import { useAtom } from 'jotai';
import { userAtom, jwtTokenAtom, authErrorMessageAtom, authenticatingAtom, awaitingConfirmationAtom } from 'context/Atoms';
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";


export function useSignOut() {
    const [, setUser] = useAtom(userAtom);
    const history = useHistory();
    const [, setJwtToken] = useAtom(jwtTokenAtom);



    return async () => {
        try {
            await Auth.signOut();
            history.push('/auth/signin');
            setJwtToken('')
            //setUser(undefined);
            Cookies.remove('jwtToken');
            console.error('LOGGING OUTTTTT ');


        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };
}
