import { useAtom } from 'jotai';
import {
    userAtom,
    jwtTokenAtom,
    authErrorMessageAtom,
    authenticatingAtom,
    awaitingConfirmationAtom,
    socketAtom, isSignUpCompleteAtom
} from 'context/Atoms';
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import io from "socket.io-client";
import Cookies from "js-cookie";
import {useState} from "react";
import {useApi} from "../context/ApiContext";

export function useSignIn() {
    const [, setUser] = useAtom(userAtom);
    const [, setSocket] = useAtom(socketAtom);
    const [, setJwtToken] = useAtom(jwtTokenAtom);
    const [, setAuthErrorMessage] = useAtom(authErrorMessageAtom);
    const [, setAuthenticating] = useAtom(authenticatingAtom);

    const api = useApi();

    const history = useHistory();

    return async (email, password, redirect) => {
        try {
            setAuthenticating(true);
            await Auth.signIn(email, password);


            Auth.currentAuthenticatedUser()
                .then(async userAuth => {
                    const jwt = userAuth && userAuth.signInUserSession ? userAuth.signInUserSession.idToken.jwtToken : null;
                    const groups = userAuth.signInUserSession.idToken.payload['cognito:groups'] || [];

                    Cookies.set('jwtToken', jwt);

                    setJwtToken(jwt)
                    console.log(jwt)
                    console.log(userAuth)


                })
                .catch(err => {
                    console.log('User not authenticated:', err);

                    const newSocket = io(process.env.REACT_APP_API_URL, {
                        auth: {
                            api_key: '649a526ea198f0622b467494',
                        }
                    });

                    setSocket(newSocket);
                });
        } catch (error) {
            setAuthErrorMessage("Login failed. Please check your credentials.");
        } finally {

            setAuthenticating(false);
        }
    };
}
