import React, {useEffect, useState} from 'react';
import {
    Flex, Text, Box, CircularProgress, CircularProgressLabel
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from '../../context/Atoms';
import Card from "../Card/Card";
import {lineChartOptionsDashboard} from "../../variables/charts";
import {useApi} from "../../context/ApiContext";

const TodayApiUsageCard = () => {
    const [user] = useAtom(userAtom);
    const limit = user.api_limit;  // Assuming 'limit' is the daily API limit available for the user
    const [todayApiUsage, setTodayApiUsage] = useState(0);
    const api = useApi();

    useEffect(() => {

        const fetchApiUsage = async () => {
            try {
                //console.log(user)
                const response = await api.get(`${process.env.REACT_APP_API_URL}/user-api-usage/${user._id}`);


                if (response.data && response.data.totalUsage) {
                    if (response.data.totalUsage > 0){
                        setTodayApiUsage(response.data.totalUsage);

                    }
                    let options = lineChartOptionsDashboard;
                    options.xaxis.categories = response.data.categories;
                    setLineChartOptions(options);
                }
            } catch (error) {
                console.error('Error fetching today usage:', error);
            }
        };


        if ( user._id){
            fetchApiUsage();
        }

    }, [user._id]);
    return (
        <Card >
            <Flex direction='column'>
                <Flex justify='space-between' align='center' mb='40px'>
                    <Text color='#fff' fontSize='lg' fontWeight='bold'>
                        Today's API Usage
                    </Text>
                </Flex>
                <Flex direction={{ sm: 'column', md: 'row' }}>
                    <Flex direction='column' me={{ md: '6px', lg: '52px' }} mb={{ sm: '16px', md: '0px' }}>
                        <Flex
                            direction='column'
                            p='22px'
                            pe={{ sm: '22px', md: '8px', lg: '22px' }}
                            minW={{ sm: '220px', md: '140px', lg: '220px' }}
                            bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
                            borderRadius='20px'
                            mb='20px'>
                            <Text color='gray.400' fontSize='sm' mb='4px'>
                                Remaining Calls
                            </Text>
                            <Text color='#fff' fontSize='lg' fontWeight='bold'>
                                {(limit - todayApiUsage).toLocaleString()}
                            </Text>
                        </Flex>

                        <Flex
                            direction='column'
                            p='22px'
                            pe={{ sm: '22px', md: '8px', lg: '22px' }}
                            minW={{ sm: '170px', md: '140px', lg: '170px' }}
                            bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
                            borderRadius='20px'>
                            <Text color='gray.400' fontSize='sm' mb='4px'>
                                Used Calls
                            </Text>
                            <Text color='#fff' fontSize='lg' fontWeight='bold'>
                                {todayApiUsage.toLocaleString()}
                            </Text>
                        </Flex>
                    </Flex>
                    <Box mx={{ sm: 'auto', md: '0px' }}>
                        <CircularProgress
                            size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
                            value={(todayApiUsage / limit) * 100}
                            thickness={6}
                            color='#05CD99'
                            variant='vision'>
                            <CircularProgressLabel>
                                <Flex direction='column' justify='center' align='center'>
                                    <Text color='gray.400' fontSize='sm'>
                                        Usage
                                    </Text>
                                    <Text
                                        color='#fff'
                                        fontSize={{ md: '36px', lg: '50px' }}
                                        fontWeight='bold'
                                        mb='4px'>
                                        {((todayApiUsage / limit) * 100).toFixed(0)}%
                                    </Text>
                                    <Text color='gray.400' fontSize='sm'>
                                        Percentage
                                    </Text>
                                </Flex>
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                </Flex>
            </Flex>
        </Card>
    );
};

export default TodayApiUsageCard;
