// Chakra imports
import { Box, Flex, Grid, Icon, Progress, Spinner,useColorModeValue } from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import React, {useContext} from "react";
import { FaFire } from "react-icons/fa";
import { RiMastercardFill, RiVisaLine } from "react-icons/ri";
import CreditCard from "./components/CreditCard";
import Invoices from "./components/Invoices";
import PaymentMethod from "./components/PaymentMethod";
import Plan from "./components/Plan";

import { useEffect, useState } from "react";
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";


function Billing() {
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [invoices, setInvoices] = useState([])
  const [subscription, setSubscription] = useState([]);
  const [socket] = useAtom(socketAtom);
  const [user] = useAtom(userAtom);

  

  useEffect(() => {
    // Fetch the billing details on component mount
    socket.emit('getBillingDetails');

  }, []);

  

  useEffect(() => {

    // Event listener for receiving the billing details
    socket.on('billingDetails', ({ defaultPaymentMethod, paymentMethods, subscription, invoices }) => {
      console.log('received')
      console.log
      setDefaultPaymentMethod(defaultPaymentMethod);
      if ( defaultPaymentMethod){

      }
      const updatedPaymentMethods = paymentMethods.map(method => {
        // If the method id is equal to the default method id, set 'default' to true
        if (defaultPaymentMethod && method?.id === defaultPaymentMethod.id) {
          return { ...method, default: true };
        }
        // Otherwise, set 'default' to false
        return { ...method, default: false };
      });
    
      // Sort the array so that the default payment method is the first item
      updatedPaymentMethods.sort((a, b) => b.default - a.default);
    
      setPaymentMethods(updatedPaymentMethods);
      
      
      setSubscription(subscription);
      
      
      setInvoices(invoices)
      //console.log(defaultPaymentMethod, paymentMethods, subscription, invoices)
    });
  

  }, [socket]);


  const invoicesData = invoices.map((invoice) => ({
    date: new Date(invoice.created * 1000).toLocaleDateString(), // convert from Unix timestamp to local date string
    code: invoice.number,
    price: (invoice.total / 100).toFixed(2), // convert from cents to dollars
    status: invoice.status, 
    hosted_invoice_url: invoice.hosted_invoice_url
  }));

  if (!subscription) {
    return (
        <Flex justify="center" align="center" height="70vh">
          <Spinner size={'xl'}/>
        </Flex>
    );
  }






  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", sm: '120px' }}>

      <Grid templateColumns={{ sm: "1fr", lg: "2fr .9fr" }} templateRows="1fr">
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 1fr",
            }}
            templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
            gap="26px"
          >
            
            <CreditCard
              backgroundImage={BackgroundCard1}
              title={defaultPaymentMethod?.billing_details.name}
              number={`xxxx xxxx xxxx ${defaultPaymentMethod?.card.last4 || 'xxxx'}`}
              validity={{
                name: "VALID THRU",
                date: `${defaultPaymentMethod?.card.exp_month || "12"}/${defaultPaymentMethod?.card.exp_year || "33"}`,
              }}
              cvv={{
                name: "CVV",
                code: "09x",
              }}
              icon={defaultPaymentMethod?.card.brand === 'mastercard' ? 
                <Icon as={RiMastercardFill} w="48px" h="auto" color="gray.400" /> : 
                <Icon as={RiVisaLine} w="48px" h="auto" color="gray.400" />
              }
            />
            <Plan title={subscription?.plan?.product.name} subscription={subscription} icon={<Icon as={FaFire} w="32px" h="auto"/>}/>
          </Grid>

          
          
              <PaymentMethod
                paymentMethods={paymentMethods}
                title={"Payment Method"}
                setPaymentMethods={setPaymentMethods}
                setDefaultPaymentMethod={setDefaultPaymentMethod}
                
              />
            
        </Box>
        <Invoices title={"Invoices"} data={invoicesData} />
      </Grid>

    </Flex>
  );
}

export default Billing;
