/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import basic from "assets/img/basic-auth.png";
import deloitteLogo from "assets/img/2.png";
import georgiaLogo from "assets/img/3.png";
import googleLogo from "assets/img/4.png";
import microsoftLogo from "assets/img/5.png";
import diveWallet from "assets/img/DiveWallet.png"
import msnLogo from "assets/img/2.png";
import zohoLogo from "assets/img/2.png";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import React, {useEffect, useState} from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import PlansTable from "../../components/PlansTable";
import {fetchPlans} from "../../apiCalls/fetchPlans";
import {useApi} from "../../context/ApiContext";
import FeaturesGrid from "../../components/FeaturesGrid";
import { useHistory } from 'react-router-dom';
import {useAtom} from "jotai";
import {plansAtom, selectedSignUpPlan} from "../../context/Atoms";


function Pricing() {
  const [activeButton, setActiveButton] = useState({
    monthly: true,
    yearly: false,
  });
  const history = useHistory();

  const navigateToSignUp = (index) => {
    console.log(plans[0])
    setSelectedPlan(plans[index])
    // Navigate to the sign-in page and pass plans[0] as state
    history.push('/auth/signup', {plan: plans[0] });
  };


  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const bgActiveButton = useColorModeValue("#fff", "gray.700");
  const bgButtonGroup = useColorModeValue("gray.50", "gray.600");
  const bgTimesIcon = useColorModeValue("gray.700", "gray.500");
  const [plans, setPlans] = useAtom(plansAtom); // State for plans
  const [selectedPlan, setSelectedPlan] =useAtom(selectedSignUpPlan)
  const api = useApi();

  useEffect(() => {
    const loadPlans = async () => {
      const fetchedPlans = await fetchPlans(api);
      console.log(fetchedPlans)
      setPlans(fetchedPlans);
    };

    loadPlans();
  }, []);

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      width={'100%'}
      bg={"linear-gradient(159.02deg, #0F123B 14.25%, #505785 56.45%, #212B66 86.14%)"}
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", xl: "50vh" }}
        w={{ sm: "calc(100vw - 25px)", md: "calc(100vw - 25px)" }}
        borderRadius={{ sm: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgSize="cover"
        mx={{ sm: "auto" }}
        mt={{ sm: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="8.5rem"
        mb="38px"
      >
        <Text fontSize="3xl" color="white" fontWeight="bold">
          See our pricing
        </Text>
        <Text
          fontSize="md"
          color="white"
          fontWeight="normal"
          mt="10px"
          mb="26px"
          maxW="300px"
          // w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}
        >
          All of our pricing is billed on a monthly basis.
        </Text>

        <Stack
          direction={{ sm: "column", lg: "row" }}
          spacing="20px"
          mt="30px"
          mb="80px"
        >
          <Card
            boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
            w={{ sm: "300px", md: "650px", lg: "300px" }}
          >
            <CardHeader mb="30px">
              <Flex direction="column" w="100%" align="center">
                {Array.isArray(plans) && plans.length > 0 && <Tag size="sm">{plans[0].name.toUpperCase()}</Tag>}
                <Text color={textColor} fontSize="5xl" fontWeight="bold">
                  FREE
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex direction="column" w="100%" minHeight={200} justifyContent={'space-between'}>
                <Stack direction="column" spacing="16px" w="100%" mb="35px" justifyContent={'space-between'} align={'space-between'}>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />

                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[0]?.features[0]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                        w="20px"
                        h="20px"
                        as={FaCheckCircle}
                        mr="8px"
                        color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      Lynx API Access
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[0]?.features[1]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[0]?.features[2]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                        w="20px"
                        h="20px"
                        as={FaCheckCircle}
                        mr="8px"
                        color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[0]?.features[3]}
                    </Text>
                  </Flex>

                </Stack>
                <Button
                  variant="no-hover"
                  fontSize="xs"
                  bg={bgButton}
                  color="#fff"
                    onClick={() => navigateToSignUp(0)} // Add the onClick event handler


                >
                  JOIN NOW
                </Button>
              </Flex>
            </CardBody>
          </Card>
          <Card
            boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
            w={{ sm: "300px", md: "650px", lg: "300px" }}
          >
            <CardHeader mb="30px">
              <Flex direction="column" w="100%" align="center">
                <Tag size="sm">{plans[1]?.name.toUpperCase()}</Tag>
                <Text color={textColor} fontSize="5xl" fontWeight="bold">
                  ${plans[1]?.price}
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="16px" w="100%" mb="35px">
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[1]?.features[0]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[1]?.features[1]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[1]?.features[2]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[1]?.features[3]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[1]?.features[4]}
                    </Text>
                  </Flex>
                </Stack>
                <Button
                  variant="no-hover"
                  fontSize="xs"
                  onClick={() => navigateToSignUp(1)}
                  color="#fff"
                >
                  JOIN NOW
                </Button>
              </Flex>
            </CardBody>
          </Card>
          <Card
            boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
            w={{ sm: "300px", md: "650px", lg: "300px" }}
          >
            <CardHeader mb="30px">
              <Flex direction="column" w="100%" align="center">
                <Tag size="sm">{plans[2]?.name.toUpperCase()}</Tag>
                <Text color={textColor} fontSize="5xl" fontWeight="bold">
                  ${plans[2]?.price}
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="16px" w="100%" mb="35px">
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[2]?.features[0]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[2]?.features[1]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[2]?.features[2]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[2]?.features[3]}
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon
                      w="20px"
                      h="20px"
                      as={FaCheckCircle}
                      mr="8px"
                      color="green.300"
                    />
                    <Text color="gray.500" fontWeight="normal" fontSize="md">
                      {plans[2]?.features[4]}
                    </Text>
                  </Flex>
                </Stack>
                <Button
                  variant="no-hover"
                  fontSize="xs"
                  bg={bgButton}
                  color="#fff"
                  onClick={() => navigateToSignUp(2)}
                >
                  JOIN NOW
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </Stack>
        <Flex direction="column" mb="110px" justify="center" align="center">
          <Text
            color="gray.300"
            fontWeight="bold"
            fontSize="md"
            mb={{ sm: "32px", xl: "16px" }}
            maxW={{ sm: "250px", md: "100%" }}
            textAlign="center"
          >
            Trusted by top crypto companies
          </Text>
          <SimpleGrid
            columns={{ sm: 2, md: 2, lg: 4 }}
            rows={{ sm: 2, md: 2, lg: 1 }}
            spacingX={{ sm: "65px", lg: "40px", xl: "5px" }}
            spacingY={{ sm: "30px" }}
            ml={10}
            mr={10}
          >
            <Image src={googleLogo} alignSelf="center" justifySelf="center" w={"50%"}/>
            <Image src={diveWallet} alignSelf="center" justifySelf="center" w={"50%"}/>
            <Image
              src={microsoftLogo}
              alignSelf="center"
              justifySelf="center"
              w={"50%"}
            />
            <Image src={georgiaLogo} alignSelf="center" justifySelf="center" w={"50%"}/>
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Pricing;
