import {
  Badge,
  Button,
  Flex,
  Icon,
  HStack,
  Input,
  Spacer,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Switch,
  useDisclosure,
  VStack,
  Stack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { CardElement, Elements, useStripe, useElements,  } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {useState, useRef, useEffect, useContext} from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import React from "react";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";
import axios from 'axios';
import {useApi} from "../../../../context/ApiContext";  // Assuming you're using Axios. You'll need to install this if not already done.
const test_stripe_public_key = "pk_test_51NqMYsIKGHPI0vziue3PMw2bvUGSuf6qBntAec1cFkPx4q59UKL51KQtoC5GnyX6vvY7FUhkwXD05JQXj3kEkK3E00WKECRQlC";
const stripe_public_key = "pk_live_51NqMYsIKGHPI0vzipi3sOv6PlNStGFG9dusZvphqGasJ2ax66SWawcdv0MlJQxPa2XgXunAbYrvI2Lj9EacsBUsN00EpOjuhdL"


const CardForm = ({ isOpen, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isDefault, setIsDefault] = useState(false);
  const [socket] = useAtom(socketAtom);
  const [user,setUser] = useAtom(userAtom)
  const [cardHolderName, setCardHolderName] = useState("");
  const cardColor = "red";
  const toast = useToast();
  const api = useApi()
  const [billingAddress, setBillingAddress] = useState({
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      postal_code: '',
    }
  });
  
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    
    // Check if the input is an address field
    if (name.includes('address.')) {
      const addressKey = name.split('.')[1]; // Split the name at the '.' to get the key for the address
    
      setBillingAddress(prevState => ({
        ...prevState,
        address: {
          ...prevState.address,
          [addressKey]: value,
        },
      }));
    } else {
      // If it's not an address field, we're setting the name
      setBillingAddress(prevState => ({
        ...prevState,
        name: value,
      }));
    }
  };

  const handleSave = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingAddress,
    });

    if (error) {
      console.error(error);
      toast({
        title: "Error creating payment method.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      try {
        // Calling our API endpoint to add the payment method
        const response = await api.post(process.env.REACT_APP_API_URL + '/add-payment-method', {
          paymentMethodDetails: paymentMethod,
          email: user.attributes.email,
          isDefault: isDefault
        });

        if (response.data.message) {
          toast({
            title: "Success",
            description: response.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "Failed to add payment method.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (err) {
        console.error('API call failed:', err);
        toast({
          title: "Error",
          description: err.response?.data?.message || "Server error.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    //onClose();
  };


  


  const handleSwitchChange = () => {
    console.log(!isDefault)
    setIsDefault(!isDefault);
  };

  const handleNameChange = (event) => {
    setCardHolderName(event.target.value);
  };
  


  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
      <ModalOverlay />
      <ModalContent bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.99) 96.65%)"} >
        <ModalHeader>Add new card</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <FormControl>
          <FormLabel>Cardholder Name</FormLabel>
          <Input type="text" name="name" value={billingAddress.name} onChange={handleAddressChange} mb={5} />
        </FormControl>
        <FormControl>
          <FormLabel>Street</FormLabel>
          <Input type="text" name="address.line1" value={billingAddress.address.line1} onChange={handleAddressChange} mb={5} />
        </FormControl>
        <FormControl>
          <FormLabel>Street 2</FormLabel>
          <Input type="text" name="address.line2" value={billingAddress.address.line2} onChange={handleAddressChange} mb={5} />
        </FormControl>
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input type="text" name="address.city" value={billingAddress.address.city} onChange={handleAddressChange} mb={5} />
        </FormControl>
        <FormControl>
          <FormLabel>Postal Code</FormLabel>
          <Input type="text" name="address.postal_code" value={billingAddress.address.postal_code} onChange={handleAddressChange} mb={5} />
        </FormControl>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "17px",
                color: 'white',
                marginBottom: "10px",
                marginTop: "10px",
                borderWidth: '2px'
              }
            },
            hidePostalCode: true,
          }}
        />

        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const PaymentMethod = ({ paymentMethods, title, setPaymentMethods, setDefaultPaymentMethod }) => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const [socket] = useAtom(socketAtom);
  const [isRemoveAlertOpen, setIsRemoveAlertOpen] = useState(false);
  const [isMakeDefaultAlertOpen, setIsMakeDefaultAlertOpen] = useState(false);
  const [paymentMethodToRemove, setPaymentMethodToRemove] = useState(null);
  const [paymentMethodToMakeDefault, setPaymentMethodToMakeDefault] = useState(null);
  // These refs will be passed to the AlertDialog component to handle focus
  const cancelRemoveRef = useRef();
  const cancelMakeDefaultRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const paymentMethodsRef = useRef(paymentMethods);
  const [user,setUser] = useAtom(userAtom)

  useEffect(() => {
    paymentMethodsRef.current = paymentMethods;
  }, [paymentMethods]);


  // This function opens the remove dialog
  const handleRemoveClick = (paymentMethod) => {
    setPaymentMethodToRemove(paymentMethod);
    setIsRemoveAlertOpen(true);
  };

  const handleRemove = async () => {
    console.log(paymentMethodToRemove.default);

    if (!paymentMethodToRemove.default) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/remove-payment-method', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: user.attributes.email,
            paymentMethodId: paymentMethodToRemove.id

          })
        });

        const data = await response.json();

        if (response.ok) {
          // Assuming you want to remove the payment method from the frontend array too
          const updatedPaymentMethods = paymentMethodsRef.current.filter(pm => pm.id !== paymentMethodToRemove.id);
          setPaymentMethods(updatedPaymentMethods);

          toast({
            title: "Success",
            description: data.message || "Payment method removed successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: data.message || "Failed to remove payment method.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

      } catch (error) {
        console.error('Error removing payment method:', error);
        toast({
          title: "Error",
          description: "An error occurred while removing the payment method. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    setIsRemoveAlertOpen(false);
  };


  // This function opens the make default dialog
  const handleMakeDefaultClick = (paymentMethod) => {
    setPaymentMethodToMakeDefault(paymentMethod);
    setIsMakeDefaultAlertOpen(true);
  };

  // This function actually makes the payment method default
  const handleMakeDefault = async () => {
    console.log(paymentMethodToMakeDefault?.id);
    socket.emit("changeDefaultPaymentMethod", paymentMethodToMakeDefault)

    setIsMakeDefaultAlertOpen(false);
  };

  useEffect(() => {

    socket.on('deletePaymentMethod', (paymentMethod) => {
      const updatedPaymentMethods = paymentMethodsRef.current.filter(method => method.id !== paymentMethod.id);
      setPaymentMethods(updatedPaymentMethods);
  
      toast({
        title: "Payment Method Removed!",
        description: "You have successfully removed the payment method.",
        status: "success",
        duration: 3000, // Duration of the toast message
        isClosable: true, // Enable the close button
      });
    });

    socket.on('changeDefaultPaymentMethod', (paymentMethod) => {
      var newDefaultPaymentMethod = paymentMethod
      const paymentMethods = paymentMethodsRef.current;

      setDefaultPaymentMethod(newDefaultPaymentMethod);
      console.log(paymentMethods)
      // If the method id is equal to the default method id, set 'default' to true. Otherwise, set 'default' to false
      const updatedPaymentMethods = paymentMethods.map(method => {
        console.log(method)
        // If the method id is equal to the default method id, set 'default' to true
        if (method.id === newDefaultPaymentMethod.id) {
          return { ...method, default: true };
        }
        // Otherwise, set 'default' to false
        return { ...method, default: false };
      });
    
      // Sort the array so that the default payment method is the first item
      updatedPaymentMethods.sort((a, b) => b.default - a.default);
    
      // Update the state
      setPaymentMethods(updatedPaymentMethods);
    
      // Show a success toast
      toast({
        title: "Default Payment Method Changed!",
        description: "You have successfully changed your default payment method.",
        status: "success",
        duration: 3000, // Duration of the toast message
        isClosable: true, // Enable the close button
      });
    });

    socket.on('createPaymentMethod', (data) => {
      const {paymentMethod, isDefault} = data;
      const paymentMethods = paymentMethodsRef.current;

      var updatedPaymentMethods = [...paymentMethods ]
      paymentMethod.default = false;
      // Add the new payment method to the array
      updatedPaymentMethods.push(paymentMethod);
    
      if (isDefault){
        // If the new method is default, update the defaultPaymentMethod state
        setDefaultPaymentMethod(paymentMethod);

        // Update the 'default' key on all payment methods
        updatedPaymentMethods = updatedPaymentMethods.map(method => {
          // If the method id is equal to the new method id and the new method is default, set 'default' to true
          if (method.id === paymentMethod.id && isDefault) {
            return { ...method, default: true };
          }
          // Otherwise, set 'default' to false
          return { ...method, default: false };
        });

        // Sort the array so that the default payment method is the first item
        updatedPaymentMethods.sort((a, b) => b.default - a.default);
      
        
      } 

      // Update the state
      setPaymentMethods(updatedPaymentMethods);
    
      // Show a success toast
      toast({
        title: "Payment Method Created!",
        description: "You have successfully created a payment method.",
        status: "success",
        duration: 3000, // Duration of the toast message
        isClosable: true, // Enable the close button
      });
    });
    
    
  

  }, [socket]);
  

  return (
    <Card p="16px" mt="24px">
      <Elements stripe={loadStripe(stripe_public_key)}>
      <CardHeader>
        <Flex justify="space-between" align="center" minHeight="60px" w="100%">
          <Text fontSize="lg" fontWeight="bold">
            {title}
          </Text>
          <Button bg={bgButton} color="white" fontSize="xs" variant="no-hover" onClick={onOpen}>
            ADD NEW CARD
          </Button>
        </Flex>
      </CardHeader>
      <CardForm isOpen={isOpen} onClose={onClose} />
      <CardBody direction='column'>
      <Stack
                direction={{ sm: "column", md: "row" }}
                align="flex-start"
                w="100%"
                justify="flex-start"
                py="1rem"
              >

      {paymentMethods.map((paymentMethod) => {
            const isVisa = paymentMethod.card.brand === 'visa';
            const isMastercard = paymentMethod.card.brand === 'mastercard';
            const cardNumber = '**** **** **** ' + paymentMethod.card.last4;
            var mastercard={
              icon: <MastercardIcon w="100%" h="100%" />,
              number: cardNumber,
            }
            var visa={
              icon: <VisaIcon w="100%" h="100%" />,
              number: cardNumber,
            }

            const badgeColor = paymentMethod?.default ? 'green' : 'red';



            return (

              <VStack
                direction={{ sm: "column", md: "column", lg:'column' }}
                align="flex-start"
                w={{ sm: "100%", md: "100%", lg:"50%" }}
                justify="flex-start"
                py="1rem"
                mr={3}
              >
              {isMastercard ? 
                <Flex
                p="1rem"
                bg="transparent"
                borderRadius="15px"
                width="100%"
                h={"5rem"}
                border="1px solid"
                borderColor={borderColor}
                align="center"
                mb={{ sm: "24px", md: "0px" }}
                me={{ sm: "0px", md: "24px" }}
              >
                <IconBox me="10px" w="25px" h="22px">
                  {mastercard.icon}
                </IconBox>
                <Text color="gray.400" fontSize="md" fontWeight="semibold">
                  {mastercard.number}
                </Text>
                <Spacer />
                <VStack justify={'space-evenly'} h='100%'>

                    <Button 
                        bg={'transparent'}
                        onClick={() => handleRemoveClick(paymentMethod)}
                        variant="no-hover"
                        pointerEvents={paymentMethod.default ? 'none' : 'auto'}
                        h={3}
                      >
                        <Badge colorScheme={badgeColor}>
                          {paymentMethod?.default ? 'Default' : 'Remove'}
                        </Badge>
                    </Button>

                </VStack>
                
               
              </Flex>
              : isVisa ? 
              <Flex
                p="1rem"
                bg="transparent"
                borderRadius="15px"
                width="100%"
                h={"5rem"}
                border="1px solid"
                borderColor={borderColor}
                align="center"
                mb={{ sm: "24px", md: "0px" }}
                me={{ sm: "0px", md: "24px" }}
              >
                  <IconBox me="10px" w="25px" h="25px">
                    {visa.icon}
                  </IconBox>
                  <Text color="gray.400" fontSize="md" fontWeight="semibold">
                    {visa.number}
                  </Text>
                  <Spacer />
                  <VStack justify={'space-evenly'} h='100%'>

                    <Button 
                        bg={'transparent'}
                        onClick={() => handleRemoveClick(paymentMethod)}
                        variant="no-hover"
                        pointerEvents={paymentMethod.default ? 'none' : 'auto'}
                        h={3}
                      >
                        <Badge colorScheme={badgeColor}>
                          {paymentMethod?.default ? 'Default' : 'Remove'}
                        </Badge>
                    </Button>

                </VStack>
                </Flex>
              : null}
                
                
              </VStack>
                    
                  );
                })}
      </Stack>
        
      </CardBody>
      <AlertDialog
      isOpen={isRemoveAlertOpen}
      leastDestructiveRef={cancelRemoveRef}
      onClose={() => setIsRemoveAlertOpen(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.99) 96.65%)"}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove Payment Method
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to remove this payment method?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRemoveRef} onClick={() => setIsRemoveAlertOpen(false)} colorScheme={'blue'}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleRemove} ml={3}>
              Remove
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>


      </Elements>

    </Card>
  );
};

export default PaymentMethod;
