import { Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex, Text, useColorModeValue,Button, VStack, Badge, Icon, HStack, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, useToast } from "@chakra-ui/react";
import {useState, useRef, useEffect, useContext} from "react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";
import PlansTable from "../../../../components/PlansTable";
import moment from "moment";

const Plan = ({ icon, title, description, amount, subscription }) => {
  const iconTeal = useColorModeValue("gray.300", "gray.800");
  const textColor = useColorModeValue("gray.700", "white");
  const badgeColor = subscription?.status === 'active' ? 'green' : 'red';
  const [socket] = useAtom(socketAtom);
  const [user] = useAtom(userAtom);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const toast = useToast();
  // State to manage the modal's visibility
  const [isModalOpen, setModalOpen] = useState(false);

  // Functions to open and close the modal
  const openModal = () => {
    console.log(`user be like ${user}`)
    console.log(user)
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };



  useEffect(() => {
    // Handle the cancelSubscription event
    socket.on('cancelSubscription', (data) => {
      const { success, subscription } = data;

      if (success) {
        toast({
          title: "Subscription Cancelled",
          description: "Your subscription has been cancelled and will not renew at the end of the current period.",
          status: "success",
          duration: 3000, 
          isClosable: true,
        });

        setIsOpen(false)
      } else {
        toast({
          title: "Subscription Cancellation Failed",
          description: "An error occurred while trying to cancel your subscription. Please try again.",
          status: "error",
          duration: 3000, 
          isClosable: true,
        });
      }
    });

  }, [socket]);


  const handleCancel = () => {
    socket.emit('cancelSubscription', { subscriptionId: subscription.id });
  };



  return (
    <Card
        p="16px"
        display="flex"
        align="center"
        justify="center"


    >
      <CardBody>
        <Flex direction="column" align="center" w="100%" py="14px">

          <Flex
              direction="column"
              m="14px"
              justify="center"
              textAlign="center"
              align="flex-start"
              w="100%"
          >
            <Text fontSize="xl" fontWeight="bold" mb="4px" alignSelf={'flex-start'} color="gray.500">
              Plan
            </Text>
            <HStack>
              <Text fontSize="xl" fontWeight="bold" mb="4px">
                {subscription?.plan?.product?.name || 'Growth'}
              </Text>
              <Badge colorScheme="teal" fontSize="xs" p="5px" borderRadius="lg" mb="10px">
                {subscription?.interval || 'Monthly'}
              </Badge>
            </HStack>

            <Text fontSize="xl" fontWeight="bold" mt={2} mb="4px">
              ${subscription?.plan?.amount / 100 || '0.00'}
            </Text>
            <Text fontSize="sm" color="gray.500">
              Billing period: {
                moment.unix(subscription?.current_period_start).format('MMM DD, YYYY') || 'Jan 17, 2024'
            } – {
                moment.unix(subscription?.current_period_end).format('MMM DD, YYYY') || 'Feb 17, 2024'
            }
            </Text>
          </Flex>
          <Separator mb={5}/>
          <HStack>
          {subscription?.plan?.product?.id != "prod_OLoM1sMqdUDZZs" && subscription?.status == 'active' && (
            <Button colorScheme={'red'} size="sm" disabled={subscription?.plan?.product?.id == "prod_Of4ZYvor37WaQZ" ? true : false} onClick={() => setIsOpen(true)}>
              Cancel
            </Button>
          )}
          
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.99) 96.65%)"}>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Cancel Subscription
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to cancel your subscription? You won't be charged starting next billing period but your plan will remain active until then.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} colorScheme={'blue'} onClick={onClose}>
                    No
                  </Button>
                  <Button colorScheme="red" onClick={handleCancel} ml={3}>
                    Yes
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
            <Button
                colorScheme={'blue'}
                size="sm"
                onClick={openModal}
            >
            {subscription?.plan?.product?.id == "prod_Of4ZYvor37WaQZ" ? 'Upgrade' : subscription?.status != 'active' ? 'Renew' : 'Change'}
            </Button>
          </HStack>
          
        </Flex>

      </CardBody>
      <Modal isOpen={isModalOpen} onClose={closeModal} >
        <ModalOverlay />
        <ModalContent bg={"#0C1233"} maxW="70vw">
          <ModalHeader>Change Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PlansTable user={user} currentPlan={subscription?.plan?.product?.name} action={'change'}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default Plan;
