/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {Box, Button, Flex, Icon, IconButton, Link, Spacer, Text} from "@chakra-ui/react";
import {FaDownload} from "react-icons/fa";

function InvoicesRow(props) {
  const { date, code, price, format, logo, hosted_invoice_url } = props;

  return (
    <Flex mb='24px' alignItems='center'>
      <Flex direction='column'>
        <Text fontSize='sm' color='#fff' mb='4px'>
          {date}
        </Text>
        <Text fontSize='xs' color='gray.400'>
          {code}
        </Text>
      </Flex>
      <Spacer />
      <Box me='14px'>
        <Text fontSize='xs' color='gray.400'>
          {price}
        </Text>
      </Box>
        <Link href={hosted_invoice_url} isExternal>
            <IconButton
                aria-label="Download Invoice"
                icon={<FaDownload />}
                size="sm"
                colorScheme="blue"
                variant="outline"
            />
        </Link>
    </Flex>
  );
}

export default InvoicesRow;
