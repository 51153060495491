/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";

export default function AuthFooter(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
      }}
      alignItems={{
        base: "center",
      }}
      justifyContent='space-between'
      pb='20px'
      fontSize='sm'>
        <Text
            fontSize='sm'
            color='white'
            textAlign={{
                base: "center",
                xl: "start",
            }}
            mb={{ base: "20px", xl: "0px" }}>
            &copy; {1900 + new Date().getYear()},{" "}
            <Text as='span'>
                {"Lynx Crypto LLC, All Rights Reserved  "}
            </Text>
        </Text>
    </Flex>
  );
}
