// Chakra imports
import {
  Box,
  Button,
  CircularProgress, CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  Spinner,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import React, {useContext} from "react";

import { useEffect, useState } from "react";
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";
import UsageScreen from "./components/Usage";
import Billing from "../Billing";
import APIKeys from "./components/APIKeys";
import Nodes from "./components/Nodes";


function NodeScreen() {

  return (
      <Flex direction="column" pt={{ base: "120px", md: "25px" }} w={'100%'}>

        <Tabs variant="enclosed" colorScheme={'whiteAlpha'} >
          <TabList mb="1em">
            <Tab>Usage</Tab>
            <Tab>Nodes</Tab>
            <Tab>API Keys</Tab>
            <Tab>Plans & Billing</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Content for the "Usage" tab. You can put the existing content here. */}
              <UsageScreen />
            </TabPanel>
            <TabPanel>
              {/* Content for the "Nodes" tab. */}
              <Nodes />
            </TabPanel>
            <TabPanel>
              {/* Content for the "API Keys" tab. */}
              <APIKeys />
            </TabPanel>
            <TabPanel>
              {/* Content for the "Plans & Billing" tab. */}
              <Billing />
            </TabPanel>
          </TabPanels>
        </Tabs>

      </Flex>
  );
}

export default NodeScreen;
