import { chakra, Container, Stack, HStack, VStack, Flex, Text, Image, Box } from '@chakra-ui/react';
import Screenshot from 'assets/img/rocket-guy.png'
const overviewList = [
    { id: 1, label: 'Pick a plan', subLabel: 'Pick from on of our three tiers.' },
    {
        id: 2,
        label: 'Confirm Email',
        subLabel: 'Confirm your email address for account recovery purposes.'
    },
    {
        id: 3,
        label: 'Generate an API key',
        subLabel: 'Generate your first Lynx API key!'
    },
    {
        id: 4,
        label: 'Grab your RPC Node URLs.',
        subLabel: 'Copy and paste your RPC links into your preferred ETH library.'
    },
    {
        id: 5,
        label: 'Build!',
        subLabel: 'Build the web3 app you are dreaming of!'
    }
];

const OverviewSection = () => {
    return (
        <Container maxW="6xl" py={10}>
            <chakra.h2 fontSize="4xl" fontWeight="bold" textAlign="center" mb={2}>
                Get Building In Minutes.
            </chakra.h2>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: 0, md: 3 }}
                justifyContent="center"
                alignItems="center"
            >
                <VStack spacing={4} alignItems="flex-start" mb={{ base: 5, md: 0 }} maxW="md">
                    {overviewList.map((data) => (
                        <Box key={data.id}>
                            <HStack spacing={2}>
                                <Flex
                                    fontWeight="bold"
                                    boxShadow="md"
                                    color="white"
                                    bg="linear-gradient(159.02deg, #505785 14.25%, #505785 56.45%, #212B66 86.14%)"
                                    rounded="full"
                                    justifyContent="center"
                                    alignItems="center"
                                    w={10}
                                    h={10}
                                >
                                    {data.id}
                                </Flex>
                                <Text fontSize="xl">{data.label}</Text>
                            </HStack>
                            <Text fontSize="md" color="gray.500" ml={12}>
                                {data.subLabel}
                            </Text>
                        </Box>
                    ))}
                </VStack>
                <Image
                    boxSize={{ base: 'auto', md: 'lg' }}
                    objectFit={'stretch'}
                    src={Screenshot}
                    rounded="lg"
                />
            </Stack>
        </Container>
    );
};

export default OverviewSection;