import React, { useEffect } from "react";
import Amplify, { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { atom, Provider, useAtom } from "jotai";

// Create atoms
const userAtom = atom(null);
const jwtTokenAtom = atom(null);

const isSignUpCompleteAtom = atom(false);

const authErrorMessageAtom = atom("");
const authenticatingAtom = atom(false);
const awaitingConfirmationAtom = atom(false);

const isAuthenticatedAtom = atom(false);

const tradesAtom = atom([])


const socketAtom = atom(null);

const plansAtom = atom([]);
const selectedSignUpPlan = atom(null)

export {
    userAtom,
    jwtTokenAtom,
    isSignUpCompleteAtom,
    authErrorMessageAtom,
    authenticatingAtom,
    awaitingConfirmationAtom,
    socketAtom,
    plansAtom,
    selectedSignUpPlan,
    isAuthenticatedAtom,
    tradesAtom
};
