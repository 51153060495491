import {
	Box,
	Button,
	Flex,
	FormControl,
	Grid,
	HStack,
	Image,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Spinner,
	Stat,
	StatArrow,
	StatLabel,
	StatNumber,
	Tab,
	Table,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useBreakpointValue,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import {ChevronDownIcon, CopyIcon, SettingsIcon, TriangleDownIcon} from '@chakra-ui/icons';
import ReactApexChart from "react-apexcharts";
import medusa from 'assets/img/cardimgfree.png';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import TradesTable from 'components/Tables/TradesTable';
import MainSearch from 'components/Tables/MainSearch';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {formatCrypto, formatNumber} from 'utils/format_number';
import {useWeb3Modal} from '@web3modal/react'
import {useAtom} from "jotai";
import {socketAtom, tradesAtom, userAtom} from "../../context/Atoms";
import {TVChartContainer} from "../../components/TVChartContainer";
import axios from "axios";
import {subscribeTradesOnStream} from "../../components/TVChartContainer/streaming";


const defaultToken0 = [
	{
		"_id": {
			"$oid": "634395c799d7118e20765056"
		},
		"token_id": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2:1",
		"contract": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
		"chain": "1",
		"name": "wrapped ether",
		"symbol": "weth",
		"usd_price": 1852.5633333333335,
		"price_change": 0.5079444018004362,
		"all_time_high": 4857.3304291481145,
		"all_time_low": 878.4144392581154,
		"decimals": 18,
		"total_supply": 4071497.1564054927,
		"project_info": {
			"listed": true,
			"list_date": null,
			"weekly_votes": 0,
			"all_time_votes": 0,
			"website": null,
			"logo_url": "https://token-images.s3.amazonaws.com/eth.png",
			"twitter": null,
			"telegram": null,
			"discord": null,
			"discription": null
		},
		"volume": 2564121.4661915735
		},
		{
			"_id": {
			"$oid": "631424dad41f007ef124737d"
			},
			"token_id": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c:56",
			"contract": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
			"chain": "56",
			"name": "wrapped bnb",
			"symbol": "wbnb",
			"usd_price": 233.485,
			"price_change": 0.13295876133902437,
			"all_time_high": 669.6209251255284,
			"all_time_low": 184.0670039747544,
			"decimals": 18,
			"total_supply": 4322226.203112505,
			"project_info": {
			"listed": true,
			"list_date": null,
			"weekly_votes": 0,
			"all_time_votes": 0,
			"website": null,
			"logo_url": "https://token-images.s3.amazonaws.com/bnb.png",
			"twitter": null,
			"telegram": null,
			"discord": null,
			"discription": null
			},
			"liquidityValue": 74036168.72625822,
			"volume": 1822263.129643414
		},
]

export default function Chart() {
	const { token_id } = useParams();
	const [user, setUser] = useAtom(userAtom);
	const [socket] = useAtom(socketAtom);
	const [token, setToken] = useState({});
	const [pairs, setPairs] = useState([]);
	const [selectedPair, setSelectedPair] = useState({});
	const [lineChart, setLineChart] = useState([]);
	const [candleChart, setCandleChart] = useState([]);
	const [error, setError] = useState(undefined);
	const toast = useToast();
	const [walletConnected, setWalletConnected] = useState(false);
	const [formData, setFormData] = useState({ tokenA: '', tokenB: '' });
  	const [showForm, setShowForm] = useState(true);
	const [chainId, setChainID] = useState(token_id.split(":")[1])
	const [token0, setToken0] = useState(chainId == 1 ? defaultToken0[0] : chainId == 56 ? defaultToken0[1] : null);
	const [token1, setToken1] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSearching, setSearching] = useState(false);
	const timeoutRef = useRef(null);
	const [modalButton, setModalButton] = useState(undefined)
	const [slippage, setSlippage] = React.useState(3);
	const [defaultSearch, setDefaultSearch] = useState([])
	const [percentageChange, setPercentageChange] = useState(0.00)
	const [tab, setTab] = useState("info");
	const direction = useBreakpointValue({ base: "row", md: "row" });
	const [trades, setTrades] = useAtom(tradesAtom)
	const { open, close } = useWeb3Modal()
	const [formattedPrice, setFormattedPrice] = useState(0.00)
	const textColor = useColorModeValue("white", "white");
	const chartType = 'chart';
	const [chart, setChart] = useState(null);
	const [symbol, setSymbol] = useState(null)


	useEffect(() => {
		console.log('yoo')
		const widgetChart = (
			<div className="mx-4 mb-14 mt-4 w-full xl:w-1/2 h-72 xl:pr-3 mb-4 mx-0 mt-0 md:w-full lg:px-3 md:px-1 px-3">
				<TVChartContainer theme="dark" selectedPair={selectedPair} interval="60" autosize />
			</div>
		);
		setChart(widgetChart);
	}, [selectedPair]);



	const capitalizeName = name => name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	const isSmallScreen = useBreakpointValue({ base: true, md: false });
	// Ref to keep track of the current token_id
	const currentTokenIdRef = useRef();


	async function updateTrades(newTrade) {
		// Merge the new trade with the existing trades and sort by date
		setTrades((prevTrades) => {
			const mergedTrades = [newTrade, ...prevTrades];
			// Sort by date, assuming trade.date is in a format that can be compared like this
			mergedTrades.sort((a, b) => new Date(b.date) - new Date(a.date));

			// Slice the array to keep only the first 100 trades, if more than 100
			return mergedTrades.slice(0, 100);
		});
		setFormattedPrice(formatCrypto(newTrade.price))

	}

	useEffect(() => {
		let details = {... selectedPair, token_id}
		const event = new CustomEvent('SelectedPairUpdated', { detail: details });
		window.dispatchEvent(event);
	}, [selectedPair]);

	useEffect(() => {
		const getPairs = async() => {
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `https://data.lynxcrypto.app/api/v1/pairs?asset_id=${token_id}`,
				headers: {
					'x-api-key': 'GP9qJRk4i82OVFRazijNP5n0OWUhHn6g2GWJLAus',
				}
			};

			let response = await axios.request(config);
			let data = response;
			console.log(response)

			console.log(data.data);
			setPairs(data.data);
			setSelectedPair(data.data[0]);
			console.log('yooooooo')

			if(data && data.data[0]) {
				console.log('yooooooo')
				let config = {
					method: 'get',
					maxBodyLength: Infinity,
					url: `https://data.lynxcrypto.app/api/v1/trades?asset_id=${token_id}&pair_id=${data.data[0].pair_id}`,
					headers: {
						'x-api-key': 'GP9qJRk4i82OVFRazijNP5n0OWUhHn6g2GWJLAus',
					}
				};

				axios.request(config)
					.then((response) => {
						console.log(JSON.stringify(response.data));
						setTrades(response.data);
						subscribeTradesOnStream(selectedPair,  updateTrades, user.attributes.email, null)



					})
					.catch((error) => {
						console.log(error);
					});
			}
			//socket.emit('getTrades', {token_id: token_id, pair_address: data.data[0].pair_address});
			//socket.emit('getLineChart', { token_id: token_id, days: 7, pair_address: data.data[0].pair_address });

			if (token_id) {
				// // Unsubscribe from the previous token_id when the token_id changes
				// const prevTokenId = currentTokenIdRef.current;
				// if (prevTokenId && prevTokenId !== `${token_id}::${data.data[0].pair_address}`) {
				// 	socket.emit('unsubscribe', prevTokenId);
				// 	console.log(`Unsubscribed from ${prevTokenId}`);
				// }
				//
				// // Subscribe to the new token_id
				// socket.emit('subscribe', `${token_id}::${data.data[0].pair_address}`);
				// console.log(`Subscribed to ${token_id}::${data.data[0].pair_address}`);

				// Update the currentTokenIdRef with the new token_id
				currentTokenIdRef.current = `${token_id}::${data.data[0].pair_address}`;
			}
		}

		getPairs();

	}, [token_id]);



	useEffect(() => {
		// Retrieve the chart type preference from local storage
		const storedChartType = localStorage.getItem('chartType');

		// Determine the initial chart type based on the stored preference
		const initialChartType = storedChartType === 'candleChart' ? 'candleChart' : 'lineChart';
	
		// Emit the appropriate event based on the initial chart type
		//if (initialChartType === 'lineChart') {
		//  socket.emit('getLineChart', { token_id: token_id, days: 7, pair_address: selectedPair.pair_address });
		//} else {
		//  socket.emit('getCandleChart', { token_id: token_id });
		//	}
		console.log(token_id);
		socket.emit('getToken', {token_id: token_id});
		//socket.emit('getPairs', {token_id: token_id});

		socket.emit('getDefaultSearchTokens', {chain_id: chainId});
		// Define what should happen when new trades are received



		socket.on('defaultSearchTokens', (data) => {
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			setSearchResults(data.data);
			setDefaultSearch(data.data);
		});

		socket.on('trades', (data) => {
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			console.log(data.data)
			setTrades(data.data);
		});

		socket.on('priceUpdates', (data) => {
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			console.log(data.data)
			// Update the usd_price field of the token
			setToken((prevState) => ({
				...prevState,
				usd_price: data.data.price,
			}));
			
		});
	
		socket.on('token', (data) => {
			console.log('here')
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			console.log(data.data);
			setFormattedPrice(formatCrypto(data.data.usd_price))
			setToken(data.data);
			setToken1(data.data);
		});

		socket.on('pairs', (data) => {

			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			console.log(data.data);
			setPairs(data.data);
			setSelectedPair(data.data[0]);
			socket.emit('getTrades', {token_id: token_id, pair_address: data.data[0].pair_address});
			socket.emit('getLineChart', { token_id: token_id, days: 7, pair_address: data.data[0].pair_address });

			if (token_id) {
				// Unsubscribe from the previous token_id when the token_id changes
				const prevTokenId = currentTokenIdRef.current;
				if (prevTokenId && prevTokenId !== `${token_id}::${data.data[0].pair_address}`) {
					socket.emit('unsubscribe', prevTokenId);
					console.log(`Unsubscribed from ${prevTokenId}`);
				}

				// Subscribe to the new token_id
				socket.emit('subscribe', `${token_id}::${data.data[0].pair_address}`);
				console.log(`Subscribed to ${token_id}::${data.data[0].pair_address}`);

				// Update the currentTokenIdRef with the new token_id
				currentTokenIdRef.current = `${token_id}::${data.data[0].pair_address}`;
			}
		});

		socket.on('searchResults', (data) => {
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			setSearchResults(data.data);
			setSearching(false);
		});

		socket.on('lineChart', (data) => {
			console.log(data.data);
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}

			console.log(data.data.length)

			if (data.data[0].data.length === 0) {
				return
			}
			const newData = data.data.map(obj => ({
				...obj,
				data: obj.data.map(item => ({
					...item,
					x: new Date(item.x),
				})),
			}));
		
			
			// Calculate the percentage change
			const startPrice = newData[0].data[0].y;
			const endPrice = newData[0].data[newData[0].data.length - 1].y;
			const percent = ((endPrice - startPrice) / startPrice) * 100;
			setPercentageChange(percent.toFixed(2));
			setLineChart(newData);
		});

		socket.on('candleChart', (data) => {
			console.log(data);
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			  }
			setCandleChart(data.data);
		});

		// Cleanup function to unsubscribe when the component unmounts
		return () => {
			socket.emit('unsubscribe', token_id);
		};
		
	  }, [socket, token_id]);


		useEffect(() => {
			const delay = 1000; // 2 seconds

			clearTimeout(timeoutRef.current);

			if (!searchText.trim()) {
				setSearching(false);
				setSearchResults(defaultSearch);
			}

			if (searchText.trim() !== '') {
			timeoutRef.current = setTimeout(() => {
				setSearching(true);
				socket.emit('searchTokens', { search_text: searchText, chain_id: chainId });
			}, delay);
			}
		}, [searchText]);

	  const handleConnectWallet = () => {
		// Connect wallet logic
		setWalletConnected(true);
		toast({
		  title: 'Success',
		  description: 'Wallet connected.',
		  status: 'success',
		  duration: 5000,
		  isClosable: true,
		});
	  };
	
	  const handleSwapTokens = () => {
		// Swap tokens logic
		toast({
		  title: 'Success',
		  description: 'Tokens swapped.',
		  status: 'success',
		  duration: 5000,
		  isClosable: true,
		});
	  };

	  const handleSlippageChange = (value) => {
        setSlippage(value);
    };

	  const handleSettingsClick = () => {
		setShowForm(!showForm);
	  };
	
	  const handleChange = (e) => {
		setFormData((prevData) => ({
		  ...prevData,
		  [e.target.name]: e.target.value,
		}));
	  };

	  const handleTokenSelection = (token) => {
		console.log(token);
		if (modalButton === 'token0') {
		  setToken0(token);
		} else if ( modalButton === 'token1') {
		  setToken1(token);
		}
		setIsModalOpen(false);
	  };
	  
	
	  const handleModalOpen = (data) => {
		setModalButton(data);
		setIsModalOpen(true);
	  };
	
	  const handleModalClose = () => {
		setSearchResults(defaultSearch);
		setIsModalOpen(false);
	  };
	

	  const copyToClipboard = async (textToCopy) => {
		try {
		  await navigator.clipboard.writeText(textToCopy);
		  toast({
			title: "Copied to clipboard",
			description: `${textToCopy} has been copied to your clipboard.`,
			status: "success",
			duration: 3000,
			isClosable: true,
		  });
		} catch (err) {
		  toast({
			title: "Failed to copy",
			description: `${textToCopy} couldn't be copied to your clipboard.`,
			status: "error",
			duration: 3000,
			isClosable: true,
		  });
		}
	};
	return (
		<Flex flexDirection='row' pt={{ base: '120px', md: '75px',sm: '70px' }}>

			<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '3fr 7fr' }} gap='10px' w={'100%'}>
				<Card p='16px' maxH={{ sm: '100%', md: '50%' }}>
					<Tabs variant='enclosed'>
						<CardHeader p='12px 0px 28px 0px' w={'100%'}>
							<TabList>
								<Tab _selected={{ color: 'white', bg: 'blue.500' }}>Info</Tab>
								<Tab _selected={{ color: 'white', bg: 'blue.500' }}>Holdings</Tab>
								<Tab isDisabled _selected={{ color: 'white', bg: 'blue.500' }}>Swap</Tab>
							</TabList>
						</CardHeader>
						<CardBody>
							<TabPanels>
								<TabPanel>
									{/* Info content */}
									{/* Replace with actual Info content */}
									<Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} w={'100%'}>
										{selectedPair && selectedPair.factory_name && (
											<>
												<Menu >
													<MenuButton as={Button} rightIcon={<ChevronDownIcon />} pb={5} fontSize={'2xl'} bg={'transparent'} _ _hover={{backgroundColor:'transparent'}} _active={{backgroundColor:'transparent'}}>
														{selectedPair.token0_symbol.toUpperCase()} / {selectedPair.token1_symbol.toUpperCase()}
													</MenuButton>
													<MenuList bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)">
														{/* Here, you'd map over your array of pairs. For demonstration, I'm just showing a static list. */}
														{pairs.map((pair, index) => (
															<MenuItem key={index} onClick={() => {
																setSelectedPair(pair);
																subscribeTradesOnStream({...pair, token_id},  updateTrades, user.attributes.email, pair)

															}}
																	  bg={'transparent'}
																	  _hover={{color:'black'}}
																	  _active={{color:'black'}}
																	  _focus={{color:'black',
																		  backgroundColor:'lightgray'}}
															>
																<Flex flexDirection={'column'}>
																	<Text>{pair.token0_symbol.toUpperCase()} / {pair.token1_symbol.toUpperCase()}</Text>

																	<Text fontWeight={'light'} fontSize={'sm'}>{capitalizeName(pair.factory_name.split('_')[0])} {capitalizeName(pair.factory_name.split('_')[1])}</Text>

																</Flex>
															</MenuItem>
														))}
													</MenuList>
												</Menu>
												<Flex flexDir={'row'} w={'100%'} justifyContent={'space-between'}>
													<Button variant='nobg' onClick={() => copyToClipboard(selectedPair.token0_address)} alignItems={'center'} minW={'34%'}>
														<Text fontWeight={'bold'}>
															{selectedPair.token0_symbol.toUpperCase()}
														</Text>
														<CopyIcon ml={1}/>
													</Button>
													<Button variant='nobg' onClick={() => copyToClipboard(selectedPair.token1_address)} alignItems={'center'} minW={'34%'}>
														<Text fontWeight={'bold'}>
															{selectedPair.token1_symbol.toUpperCase()}
														</Text>
														<CopyIcon ml={1}/>
													</Button>
													<Button variant='nobg' onClick={() => copyToClipboard(selectedPair.pair_address)} alignItems={'center'} minW={'30%'}>
														<Text fontWeight={'bold'}>
															{"pair".toUpperCase()}
														</Text>
														<CopyIcon ml={1}/>
													</Button>
												</Flex>

												<Flex direction="column" justifyContent="space-evenly" alignItems="center" mt={7} ml={10} w={'100%'}>
													<Flex direction={direction} justifyContent="center" alignItems="center" wrap="wrap" w="100%" mb={5}>
														<Stat w={{base: '100%', md: '50%'}}>
															<StatLabel >24 Hour Change</StatLabel>
															<StatNumber color={selectedPair.change > 0 ? "green.400" : selectedPair.change < 0 ? "red.400" : "gray.400"} fontWeight='bold' pb='.1rem'>
																<StatArrow type={selectedPair.change > 0 ? "increase" : selectedPair.change < 0 ? "decrease" : "none"} />
																{formatNumber(selectedPair.change)}
															</StatNumber>
														</Stat>
														<Stat w={{base: '100%', md: '50%'}}>
															<StatLabel >24 Hour Volume</StatLabel>
															<Text fontSize='2xl'>
																${formatNumber(selectedPair.volume)}
															</Text>

														</Stat>
													</Flex>
													<Flex direction={direction} justifyContent="space-between" wrap="wrap" w="100%">
														<Stat w={{base: '100%', md: '50%'}}>
															<StatLabel>{selectedPair.token0_symbol.toUpperCase()} Reserves</StatLabel>
															<Text fontSize='2xl'>
																{formatNumber(selectedPair.token0_reserves)}
															</Text>
														</Stat>
														<Stat w={{base: '100%', md: '50%'}}>
															<StatLabel>{selectedPair.token1_symbol.toUpperCase()} Reserves</StatLabel>
															<Text fontSize='2xl'>
																{formatNumber(selectedPair.token1_reserves)}
															</Text>
														</Stat>
													</Flex>

												</Flex>

											</>

										)}

									</Flex>
								</TabPanel>
								<TabPanel>
									{/* Holdings content */}
									{/* Replace with actual Holdings content */}
									<Flex w={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
										<Text>Coming soon</Text>
									</Flex>
								</TabPanel>
								<TabPanel>
									{/* Swap content */}
									<Flex flexDirection="column" w={'100%'}>
										<Flex flex="1" w={'100%'} justifyContent="flex-end" alignItems="center" >
											<Button variant="nobg" onClick={handleSettingsClick}>
												<SettingsIcon />
											</Button>
										</Flex>

										{showForm ? (
											<form onSubmit={handleSwapTokens}>
												<Flex w={'100%'} flexDirection={'column'} p={4}  borderRadius="md" justifyContent={'center'} alignItems="center">
													<Flex bg={'rgba(40, 80, 141,0.4)'} borderRadius={7} w={"100%"} h={75} p={2}>
														<FormControl mb={4}>
															<Input
																size={'lg'}
																name="tokenA"
																w={'100%'}
																value={formData.tokenA}
																onChange={handleChange}
																placeholder="0.00"
																variant={'unstyled'}
															/>
														</FormControl>
														<Button variant="outlineWhite" borderWidth={1} onClick={() => handleModalOpen("token0")} width={220} p='20px'>
															<TriangleDownIcon mr={1} fontSize='xs' color='gray.400' />
															<Image src={token0 && token0.project_info ? token0.project_info.logo_url : medusa} h={"34px"} w={"34px"} me='10px' alt='logo'/>
															<Text fontSize='lg' fontWeight='bold'>{token0 && token0.symbol ? token0.symbol.toUpperCase() : ''}</Text>
														</Button>
													</Flex>
													<Flex bg={'rgba(40, 80, 141,0.4)'} borderRadius={7} w={"100%"} h={75} p={2} mt={2}>
														<FormControl mb={4}>
															<Input
																size={'lg'}
																w={'100%'}
																name="tokenB"
																value={formData.tokenB}
																onChange={handleChange}
																placeholder="0.00"
																variant={'unstyled'}
															/>
														</FormControl>
														<Button variant="outlineWhite" borderWidth={1} onClick={() => handleModalOpen("token1")} width={220} p='20px'>
															<TriangleDownIcon mr={1} fontSize='xs' color='gray.400' />
															<Image src={token1 && token1.project_info ? token1.project_info.logo_url : medusa} h={"34px"} w={"34px"} me='10px' alt='logo'/>
															<Text fontSize='lg' fontWeight='bold'>{token1 && token1.symbol ? token1.symbol.toUpperCase() : ''}</Text>
														</Button>
													</Flex>

													<Button onClick={() => open()} variant='brand' mb={4} mt={4}>
														Swap Tokens
													</Button>


												</Flex>
											</form>
										) : (
											<Flex w={'100%'} flexDirection={'column'} p={4}  borderRadius="md" justifyContent={'center'} alignItems="center">
												<Text mb="2rem">Slippage: {slippage}%</Text>
												<Slider aria-label="slider-ex-5" value={slippage} min={0} max={50} onChange={handleSlippageChange}>
													<SliderTrack>
														<SliderFilledTrack />
													</SliderTrack>
													<SliderThumb boxSize={6}>
														<Box color="tomato" />
													</SliderThumb>
												</Slider>
											</Flex>
										)}
									</Flex>
								</TabPanel>
							</TabPanels>
						</CardBody>
					</Tabs>
				</Card>

				<Card p='28px 0px 0px 0px'>
					<CardHeader mb='20px' ps='22px'>
							<Flex direction='row' w={{md:'83%',sm:'67%'}} alignItems='center'>
								<Flex align='center' py='.2rem' minWidth='100%' flexWrap='nowrap' flexDir={'row'}>
									<Image src={token.project_info ? token.project_info.logo_url : medusa} h={"74px"} w={"74px"} me='10px' alt='logo'/>
									<Flex direction='column' w={'100%'}>
										<Text
											fontSize='lg'
											color='#fff'
											fontWeight='bold'
											mb='6px'
											display={{ base: 'none', md: 'block' }} // 'none' on base (smaller screens), 'block' on medium screens and up
										>
											{token.name ? capitalizeName(token.name) : ''}
										</Text>
										<Text
											fontSize='lg'
											color='#fff'
											fontWeight='bold'
											mb='6px'
											display={{ base: 'block', md: 'none' }} // 'block' on base (smaller screens), 'none' on medium screens and up
										>
											{token.symbol ? token.symbol.toUpperCase() : ''}
										</Text>
										<HStack spacing={1}>
											{formattedPrice && formattedPrice?.zeroCount ? (
												<>
													<Text fontSize='md' color={textColor} fontWeight='bold' pb='.7rem'>
														{formattedPrice.start}
													</Text>
													<Text fontSize='xs' color={textColor} fontWeight='bold'>
														{formattedPrice.zeroCount}  {/* Display zeros count as repeated '0's */}
													</Text>
													<Text fontSize='md' color={textColor} fontWeight='bold' pb='.7rem'>
														{formattedPrice.value}
													</Text>
												</>
											) : <Text fontSize='md' color={textColor} fontWeight='bold' pb='.7rem'>
												{formattedPrice}
											</Text>}
										</HStack>
									</Flex>
								</Flex>
								<Stat>

								<Flex direction="row" alignItems="center">
								<StatArrow type={percentageChange > 0 ? "increase" : percentageChange < 0 ? "decrease" : "none"} />
								<StatNumber size={'sm'} color={percentageChange > 0 ? "green.400" : percentageChange < 0 ? "red.400" : "gray.400"}>
									{Math.abs(percentageChange).toFixed(2)}%
								</StatNumber>
								</Flex>
								</Stat>
							</Flex>
						
					</CardHeader>
					<Box w='100%' minH={{ sm: '300px' }}>

						{chartType == 'line' ?
							<ReactApexChart
								options={lineChartOptions}
								series={lineChart}
								type='area'
								width='100%'
								height='100%'
							/>
						: selectedPair && selectedPair.token0_symbol ?
							<>
								{chart}
							</>
						: null
						}

					</Box>
					<Flex w='100%' minH={{ sm: '300px' }} >
					<Box

						w={'100%'}
						maxH="500px"
						overflowY="scroll"
						sx={{
						'::-webkit-scrollbar': {
							width: '3px',
						},
						'::-webkit-scrollbar-track': {
							background: "linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)",
						},
						'::-webkit-scrollbar-thumb': {
							background: "#FFF",
							borderRadius: '4px',
						},
						'::-webkit-scrollbar-thumb:hover': {
							background: '#555',
						},
						}}
					>
						<Table variant='simple' color='#fff' w={'100%'}>
							<Thead>
								<Tr my='.7rem' ps='0px'>
									<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										Type
									</Th>
									<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										Age
									</Th>
									{!isSmallScreen && (
										<>
											<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
												{trades[0] ? trades[0].token0_symbol.toUpperCase() : null} Amount
											</Th>
											<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
												{trades[0] ? trades[0].token1_symbol.toUpperCase() : null} Amount
											</Th>
										</>
									)}

									<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										USD Value
									</Th>

									{!isSmallScreen && (
										<>
											<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
												Maker
											</Th>
										</>
									)}
									<Th textAlign="center" color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										Link
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{trades.map((row, index, arr) => {
									return (
										<TradesTable
											tokenId={token_id}
											trade={row}
											lastItem={index === arr.length - 1}
											isSmallScreen={isSmallScreen} // Pass this to your TradesTable component if it needs to know about the screen size
										/>
									);
								})}
							</Tbody>
						</Table>
						</Box>

					</Flex>
				</Card>
			</Grid>
			<Modal isOpen={isModalOpen} onClose={handleModalClose} size={'xl'}>
				<ModalOverlay />
				<ModalContent bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)">
				<ModalHeader>Token Selection</ModalHeader>
				<ModalCloseButton />
				<ModalBody >
					{/* Search bar */}
					<Input
						placeholder="Search tokens"
						mb={4}
						onChange={(e) => setSearchText(e.target.value)}
						/>
					{isSearching ? <Spinner /> : null}
					{/* Tokens table */}
					<Box 
						maxH="500px"
						overflowY="scroll"
						sx={{
						'::-webkit-scrollbar': {
							width: '3px',
						},
						'::-webkit-scrollbar-track': {
							background: "linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)",
						},
						'::-webkit-scrollbar-thumb': {
							background: "#FFF",
							borderRadius: '4px',
						},
						'::-webkit-scrollbar-thumb:hover': {
							background: '#555',
						},
						}}
					>
						<Table variant='simple' color='#fff' maxW={'100%'}>
							<Thead>
								<Tr my='.8rem' ps='0px'>
									<Th
										ps='0px'
										color='gray.400'
										fontFamily='Plus Jakarta Display'
										borderBottomColor='#56577A'>
										Symbol
									</Th>
									<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										Chain
									</Th>
									<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										24 Hr Change
									</Th>
									<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										24Hr Volume
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{searchResults.map((row, index, arr) => {
									return (
										<MainSearch
											selectToken={handleTokenSelection}
											name={row.name}
											logo={row.project_info.logo_url}
											chain={row.chain}
											change={row.price_change}
											volume={row.volume}
											price={row.usd_price}
											token_id={row.token_id}
											token={row}
											lastItem={index === arr.length - 1 ? true : false}
										/>
									);
								})}
							</Tbody>
						</Table>
					</Box>
				</ModalBody>
				<ModalFooter>
				</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}



const lineChartOptions = {
	chart: {
	  toolbar: {
		show: false,
	  },
		zoom: {
		  enabled: false
		}
	},
	tooltip: {
	  theme: "dark"
	},
	dataLabels: {
	  enabled: false,
	},
	stroke: {
	  curve: "smooth",
	  width: 3,
	},
	xaxis: {
	  type: "datetime",
	  labels: {
		datetimeFormatter: {
			year: 'yyyy',
			month: 'MMM \'yy',
			day: 'dd MMM',
			hour: 'HH:mm'
		  },
		style: {
		  colors: "#c8cfca",
		  fontSize: "12px",
		},
	  },
	  axisBorder: {
		show: false,
	  },
	  axisTicks: {
		show: false,
	  },
	},
	yaxis: {
	  labels: {
		style: {
		  colors: "#c8cfca",
		  fontSize: "12px",
		},
		  formatter: (value) => {
			  // Use the formatCrypto function
			  const formatted = formatCrypto(value);

			  // Check if zeroCount is defined and is a number
			  if (typeof formatted.zeroCount === 'number') {
				  // Convert zeroCount to subscript
				  const subscriptZeros = formatted.zeroCount.toString().split('').map((digit) => {
					  // Convert each digit to the corresponding subscript character
					  return '₀₁₂₃₄₅₆₇₈₉'[digit];
				  }).join('');

				  return `${formatted.start}${subscriptZeros}${formatted.value}`;
			  } else {
				  // Handle case where zeroCount is not defined or not a number
				  // You might want to return a default string or handle the error as appropriate
				  return value.toString(); // Fallback to the default .toString() for the value
			  }
		  }
	  },
	},
	legend: {
	  show: false,
	},
	grid: {
	  strokeDashArray: 5,
	  borderColor: "#56577A"
	},
	fill: {
	  type: "gradient",
	  gradient: {
		shade: "dark",
		type: "vertical",
		shadeIntensity: 0,
		gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
		inverseColors: true,
		opacityFrom: 0.8,
		opacityTo: 0,
		stops: [],
	  },
	  colors: ["#2CD9FF", "#582CFF"],
	},
	colors: ["#2CD9FF", "#582CFF"],
  };
