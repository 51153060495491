/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    HStack,
    Link,
    Text,
    useColorModeValue,
    Image
} from "@chakra-ui/react";
import {
    CreativeTimLogo,
    DocumentIcon, GraphIcon,
    HomeIcon,
    PersonIcon,
    RocketIcon, StatsIcon, WalletIcon,
} from "components/Icons/Icons";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import Layer4 from '../../assets/img/Layer 4.png';
import Asset18 from '../../assets/img/Asset18.png';
import Layer14 from '../../assets/img/Layer14.png';
export default function AuthNavbar(props) {
    const [open, setOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const { logo, logoText, secondary, ...rest } = props;
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    // Chakra color mode
    let navbarIcon = "white";
    let mainText = "white";
    let navbarBg =
        "linear-gradient(123.64deg, rgba(255, 255, 255, 0) -22.38%, rgba(255, 255, 255, 0.039) 70.38%)";
    let navbarBorder = "rgba(226, 232, 240, 0.3)";
    let navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
    );
    let navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
    );
    let navbarBackdrop = "blur(42px)";
    let navbarPosition = "relative";
    var brand = (
        <Link
            href={`${process.env.PUBLIC_URL}/#/`}
            target='_blank'
            display='flex'
            lineHeight='100%'
            fontWeight='bold'
            justifyContent='center'
            alignItems='center'
            color={mainText}>
            <Box>
                <Image w={"100px"} src={Layer4} alt="Lynx Logo" />
            </Box>
        </Link>
    );
    var linksAuth = (
        <HStack display={{ sm: "none", lg: "flex" }}>

            <NavLink to='/home/#nodes'>
                <Button
                    fontSize='sm'
                    ms='0px'
                    me='0px'
                    px='0px'
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant='transparent-with-icon'
                    _hover={{ bg: 'transparent' }}

                    leftIcon={
                        <RocketIcon color={navbarIcon} w='12px' h='12px' me='0px' />
                    }>
                    <Text>Node Service</Text>
                </Button>
            </NavLink>
            <NavLink to='/home/#api'>
                <Button
                    fontSize='sm'
                    ms='0px'
                    me='0px'
                    px='0px'
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant='transparent-with-icon'
                    _hover={{ bg: 'transparent' }}
                    leftIcon={<StatsIcon color={navbarIcon} w='12px' h='12px' me='0px' />}>
                    <Text>API</Text>
                </Button>
            </NavLink>
            <NavLink to='/home/#pricing'>
                <Button
                    fontSize='sm'
                    ms='0px'
                    me='0px'
                    px='0px'
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant='transparent-with-icon'
                    _hover={{ bg: 'transparent' }}
                    leftIcon={<WalletIcon color={navbarIcon} w='12px' h='12px' me='0px' />}>
                    <Text>Pricing</Text>
                </Button>
            </NavLink>
            <NavLink to='/auth/signup'>
                <Button
                    fontSize='sm'
                    ms='0px'
                    me='0px'
                    px='0px'
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant='transparent-with-icon'
                    _hover={{ bg: 'transparent' }}

                    leftIcon={
                        <DocumentIcon color={navbarIcon} w='12px' h='12px' me='0px' />

                    }>
                    <Text>Documentation</Text>
                </Button>
            </NavLink>
            <NavLink to='/auth/signin'>
                <Button
                    fontSize='sm'
                    ms='0px'
                    px='0px'
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant='transparent-with-icon'
                    _hover={{ bg: 'transparent' }}

                    leftIcon={
                        <PersonIcon color={navbarIcon} w='12px' h='12px' me='0px' />
                    }>
                    <Text>Sign In</Text>
                </Button>
            </NavLink>
        </HStack>
    );
    return (
        <Flex
            position={navbarPosition}
            top='0px'
            left='50%'
            transform='translate(-50%, 0px)'
            background={navbarBg}
            boxShadow={navbarShadow}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            borderRadius='20px'
            px='33px'
            py='22px'
            mx='auto'
            width='100%'
            maxW='100%'
            alignItems='center'>
            <Flex w='100%' justifyContent={{ sm: "start", lg: "space-between" }}>
                {brand}
                <Box
                    ms={{ base: "auto", lg: "0px" }}
                    display={{ base: "flex", lg: "none" }}>
                    <SidebarResponsive
                        iconColor='white'
                        logoText={props.logoText}
                        secondary={props.secondary}
                        routes={routes}
                        {...rest}
                    />
                </Box>
                {linksAuth}
                <NavLink to='/auth/signin' >
                    <Button
                        fontSize='sm'
                        display={{ sm: "none", lg: "flex" }}
                        color={navbarIcon}
                        variant='primary'
                    >
                        <Text>Start Building</Text>
                    </Button>
                </NavLink>
            </Flex>
        </Flex>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string,
};
