import { parseFullSymbol } from './datafeed.js';


const websocketUrl = 'wss://data.lynxcrypto.app/ws';
//const websocketUrl = 'ws://localhost:3334/ws';

let websocket = null; // This will store our WebSocket connection

const channelToSubscription = new Map();
let previousPair = {}; // Initialize previousPair as an empty object
let selectedPair = {}; // Initialize selectedPair as an empty object

window.addEventListener('SelectedPairUpdated', (e) => {
    // Check if the previousPair has a pair_id and it's different from the new pair_id
    if (previousPair.pair_id && previousPair.pair_id !== e.detail.pair_id) {
        unsubscribeFromStream(null, previousPair); // Unsubscribe using the entire previousPair object
    }
    previousPair = {...e.detail}; // Update previousPair with the new details (creates a copy of e.detail)
    selectedPair = {...e.detail}; // Update selectedPair with the new details (creates a copy of e.detail)
    console.log('Received selected pair:', selectedPair);


    // Now, `selectedPair` can be used within datafeed.js as needed
});

async function ensureWebSocketConnection() {
    if (websocket === null || websocket.readyState === WebSocket.CLOSED) {
        // Define your API key and endpoint
        let api_key = "g77CoMvJEG1KsS8eai7Oo5I9SREpnw1h2k76Y4Td"; // Use your actual API key here
        let tokenEndpoint = 'https://data.lynxcrypto.app/api/v1/ws-token';

        try {
            // Make a request to obtain the token
            const response = await fetch(tokenEndpoint, {
                method: 'GET', // or 'POST' if your endpoint expects a POST request
                headers: {
                    'x-api-key': api_key,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch the WebSocket token');
            }

            const data = await response.json();
            const token = data.token;

            // Append the token to the WebSocket URL as a query parameter
            let websocketUrlWithToken = `${websocketUrl}?token=${token}`;

            // Create the WebSocket connection with the token
            websocket = new WebSocket(websocketUrlWithToken);

            websocket.onopen = () => {
                console.log('WebSocket connection established.');
                // Resubscribe to all channels after reconnecting
                channelToSubscription.forEach((sub, channelString) => {
                    subscribeToChannel(JSON.stringify(sub.channel));
                });
            };

            websocket.onmessage = (event) => {
                // Here you should parse the event.data and update your chart accordingly
                const message = JSON.parse(event.data);
                // You'll need to identify which channel the message is for and call the appropriate callbacks
                // This is a simplified example; you'll need to adapt it to your data format

                const channelString = message.channel;
                const subscriptionItem = channelToSubscription.get(channelString);

                if (subscriptionItem) {
                    subscriptionItem.handlers.forEach(handler => handler.callback(message.data));
                }
            };

            websocket.onerror = (error) => {
                console.log('WebSocket error:', error);
            };

            websocket.onclose = () => {
                console.log('WebSocket connection closed. Attempting to reconnect...');
                // Optionally implement reconnection logic here
                ensureWebSocketConnection();
            };

            // Add other necessary WebSocket event listeners here...
        } catch (error) {
            console.error('Error during WebSocket connection setup:', error);
        }
    }
}




function subscribeToChannel(channelString) {
    if (websocket.readyState === WebSocket.OPEN) {
        websocket.send(channelString);
        console.log('Subscribed to channel:', channelString);
    } else {
        console.log('WebSocket is not open. Subscription request delayed until connection is established.');
    }
}

export function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback, lastDailyBar) {
    const parsedSymbol = parseFullSymbol(`${symbolInfo.exchange}:${symbolInfo.name}`);
    const channel = {
        "action": "subscribe",
        "subscription_type": "Candles",
        "pair_id": selectedPair.pair_id,
        "interval": "15m",
        "asset_id": selectedPair.token_id
    };

    const channelString = `candles::${channel.pair_id}::${channel.interval}::${channel.asset_id}`
    ensureWebSocketConnection(); // Ensure the WebSocket connection is open


    const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback,
    };

    let subscriptionItem = channelToSubscription.get(channelString);

    if (subscriptionItem) {
        // Already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
    } else {
        subscriptionItem = {
            subscriberUID,
            channel,
            resolution,
            lastDailyBar,
            handlers: [handler],
        };
        channelToSubscription.set(channelString, subscriptionItem);
        subscribeToChannel(JSON.stringify(channel)); // Send the subscription message
    }
    console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
}

export function subscribeTradesOnStream(symbolInfo, onRealtimeCallback, subscriberUID, pair) {
    console.log('yessssssssssssssss')



    const channel = {
        "action": "subscribe",
        "subscription_type": "Trades",
        "pair_id": pair?.pair_id || selectedPair.pair_id,
        "asset_id": pair?.token_id || selectedPair.token_id
    };

    console.log(`channel: ${channel.pair_id}`)


    const channelString = `trades::${channel.pair_id}::${channel.asset_id}`;

    ensureWebSocketConnection(); // Ensure the WebSocket connection is open

    const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback,
    };
    console.log(channel, channelString)

    let subscriptionItem = channelToSubscription.get(channelString);

    if (subscriptionItem) {
        // Already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
    } else {
        subscriptionItem = {
            subscriberUID,
            channel,
            handlers: [handler],
        };
        channelToSubscription.set(channelString, subscriptionItem);
        subscribeToChannel(JSON.stringify(channel)); // Send the subscription message
    }
    console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
}

export function unsubscribeFromStream(subscriberUID, pair) {
    const candles_channel = {
        "action": "unsubscribe",
        "subscription_type": "Candles",
        "pair_id": pair.pair_id,
        "interval": "15m",
        "asset_id": pair.token_id
    };

    const trades_channel = {
        "action": "unsubscribe",
        "subscription_type": "Trades",
        "pair_id": pair.pair_id,
        "asset_id": pair.token_id
    };

    console.log(`Unsubbing from: ${pair.pair_id}`)

    subscribeToChannel(JSON.stringify(candles_channel));
    subscribeToChannel(JSON.stringify(trades_channel));
    // To Do
}