import {
    Box,
    Button,
    CircularProgress, CircularProgressLabel,
    Flex,
    Grid,
    Icon,
    Progress,
    Spinner,
    Text,
    useColorModeValue,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel
} from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import React, {useContext, useState, useEffect} from "react";
import axios from 'axios';

import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";
import CardHeader from "../../../../components/Card/CardHeader";
import LineChart from "../../../../components/Charts/LineChart";
import Card from "../../../../components/Card/Card";
import {IoEllipsisHorizontal} from "react-icons/io5";
import {useApi} from "../../../../context/ApiContext";
import RpcUsageChart from "../../../../components/Charts/RpcUsageChart";
import ApiUsageChart from "../../../../components/Charts/ApiUsageChart";
import ApiUsageRemainingUsage from "../../../../components/Charts/ApiUsageRemainingUsage";


function UsageScreen() {
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [invoices, setInvoices] = useState([])
    const [subscription, setSubscription] = useState([]);
    const [socket] = useAtom(socketAtom);
    const [user] = useAtom(userAtom);
    const api = useApi();

    const [todayUsage, setTodayUsage] = useState(0);
    const [thisWeeksUsage, setThisWeeksUsage] = useState([]);

    const [todayApiUsage, setTodayApiUsage] = useState(0);
    const [thisWeeksApiUsage, setThisWeeksApiUsage] = useState([]);
    const [rpcUsageByDay, setRpcUsageByDay] = useState([]);

    const lineChartOptionsDashboard = {
        chart: {
            toolbar: {
                show: true,
            },
        },
        tooltip: {
            theme: "dark"
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 4,
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
                style: {
                    colors: "#c8cfca",
                    fontSize: "12px",
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#c8cfca",
                    fontSize: "12px",
                },
            },
        },
        legend: {
            show: false,
        },
        grid: {
            strokeDashArray: 5,
            borderColor: "#56577A"
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                shadeIntensity: 0,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 0.8,
                opacityTo: 0,
                stops: [],
            },
            colors: ["#2CD9FF", "#582CFF"],
        },
        colors: ["#2CD9FF", "#582CFF"],
    };

    useEffect(() => {
        // Fetch the usage for the logged-in user
        const fetchUsage = async () => {
            try {
                //console.log(user)
                const response = await api.get(`${process.env.REACT_APP_API_URL}/user-usage/${user._id}`);


                if (response.data && response.data.totalUsage) {
                    setTodayUsage(response.data.totalUsage);
                    let options = lineChartOptionsDashboard;
                    options.xaxis.categories = response.data.categories;
                    setLineChartOptions(options);
                }
            } catch (error) {
                console.error('Error fetching today usage:', error);
            }
        };

        const fetchWeeklyUsage = async () => {
            try {
                const response = await api.get(`${process.env.REACT_APP_API_URL}/user-weekly-usage/${user._id}`);

                //console.log(response.data)
                if (response.data) {
                    setThisWeeksUsage(response.data.series);

                }
            } catch (error) {
                console.error('Error fetching today usage:', error);
            }
        };


        const fetchRpcUsageByDay = async () => {
            try {
                const response = await api.get(`${process.env.REACT_APP_API_URL}/user-usage-by-rpc/${user._id}`);

                if (response.data) {
                    console.log('RPC Usage Data:', response.data);
                    setRpcUsageByDay(response.data); // Assuming the response is an array of usage data
                }
            } catch (error) {
                console.error('Error fetching RPC usage by day:', error);
            }
        };

        if ( user._id){
            fetchUsage();
            fetchWeeklyUsage();
            fetchRpcUsageByDay();
        }

    }, [user._id]);





    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px", sm: '120px' }} mx='auto' justify={'center'} align={'center'}>

            <Flex direction="column"  w={'100%'}>
                <Grid templateColumns={{ sm: '1fr', md: '1fr 2fr', '2xl': '1fr 3fr' }} my='26px' gap='18px'>

                    <Card  rowSpan={2}>
                        <Flex direction='column'>
                            <Flex justify='space-between' align='center' mb='40px'>
                                <Text color='#fff' fontSize='lg' fontWeight='bold'>
                                    Today's RPC Usage
                                </Text>

                            </Flex>
                            <Flex direction={{ sm: 'column', md: 'row' }}>
                                <Flex direction='column' me={{ md: '6px', lg: '52px' }} mb={{ sm: '16px', md: '0px' }}>
                                    <Flex
                                        direction='column'
                                        p='22px'
                                        pe={{ sm: '22e', md: '8px', lg: '22px' }}
                                        minW={{ sm: '220px', md: '140px', lg: '220px' }}
                                        bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
                                        borderRadius='20px'
                                        mb='20px'>
                                        <Text color='gray.400' fontSize='sm' mb='4px'>
                                            Remaining Calls
                                        </Text>
                                        <Text color='#fff' fontSize='lg' fontWeight='bold'>
                                            {(user.limit - todayUsage).toLocaleString()}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        direction='column'
                                        p='22px'
                                        pe={{ sm: '22px', md: '8px', lg: '22px' }}
                                        minW={{ sm: '170px', md: '140px', lg: '170px' }}
                                        bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
                                        borderRadius='20px'>
                                        <Text color='gray.400' fontSize='sm' mb='4px'>
                                            Used Calls
                                        </Text>
                                        <Text color='#fff' fontSize='lg' fontWeight='bold'>
                                            {todayUsage.toLocaleString()}
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Box mx={{ sm: 'auto', md: '0px' }}>
                                    <CircularProgress
                                        size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
                                        value={(todayUsage / user.limit) * 100}
                                        thickness={6}
                                        color='#05CD99'
                                        variant='vision'>
                                        <CircularProgressLabel>
                                            <Flex direction='column' justify='center' align='center'>
                                                <Text color='gray.400' fontSize='sm'>
                                                    Usage
                                                </Text>
                                                <Text
                                                    color='#fff'
                                                    fontSize={{ md: '36px', lg: '50px' }}
                                                    fontWeight='bold'
                                                    mb='4px'>
                                                    {((todayUsage / user.limit) * 100).toFixed(0)}%
                                                </Text>
                                                <Text color='gray.400' fontSize='sm'>
                                                    Percentage
                                                </Text>
                                            </Flex>
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                </Box>
                            </Flex>
                        </Flex>
                    </Card>
                    <Card >
                        <CardHeader mb='20px' ps='22px'>
                            <Flex direction='column' alignSelf='flex-start'>
                                <Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
                                    RPC Node Requests By Chain
                                </Text>

                            </Flex>
                        </CardHeader>
                        <Box w='100%' minH={{ sm: '300px' }}>
                            <LineChart
                                lineChartData={thisWeeksUsage}
                                lineChartOptions={lineChartOptionsDashboard}
                            />
                        </Box>
                    </Card>

                    <ApiUsageRemainingUsage />

                    <RpcUsageChart />


                </Grid>

                <Grid templateColumns={{ sm: '1fr', md: '1', '2xl': '1fr 1fr' }} my='26px' gap='18px'>
                    <ApiUsageChart />
                </Grid>
            </Flex>
        </Flex>
    );
}

export default UsageScreen;