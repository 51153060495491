import * as React from 'react';
import {Container, chakra, Stack, Text, Button, Box, Flex, Link} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import {FaGithub, FaRegNewspaper} from 'react-icons/fa';
import {RocketIcon} from "../Icons/Icons";
import {NavLink} from "react-router-dom";

const HeroSection = () => {
    return (
        <Flex p={{ base: 8, sm: 14 }} bg={"linear-gradient(159.02deg, #0F123B 14.25%, #090D2E 56.45%, #020515 86.14%)"} width={'100%'} justifyContent={'center'}>
            <Stack direction="column" spacing={6} alignItems="center">
                <Box py={2} px={4} bg="#300EAA" w="max-content" color="white" rounded="md" fontSize="sm">
                    <Stack direction={{ base: 'column', sm: 'row' }}>
                        <Text fontWeight="bold">Ready, Set, Build! 🚀</Text>
                    </Stack>
                </Box>
                <chakra.h1
                    fontSize={{ base: '4xl', sm: '5xl' }}
                    fontWeight="bold"
                    textAlign="center"
                    maxW="500px"
                >
                    Build your web3 app {'     '}
                    <chakra.h2
                        fontSize="5xl"
                        lineHeight={1.2}
                        fontWeight="bold"
                        bgGradient="linear(to-l, #0ea5e9,#2563eb)"
                        bgClip="text"
                    >
                        with speed
                    </chakra.h2>
                </chakra.h1>
                <Text maxW="550px" fontSize="xl" textAlign="center" color="gray.500">
                    The Lynx platform unlocks a new level of speed for web3 development. All the data you need at your
                    fingertips.
                </Text>
                <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    w={{ base: '100%', sm: 'auto' }}
                    spacing={5}
                >
                    <NavLink to='/app/nodes' >
                        <Button
                            colorScheme="secondary"
                            variant="outline"
                            rounded="md"
                            size="lg"
                            height="3.5rem"
                            fontSize="1.2rem"
                        >
                            <RocketIcon mr={3}/> Let's Go!
                        </Button>
                    </NavLink>
                    <Link color='white' fontSize='sm' href='https://lynx-4.gitbook.io/api'>
                        <Button
                            leftIcon={<FaRegNewspaper />}
                            colorScheme={'blue'}
                            variant="outline"
                            rounded="md"
                            size="lg"
                            height="3.5rem"
                            fontSize="1.2rem"
                        >
                            Read The Docs
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        </Flex>
    );
};

export default HeroSection;