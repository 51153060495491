import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {HashRouter, Route, Switch, Redirect, useHistory, useLocation} from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import HomeLayout from "layouts/Home.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import io from 'socket.io-client';
import { useAtom } from 'jotai';
import {isAuthenticatedAtom, jwtTokenAtom, socketAtom, userAtom} from './context/Atoms';  // assuming you have named it jwtTokenAtom
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc, mainnet, polygon } from 'wagmi/chains';
Amplify.configure(awsExports);
import {ApiProvider, useApi} from './context/ApiContext';
const chains = [bsc, mainnet, polygon];
const projectId = 'fb81a45d04763b498d6bd9f242373350';
import Cookies from 'js-cookie';
import axios from "axios";


const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const App = () => {
    const [jwtToken, setJwtToken] = useAtom(jwtTokenAtom);
    const [socket, setSocket] = useAtom(socketAtom);
    const [user, setUser] = useAtom(userAtom);
    const history = useHistory();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
    const api = useApi()

    useEffect(() => {
        const fetchUserDetails = async (email) => {
            try {
                // Retrieve the JWT token from cookies
                const jwtToken = Cookies.get('jwtToken');
                //console.log(jwtToken)

                // Set up the Authorization header with the JWT token
                const config = {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                };

                // Make the request to the API with the Authorization header
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${email}`, config);
                if (response.status === 200) {

                    return response.data;
                }
            } catch (error) {
                console.error(`Error fetching user details for email: ${email}`, error);
            }
            return null;
        };

        Auth.currentAuthenticatedUser()
            .then(async userAuth => {
                const jwt = userAuth && userAuth.signInUserSession ? userAuth.signInUserSession.idToken.jwtToken : null;
                const groups = userAuth.signInUserSession.idToken.payload['cognito:groups'] || [];
                //console.log(groups)

                if (jwt) {
                    Cookies.set('jwtToken', jwt); // Set the JWT token as a cookie for 7 days
                    const backendUserData = await fetchUserDetails(userAuth.attributes.email);
                    if (backendUserData && backendUserData.stripeSubscriptionId) {
                        setUser({ ...userAuth, ...backendUserData, groups });
                        //console.log(userAuth)
                    } else {
                        setUser(userAuth); // Setting the user even if backend details are not available
                        history.push('/auth/signup', {planNeeded: true });
                    }
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false)

                }

                const newSocket = io(process.env.REACT_APP_API_URL, {
                    auth: {
                        api_key: '649a526ea198f0622b467494',
                        jwt: jwt
                    }
                });

                setSocket(newSocket);
                return () => newSocket.close(); // Disconnect the socket when the component unmounts
            })
            .catch(err => {
                console.log('User not authenticated:', err);
                setIsAuthenticated(false);
                setUser(undefined)


                const newSocket = io(process.env.REACT_APP_API_URL, {
                    auth: {
                        api_key: '649a526ea198f0622b467494',
                    }
                });

                setSocket(newSocket);
                return () => newSocket.close(); // Disconnect the socket when the component unmounts
            });


    }, [setSocket, setUser, jwtToken]);


    if (!socket) return null; // Render nothing until the socket is initialized

    return (
        <WagmiConfig config={wagmiConfig}>
            <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/home`} component={HomeLayout} />
                {isAuthenticated && <Route path="/app" component={AdminLayout} />}
                <Route path="/auth/signin" /> {/* If you have a SignIn component, render it here */}
                {/* Redirect unauthenticated users to sign-in and catch-all uncaught routes for authenticated users */}
                {!isAuthenticated ? (
                    <Redirect to="/home" />
                ) : (
                    <Redirect to="/app/usage" />
                )}
            </Switch>
            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} themeMode="dark" enableNetworkView="true"/>
        </WagmiConfig>
    );
};

ReactDOM.render(
    <HashRouter>
        <ApiProvider>
            <App />
        </ApiProvider>
    </HashRouter>,
    document.getElementById("root")
);
