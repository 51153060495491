import React, {useEffect, useState} from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    Input,
    FormControl,
    FormLabel,
    useToast, Image, Switch, Textarea,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel, Grid, Heading
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from "context/Atoms";
import Card from "../../components/Card/Card";
import { useHistory } from 'react-router-dom';
import {useApi} from "../../context/ApiContext"; // Import from react-router-dom

const Listings = () => {
    const [tokenID, setTokenID] = useState('');
    const [tokenData, setTokenData] = useState(null);
    const [projectInfo, setProjectInfo] = useState({
        listed: false,
        list_date: '',
        weekly_votes: 0,
        all_time_votes: 0,
        website: '',
        logo_url: '',
        twitter: '',
        telegram: '',
        discord: '',
        description: ''
    });    const [imageFile, setImageFile] = useState(null);
    const [user] = useAtom(userAtom);
    const toast = useToast();
    const [groupedAds, setGroupedAds] = useState({});
    const [selectedChainId, setSelectedChainId] = useState('56');
    const history = useHistory(); // Initialize useHistory for redirection
    const api = useApi();

    useEffect(() => {
        // Check if the user is authenticated and belongs to the 'admin' group
        //if (!user || !user.groups.includes('admin')) {
        //    // Redirect the user if they are not in the 'admin' group
        //    history.push('/dashboard'); // Redirect to an unauthorized page or home
        //    // Alternatively, for a hard redirect: window.location.href = '/unauthorized';
       // }
        // ... other useEffect code ...
    }, [user, history]);
    // Handler for chain selection buttons
    const handleChainSelect = (chainId) => {
        setSelectedChainId(chainId);

    };
    useEffect(() => {
        // Fetch the ad data from the backend when the component mounts
        const fetchAds = async () => {
            try {
                const response = await api.get(`${process.env.REACT_APP_API_URL}/ads`);
                console.log(response.data)
                setGroupedAds(response.data); // assuming the backend returns an object with grouped ads
            } catch (error) {
                toast({ title: 'Error fetching ads', description: error.message, status: 'error' });
            }
        };
        fetchAds();
    }, []);

    // Function to find the slot for a given ad ID
    const findSlotForAdId = (adId) => {
        return Object.keys(groupedAds).find(slot =>
            groupedAds[slot].some(ad => ad._id === adId)
        );
    };

    // Function to handle changes to ad info (e.g., URL, contract)
    const handleAdInfoChange = (e, adId, field) => {
        const slot = findSlotForAdId(adId);
        if (!slot) return; // If the slot isn't found, do nothing

        const newGroupedAds = { ...groupedAds };
        newGroupedAds[slot] = newGroupedAds[slot].map(ad =>
            ad._id === adId ? { ...ad, [field]: e.target.value } : ad
        );
        setGroupedAds(newGroupedAds);
    };

// Function to handle ad info updates
    const handleAdInfoUpdate = async (adId) => {
        try {
            // First, find the slot for the given ad ID
            const slot = findSlotForAdId(adId);
            if (!slot) {
                throw new Error("Slot not found for the ad.");
            }

            // Find the ad within the slot
            const ad = groupedAds[slot].find(ad => ad._id === adId);
            if (!ad) {
                throw new Error("Ad not found.");
            }

            // Send the updated ad information to the backend
            const response = await api.post(`${process.env.REACT_APP_API_URL}/ads/${adId}/update-info`, ad);
            toast({ title: 'Ad info updated', description: `Updated ad: ${adId}`, status: 'success' });
        } catch (error) {
            toast({ title: 'Error updating ad info', description: error.message, status: 'error' });
        }
    };


// Function to handle image upload for an ad
    const handleAdImageUpload = async (e, adId) => {
        if (!e.target.files[0]) {
            toast({ title: 'Please select an image to upload.', status: 'warning' });
            return;
        }

        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        try {
            const response = await api.post(`${process.env.REACT_APP_API_URL}/ads/${adId}/upload-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const imageUrl = response.data.imageUrl;
            // Find the slot for the given ad ID
            const slot = findSlotForAdId(adId);
            if (!slot) {
                throw new Error("Slot not found for the ad.");
            }

            // Update local state with the new image URL
            const newGroupedAds = {...groupedAds};
            newGroupedAds[slot] = newGroupedAds[slot].map(ad =>
                ad._id === adId ? {...ad, image: imageUrl} : ad
            );
            setGroupedAds(newGroupedAds);

            toast({ title: 'Image uploaded successfully', description: imageUrl, status: 'success' });
        } catch (error) {
            toast({ title: 'Error uploading image', description: error.message, status: 'error' });
        }
    };



    // Handlers
    // Updated token search handler to include selectedChainId
    const handleTokenSearch = async () => {
        try {
            // Construct the token search query with the selected chain ID
            const searchQuery = tokenID + (selectedChainId ? ':' + selectedChainId : '');
            const response = await api.get(`${process.env.REACT_APP_API_URL}/tokens/${searchQuery.toLowerCase()}`);
            setTokenData(response.data);
            response.data.project_info.list_date = formatDateForInput(response.data.project_info.list_date);
            setProjectInfo(response.data.project_info);
        } catch (error) {
            toast({ title: 'Error fetching token', status: 'error' });
            console.error('Error fetching token:', error);
        }
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';

        // Assuming the date is in ISO format like "2021-03-19T23:00:00.000Z"
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  // Converts to "2021-03-19"
    };

    const handleProjectInfoUpdate = async () => {
        try {
            const token = tokenID + (selectedChainId ? ':' + selectedChainId : '');

            await api.post(`${process.env.REACT_APP_API_URL}/tokens/${token.toLowerCase()}/project-info`, projectInfo);
            toast({ title: 'Project info updated', status: 'success' });
        } catch (error) {
            toast({ title: 'Error updating project info', status: 'error' });
            console.error('Error updating project info:', error);
        }
    };

    const handleImageUpload = async () => {
        if (!imageFile) {
            toast({ title: 'Please select an image to upload.', status: 'warning' });
            return;
        }

        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            const token = tokenID + (selectedChainId ? ':' + selectedChainId : '');

            const response = await api.post(`${process.env.REACT_APP_API_URL}/tokens/${token.toLowerCase()}/upload-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const imageUrl = response.data.imageUrl;
            // Update local state or do something with the imageUrl
            setProjectInfo({ ...projectInfo, logo_url: imageUrl });

            toast({ title: 'Image uploaded successfully', description: imageUrl, status: 'success' });
        } catch (error) {
            toast({ title: 'Error uploading image', description: error.message, status: 'error' });
            console.error('Error uploading image:', error);
        }
    };

    return (
        <Flex direction="column" pt={{ base: "130px", md: "125px" }} w={'100%'}>
        <Card>
        <Tabs variant='soft-rounded'>
            <TabList mb='1em'>
                <Tab>Listings</Tab>
                <Tab>Ads</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <Flex direction='column' >
                        {/* Chain Selection Buttons */}
                        <Box mb={4}>
                            <Button
                                colorScheme={selectedChainId === '56' ? 'blue' : 'ghost'}
                                onClick={() => handleChainSelect('56')}
                                borderWidth={1}

                                mr={2}
                            >
                                BSC
                            </Button>
                            <Button
                                colorScheme={selectedChainId === '1' ? 'blue' : 'ghost'}
                                onClick={() => handleChainSelect('1')}
                                borderWidth={1}
                                mr={2}
                            >
                                Ethereum
                            </Button>
                            <Button
                                colorScheme={selectedChainId === '137' ? 'blue' : 'ghost'}
                                onClick={() => handleChainSelect('137')}
                                borderWidth={1}

                            >
                                Polygon
                            </Button>
                        </Box>
                        {/* Token Lookup */}
                        <Box>
                            <FormControl>
                                <FormLabel htmlFor='token_id'>Token ID</FormLabel>
                                <Input
                                    id='token_id'
                                    value={tokenID}
                                    onChange={(e) => setTokenID(e.target.value)}
                                />
                                <Button mt={4} onClick={handleTokenSearch} variant={'primary'}>Lookup Token</Button>
                            </FormControl>
                        </Box>

                        {/* Display Token Data */}
                        {tokenData && (
                            <>
                            <Box mt={4}>
                                <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
                                    <Image src={tokenData.project_info.logo_url} w={"30px"} me='18px' alt={tokenData.name} />
                                    <Text fontSize='md' color={'white'} minWidth='100%' fontWeight={'bold'}>
                                        {tokenData.name.toUpperCase()}
                                    </Text>
                                </Flex>
                                <Text><b>Contract:</b> {tokenData.contract}</Text>
                                <Text><b>Price:</b> {tokenData.usd_price}</Text>
                                <Text><b>Symbol:</b> {tokenData.symbol.toUpperCase()}</Text>
                                {/* Add more fields as needed */}
                            </Box>


                        {/* Update Project Info */}
                        <Box mt={4}>
                            <FormControl>
                                {/* ... existing fields ... */}
                                <FormLabel htmlFor='listed'>Listed</FormLabel>
                                <Switch id='listed' isChecked={projectInfo.listed} onChange={(e) => setProjectInfo({ ...projectInfo, listed: e.target.checked })} />
                                <FormLabel htmlFor='list_date'>List Date</FormLabel>
                                <Input
                                    id='list_date'
                                    type='date'
                                    value={projectInfo.list_date}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, list_date: e.target.value })}
                                />
                                {/* ... other fields ... */}
                                <FormLabel htmlFor='website'>Website</FormLabel>
                                <Input
                                    id='website'
                                    value={projectInfo.website}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, website: e.target.value })}
                                />
                                <FormLabel htmlFor='telegram'>Logo Url</FormLabel>
                                <Input
                                    id='telegram'
                                    value={projectInfo.logo_url}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, logo_url: e.target.value })}
                                />
                                <FormLabel htmlFor='twitter'>Twitter</FormLabel>
                                <Input
                                    id='twitter'
                                    value={projectInfo.twitter}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, twitter: e.target.value })}
                                />
                                <FormLabel htmlFor='telegram'>Telegram</FormLabel>
                                <Input
                                    id='telegram'
                                    value={projectInfo.telegram}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, telegram: e.target.value })}
                                />
                                <FormLabel htmlFor='discord'>Discord</FormLabel>
                                <Input
                                    id='discord'
                                    value={projectInfo.discord}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, discord: e.target.value })}
                                />
                                <FormLabel htmlFor='description'>Description</FormLabel>
                                <Textarea
                                    id='description'
                                    value={projectInfo.description}
                                    onChange={(e) => setProjectInfo({ ...projectInfo, description: e.target.value })}
                                />
                                <Button mt={4} variant={'primary'} onClick={handleProjectInfoUpdate}>Update Project Info</Button>
                            </FormControl>
                        </Box>

                        {/* Image Upload to S3 */}
                        <Box mt={4}>
                            <FormControl>
                                <FormLabel htmlFor='image_file'>Upload Image</FormLabel>
                                <Input
                                    id='image_file'
                                    type='file'
                                    onChange={(e) => setImageFile(e.target.files[0])}
                                />
                                <Button mt={4} variant={'primary'} onClick={handleImageUpload}>Upload Image</Button>
                            </FormControl>
                        </Box>
                        </>
                        )}
                    </Flex>
                </TabPanel>
                <TabPanel>
                    <Flex direction="column" w={'100%'}>
                        {Object.entries(groupedAds).map(([slot, ads]) => (
                            <Box key={slot} mb={10}>
                                <Heading size="md" mb={4}>{`Slot: ${slot}`}</Heading>
                                <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', '2xl': '1fr 1fr 1fr' }} gap='18px'>
                                    {ads.map(ad => (
                                        <Card key={ad._id} p='28px'>
                                            <FormControl>
                                                <Text fontWeight={'bold'} fontSize={'2xl'}>{ad.slot}</Text>

                                                {/* Editable fields */}
                                                {
                                                    ad.slot.startsWith('search')
                                                        ?
                                                        <>
                                                            <FormLabel mt={4}>Contract</FormLabel>
                                                            <Input value={ad.contract || ''} onChange={(e) => handleAdInfoChange(e, ad._id, 'contract')} />
                                                        </>
                                                        :
                                                        <>
                                                            <FormLabel mt={4}>URL</FormLabel>
                                                            <Input value={ad.url || ''} onChange={(e) => handleAdInfoChange(e, ad._id, 'url')} />
                                                        </>
                                                }

                                                <FormLabel mt={4}>Clicks</FormLabel>
                                                <Input value={ad.clicks} readOnly />

                                                {/* Date inputs */}
                                                <FormLabel mt={4}>Start Date</FormLabel>
                                                <Input type="date" value={formatDateForInput(ad.start_date)} onChange={(e) => handleAdInfoChange(e, ad._id, 'start_date')} />

                                                <FormLabel mt={4}>End Date</FormLabel>
                                                <Input type="date" value={formatDateForInput(ad.end_date)} onChange={(e) => handleAdInfoChange(e, ad._id, 'end_date')} />

                                                {/* Image upload for non-search ads */}
                                                {!ad.slot.startsWith('search') && (
                                                    <>
                                                        <FormLabel mt={4}>Image URL</FormLabel>
                                                        <Input value={ad.image || ''} onChange={(e) => handleAdInfoChange(e, ad._id, 'image')} />

                                                        <FormLabel mt={4}>Image</FormLabel>

                                                        <Image src={ad.image} w={"100%"} mb={4} />

                                                        <Input type='file' onChange={(e) => handleAdImageUpload(e, ad._id)} />
                                                    </>
                                                )}

                                                {/* Button to submit changes */}
                                                <Button mt={4} colorScheme="blue" onClick={() => handleAdInfoUpdate(ad._id)}>Update Info</Button>
                                            </FormControl>
                                        </Card>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    </Flex>
                </TabPanel>

            </TabPanels>
        </Tabs>
        </Card>
        </Flex>
    );
};



export default Listings;
