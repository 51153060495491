import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Progress,
    Text, HStack, Flex, useToast, Box,
} from '@chakra-ui/react';
import {useAtom} from "jotai";
import {userAtom} from "../../context/Atoms";
import axios from "axios";
import TokenSelection from "./TokenSelection";

function WalletWizard({ isOpen, onClose, setWalletData }) {
    const [step, setStep] = useState(1);
    const [walletDetails, setWalletDetails] = useState({ address: '', name: '' });
    const [walletStats, setWalletStats] = useState({})
    const [user] = useAtom(userAtom);
    const toast = useToast();



    const saveDetails = async () => {
        setStep(step + 1); // Proceed to next step
        // Check for validation
        if (walletDetails.name === '' || walletDetails.address === '') {
            // Handle validation error
            console.log("Validation Error");
            return;
        }

        try {
            const email = user.attributes.email;

            const headers = {
                "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM",
            };

            // Add Wallet API Call
            const addWalletResponse = await axios.post(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/add?email=${email}&wallet=${walletDetails.address.toLowerCase()}&name=${walletDetails.name}`, {}, { headers });
            console.log(addWalletResponse.data);

            if (addWalletResponse.data.results === true) {
                // Get Transactions Details API Call
                const transDetailsResponse = await axios.get(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/get-v-transactions?wallet=${walletDetails.address.toLowerCase()}`, { headers });
                console.log(transDetailsResponse.data);

                // Add to Stream API Call
                const streamDetailsResponse = await axios.post(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/add-to-stream?wallet=${walletDetails.address.toLowerCase()}`, {}, { headers });
                console.log(streamDetailsResponse.data);

                setWalletStats({
                    tokens: addWalletResponse.data.tokens,
                    txns: transDetailsResponse.data.transactionsAdded,
                    transfers: transDetailsResponse.data.transfersAdded
                })

                // Set the data and navigate to the last step
                // Here you can set your state with the obtained data if needed
                setStep(3);
            } else {
                // Handle other cases from your API response
            }
        } catch (error) {
            console.error('Error during the API calls:', error);
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWalletDetails({ ...walletDetails, [name]: value });
    };

    const handleWalletAddition = () => {
        // Update the walletData with the new wallet details
        setWalletData(prevWalletData => [
            ...prevWalletData,
            {
                wallet_address: walletDetails.address.toLowerCase(),
                wallet_name: walletDetails.name,
            }
        ]);

        // Display a success toast
        toast({
            title: "Wallet Added",
            description: "You have successfully added the wallet.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        // Close the modal
        onClose();
    };


    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <FormControl>

                        <Flex flexDir={'column'} justify={'center'} align={'center'} >

                            <FormLabel>Wallet Address</FormLabel>
                        <Input
                            placeholder="Enter Wallet Address"
                            name="address"
                            value={walletDetails.address}
                            onChange={handleInputChange}
                            width={'70%'}
                        />
                        <FormLabel mt={4}>Wallet Name</FormLabel>
                        <Input
                            placeholder="Enter Wallet Name"
                            name="name"
                            value={walletDetails.name}
                            onChange={handleInputChange}
                            width={'70%'}
                        />

                            <Button mt={4} variant={'primary'} onClick={saveDetails} maxW={'50%'}>
                                Continue
                            </Button>
                        </Flex>

                    </FormControl>
                );
            case 2:
                return (
                    <Box minH={100}>

                        <Progress size="xs" isIndeterminate />
                        <Text fontWeight={'bold'} mt={5}>Scanning Wallet...</Text>

                    </Box>
                );
            case 3:
                return (
                    <TokenSelection
                        walletInfo={walletDetails}
                        walletStats={walletStats}
                        onTokensSaved={handleWalletAddition}
                    />
                );
            default:
                return <Text>Unknown Step</Text>;
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} size={'2xl'}>
            <ModalOverlay />
            <ModalContent
                bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)"
            >
                <ModalHeader>Setup New Wallet</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{renderStepContent()}</ModalBody>
                <ModalFooter>
                    <HStack justify={'space-between'} w={'100%'}>


                    </HStack>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default WalletWizard;
