import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

const lastBarsCache = new Map();
let selectedPair = {}

window.addEventListener('SelectedPairUpdated', (e) => {
    selectedPair = e.detail;
    console.log('Received selected pair:', selectedPair);

    // Now, `selectedPair` can be used within datafeed.js as needed
});
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ["1", "5", "15", "30", "60","D"],
    interval: '15',
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'crypto', value: 'crypto'}
    ]
};

// Makes requests to CryptoCompare API
export async function makeApiRequest(path) {
    try {
        const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
        return response.json();
    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
    const short = `${fromSymbol}/${toSymbol}`;
    return {
        short,
        full: `${exchange}:${short}`,
    };
}

async function getAllSymbols() {
    const data = await makeApiRequest('data/v3/all/exchanges');
    let allSymbols = [];

    for (const exchange of configurationData.exchanges) {
        const pairs = data.Data[exchange.value].pairs;

        for (const leftPairPart of Object.keys(pairs)) {
            const symbols = pairs[leftPairPart].map(rightPairPart => {
                const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
                return {
                    symbol: symbol.short,
                    full_name: symbol.full,
                    description: symbol.short,
                    exchange: exchange.value,
                    type: 'crypto',
                };
            });
            allSymbols = [...allSymbols, ...symbols];
        }
    }
    return allSymbols;
}

// makeApiRequest and generateSymbol implementation
// ...
// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
    const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
    if (!match) {
        return null;
    }
    return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}



export default {
    onReady: (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
        console.log('[searchSymbols]: Method call');
    },
    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        console.log('[resolveSymbol]: Method call', symbolName);
        console.log(extension)

        const symbolInfo = {
            ticker: `${selectedPair?.token0_symbol?.toUpperCase()} / ${selectedPair?.token1_symbol?.toUpperCase()}`,
            name: `${selectedPair?.token0_symbol?.toUpperCase()} / ${selectedPair?.token1_symbol?.toUpperCase()}`,
            description: symbolName,
            type: 'crypto',
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: `${selectedPair?.factory_name}`,
            minmov: 1,
            pricescale: 100000000000,
            has_intraday: true,
            //intraday_multipliers: ['1', '60'],
            visible_plots_set: 'ohlcv',
            supported_resolutions: ['1', '5','15', '30', '60', '1D', '1W'],
            interval: "15",
            volume_precision: 10,
            has_empty_bars: true,
            data_status: 'streaming',
        };

        onSymbolResolvedCallback(symbolInfo);
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        setTimeout (
            async () => {
                let { from, to, firstDataRequest } = periodParams;
                console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
                let asset_id = selectedPair.token_id
                let pair_id = selectedPair.pair_id

                // Convert 'from' timestamp to Date object and format it
                const fromDate = new Date(from * 1000); // 'from' is assumed to be a timestamp in milliseconds
                const formattedFrom = fromDate.toISOString(); // Converts to format like "2024-02-17T02:00:00.000Z"

                // Convert 'from' timestamp to Date object and format it
                const toDate = new Date(to * 1000); // 'from' is assumed to be a timestamp in milliseconds
                const formattedTo = toDate.toISOString(); // Converts to format like "2024-02-17T02:00:00.000Z"
                from = '2024-02-16T01:44:12.373Z'
                console.log(formattedFrom)
                console.log(formattedTo)

                // Construct the URL for your endpoint
                const url = `https://data.lynxcrypto.app/api/v1/candles?asset_id=${asset_id}&from=${formattedFrom}&to=${formattedTo}&pair_id=${pair_id}&interval=15m`;
                var myHeaders = new Headers();
                myHeaders.append("X-API-KEY", "GP9qJRk4i82OVFRazijNP5n0OWUhHn6g2GWJLAus");

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders
                };
                try {
                    const response = await fetch(url, requestOptions);
                    const bars = await response.json();
                    console.log(bars)
                    console.log(`[getBars]: returned ${bars.length} bar(s)`);


                    if (!bars || bars.length === 0) {
                        onHistoryCallback([], { noData: true });
                        return;
                    }

                    onHistoryCallback(bars, { noData: false });
                } catch (error) {
                    console.log('[getBars]: Get error', error);
                    onErrorCallback(error);
                }

            }
        )

    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback
    ) => {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(`${symbolInfo.exchange}:${symbolInfo.name}`)
        );
    },
    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);

    },
};