import {
  Avatar,
  AvatarGroup,
  Flex,
  Text,
  Td,
  Tr,
  Button,
  Link,
  useColorModeValue,
  Image,
  Box,
  Stack,
  Spacer,
} from "@chakra-ui/react";
import BNB from "./../../assets/img/bnb.png";
import ETH from "./../../assets/img/eth.png";
import POLY from "./../../assets/img/poly.png";
import { TriangleUpIcon, TriangleDownIcon, MinusIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns'
import React, { useContext, useEffect, useState, useRef } from 'react';
import { formatNumber } from "utils/format_number";
import { AiOutlineAntDesign } from "react-icons/ai";




function TradesTable(props) {
  const { selectTrade, trade, lastItem, isSmallScreen, tokenId } = props;
  const [timeSinceTrade, setTimeSinceTrade] = useState(formatDistanceToNow(new Date(trade.date), { addSuffix: true }));
  const [linkUrl, setLinkUrl] = useState(null);


  const history = useHistory();

  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    const updateLinkUrl = async () => {
      const url = await getExplorerLink(tokenId);
      setLinkUrl(url);
    };
  
    updateLinkUrl();
  }, [tokenId, trade.hash]);

  const getExplorerLink = (tokenId) => {
    let chainId = tokenId.split(':')[1];
    let baseUrl = '';
  
    switch(chainId) {
      case '1':
        baseUrl = 'https://etherscan.io/';
        break;
      case '56':
        baseUrl = 'https://bscscan.com/';
        break;
      case '137':
        baseUrl = 'https://polygonscan.com/';
        break;
      default:
        console.log('Unsupported chain ID');
    }
  
    return baseUrl;
  }

  useEffect(() => {
    setTimeSinceTrade(formatDistanceToNow(new Date(trade.date), { addSuffix: true }))
    const timer = setInterval(() => {
        setTimeSinceTrade(formatDistanceToNow(new Date(trade.date), { addSuffix: true }));
    }, 60000); // Updates every minute

    return () => {
        clearInterval(timer);
    };
}, [trade.date]);



  // Function to capitalize the first letter of each word in the name
  const capitalizeName = name => 
    name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const handleRowClick = (data) => {
      const path = `/app/chart/${token_id}`;
      history.push(path);
    };

  return (
    <Tr 
      onClick={() => {
        if (linkUrl) {
          window.open(linkUrl+ "tx/" +trade.hash, '_blank')
        }
      }}
      cursor="pointer"
      borderRadius={10}
      _hover={{ bg: "#1A1A3F", borderRadius: "10px" }} // Change this color to suit your theme
    >
  <Td
    textAlign="center"
    alignItems={'center'}
    justify={'center'}
    align={'center'}
    borderBottomColor='#56577A'
    border={lastItem ? "none" : null}

  >
    <Flex align='center' minWidth='100%' flexWrap='nowrap'>
      <Text textAlign="center" fontSize='sm' color={trade.side.toUpperCase() === "SELL" ? "red.400" : "green.400"} fontWeight='bold' minWidth='100%'>
        {trade.side.toUpperCase()}
      </Text>
    </Flex>
  </Td>

  <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} textAlign="center">
    <Text fontSize='sm' color='#fff' fontWeight='bold' minWidth='100%'>
        {timeSinceTrade}
    </Text>
  </Td>
      {/* Conditionally render the following Tds based on isSmallScreen */}
      {!isSmallScreen && (
          <>
            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} textAlign="center">
              <Text fontSize='sm' color='#fff' fontWeight='bold' minWidth='100%'>
                {formatNumber(trade.token0_amount)}
              </Text>
            </Td>

            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} textAlign="center">
              <Text fontSize='sm' color='#fff' fontWeight='bold' minWidth='100%'>
                {formatNumber(trade.token1_amount)}
              </Text>
            </Td>
          </>

      )}

      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} textAlign="center">
        <Text fontSize='sm' color='#fff' fontWeight='bold' minWidth='100%'>
          ${formatNumber(trade.volume)}
        </Text>
      </Td>

      {!isSmallScreen && (
        <>
          <Td onClick={(e) => e.stopPropagation()} borderBottomColor='#56577A' border={lastItem ? "none" : null} textAlign="center">
              <Button
                  as="a"
                  variant={'outlineWhite'}
                  href={`${linkUrl}address/${trade.maker}`}
                  target="_blank"
                  fontSize='sm'
                  minWidth='100%'
                  onClick={(e) => e.stopPropagation()}>
                {trade.maker.slice(0,5)}...{trade.maker.slice(-5)}
              </Button>
            </Td>
        </>
      )}

  <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} textAlign="center">
    <Button
        as="a"
        variant={'outlineWhite'}
        href={linkUrl + "tx/" + trade.hash}
        target="_blank"
        fontSize='xs'
        minWidth='100%'
        onClick={(e) => e.stopPropagation()}>
      <AiOutlineAntDesign />
    </Button>
  </Td>
</Tr>
  );
}

export default TradesTable;
