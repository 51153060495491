import React, {useState, useContext, useEffect} from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Switch,
  Text,
  Icon,
  DarkMode,
  Spinner, Image, useToast, Tag, Stack, useColorModeValue, VStack, Badge
} from "@chakra-ui/react";
// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";
import { useAtom } from 'jotai';
import {
  userAtom,
  authErrorMessageAtom,
  authenticatingAtom,
  awaitingConfirmationAtom,
  plansAtom, selectedSignUpPlan
} from '../../context/Atoms';
import { useSignIn } from '../../hooks/useSignIn';
import { useSignUp } from '../../hooks/useSignUp';
import {useConfirmSignUp} from "../../hooks/useConfirmSignUp";
import {useLocation, useNavigate} from 'react-router-dom';



// Assets
import signUpImage from "assets/img/signUpImage.png";
import signInImage from "../../assets/img/signInImage.png";
import Layer2 from "../../assets/img/Layer2.png";
import {NavLink} from "react-router-dom";
import {useApi} from "../../context/ApiContext";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import {FaArrowLeft, FaCheckCircle, FaRocket, FaTimesCircle} from "react-icons/fa";
import {fetchPlans} from "../../apiCalls/fetchPlans";
import {RocketIcon} from "../../components/Icons/Icons";

function SignUp(props) {
  const [plans, setPlans] = useAtom(plansAtom); // State for plans
  const [selectedPlan, setSelectedPlan] =useAtom(selectedSignUpPlan)
  console.log(selectedPlan);
  const titleColor = "white";
  const textColor = "gray.400";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useAtom(userAtom);
  const [authErrorMessage, setAuthErrorMessage] = useAtom(authErrorMessageAtom);
  const [authenticating, setAuthenticating] = useAtom(authenticatingAtom);
  const [awaitingConfirmation, setAwaitingConfirmation] = useAtom(awaitingConfirmationAtom)
  const [loading, setLoading] =useState(false);
  const signIn = useSignIn();
  const signUp = useSignUp();
  const confirmSignUp = useConfirmSignUp();
  const toast = useToast();
  const api = useApi();
  const [isSignUp, setIsSignUp] = useState(true);
  const [isPlanConfirmation, setIsPlanConfirmation] = useState(false);
  const [isPlanSelection, setIsPlanSelection] = useState(false);

  const bgTimesIcon = useColorModeValue("gray.700", "gray.500");
  const bgButton = useColorModeValue(
      "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
      "gray.800"
  );

  useEffect(() => {
    const loadPlans = async () => {
      const fetchedPlans = await fetchPlans(api);
      console.log(fetchedPlans)
      setPlans(fetchedPlans);
    };

    loadPlans();
  }, []);

  useEffect(() => {
    if (user && !user.stripeCustomerId && !user.stripeSubscriptionId) {
      setIsPlanSelection(true);
      setIsSignUp(false)
    }
  }, [user]);


  const handleSignUpStep1 = async () => {
    await signUp(email, password)
    //await handleClick()
  }
  const handleSignUpStep2 = async () => {
    await confirmSignUp(email, password, confirmationCode);
    if (selectedPlan && selectedPlan.name) {
      setIsSignUp(false);
      setIsPlanConfirmation(true);
    } else {
      setIsSignUp(false);
      setIsPlanSelection(true);
    }

    //await handleClick()
  }



  const handleChangePlans = async () => {

    //await confirmSignUp(email, password, confirmationCode);
    setIsSignUp(false);
    setIsPlanConfirmation(false);
    setIsPlanSelection(true);
    //await handleClick()
  }
  const handleSignUpStep4 = async () => {
    setLoading(true);
    const endpoint = '/checkout-session';
    let eMail = email ? email : user.attributes.email;
    const bodyData = { email: eMail, plan: selectedPlan.name.toLowerCase() };
    let action = 'create'
    try {
      const response = await api.post(endpoint, bodyData);

      if (response.status === 200) {
        const data = response.data;

        if (action === "create") {
          const stripe = await window.Stripe(process.env.REACT_APP_STRIPE_KEY);
          const result = await stripe.redirectToCheckout({ sessionId: data.id });
          if (result.error) {
            toast({
              title: "Error",
              description: result.error.message,
              status: "error",
              duration: 5000,
              isClosable: true
            });
          }
        } else if (action === "change") {
          setCurrentPlan(plan.name);
          toast({
            title: "Success",
            description: data.message,
            status: "success",
            duration: 5000,
            isClosable: true
          });
        }
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex position='relative' overflow={{ lg: "hidden" }} >
      <Flex
        flexDirection='row'
        w={'100%'}
        justifyContent='space-between'
        pt={{ sm: "100px", md: "0px" }}
        >
        <Flex
            flexDirection='column'
            w={'100%'}
            justifyContent='center'
            pt={{ sm: "100px", md: "0px" }}
        >
          <Flex
              alignItems='center'
              justifyContent='start'
              style={{ userSelect: "none" }}
              flexDirection='column'
              mx={{ base: "auto", lg: "unset" }}
              ms={{ base: "auto", lg: "auto" }}
              mt={-0}
              height={'100%'}
              mb='50px'
              w={{ base: "100%", md: "50%", lg: "60%" }}>
        {
          isSignUp ?

                <GradientBorder p='2px' me={{ base: "none", lg: "30px", xl: "none" }} mt={{ base: "60px", md: "140px", lg: "200px" }}>
                  <Flex
                      background='transparent'
                      borderRadius='30px'
                      direction='column'
                      p='40px'

                      minW={{ base: "unset", md: "430px", xl: "450px" }}
                      w='100%'
                      mx={{ base: "0px" }}
                      bg={{
                        base: "rgb(19,21,56)",
                      }}>
                    <Text
                        fontSize='xl'
                        color={textColor}
                        fontWeight='bold'
                        textAlign='center'
                        mb='22px'>
                      Let's get you building!
                    </Text>
                    <FormControl>
                      <FormLabel
                          color={titleColor}
                          ms='4px'
                          fontSize='sm'
                          fontWeight='normal'>
                        Email
                      </FormLabel>
                      <GradientBorder
                          mb='24px'
                          h='50px'
                          w={{ base: "100%", lg: "fit-content" }}
                          borderRadius='20px'>
                        <Input
                            color={titleColor}
                            bg={{
                              base: "rgb(19,21,54)",
                            }}
                            border='transparent'
                            borderRadius='20px'
                            fontSize='sm'
                            size='lg'
                            w={{ base: "100%", md: "346px" }}
                            maxW='100%'
                            h='46px'
                            type='email'
                            placeholder='Your email address'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                      </GradientBorder>
                      <FormLabel
                          color={titleColor}
                          ms='4px'
                          fontSize='sm'
                          fontWeight='normal'>
                        Password
                      </FormLabel>
                      <GradientBorder
                          mb='24px'
                          h='50px'
                          w={{ base: "100%", lg: "fit-content" }}
                          borderRadius='20px'>
                        <Input
                            color={titleColor}
                            bg={{
                              base: "rgb(19,21,54)",
                            }}
                            border='transparent'
                            borderRadius='20px'
                            fontSize='sm'
                            size='lg'
                            w={{ base: "100%", md: "346px" }}
                            maxW='100%'
                            h='46px'
                            type='password'
                            placeholder='Your password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                      </GradientBorder>

                      {authErrorMessage && (
                          <Text color='red.500' textAlign={'center'} alignSelf={'center'}>{authErrorMessage}</Text>
                      )}
                      {awaitingConfirmation ? (
                              <>
                                <FormLabel
                                    color={titleColor}
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='normal'>
                                  Confirmation Code
                                </FormLabel>
                                <GradientBorder
                                    mb='24px'
                                    h='50px'
                                    w={{ base: "100%", lg: "fit-content" }}
                                    borderRadius='20px'>
                                  <Input
                                      color={titleColor}
                                      bg={{
                                        base: "rgb(19,21,54)",
                                      }}
                                      border='transparent'
                                      borderRadius='20px'
                                      fontSize='sm'
                                      size='lg'
                                      w={{ base: "100%", md: "346px" }}
                                      maxW='100%'
                                      h='46px'
                                      type='password'
                                      placeholder='Your confirmation code'
                                      value={confirmationCode}
                                      onChange={(e) => setConfirmationCode(e.target.value)}
                                  />
                                </GradientBorder>
                                <Button
                                    variant='brand'
                                    fontSize='10px'
                                    type='submit'
                                    w='100%'
                                    maxW='350px'
                                    h='45'
                                    mb='20px'
                                    mt='20px'
                                    onClick={handleSignUpStep2}
                                    disabled={authenticating}>
                                  {authenticating ? <Spinner size="sm" /> : "CONFIRM"}
                                </Button>
                              </>

                          ) :

                          <Button
                              variant='brand'
                              fontSize='10px'
                              type='submit'
                              w='100%'
                              maxW='350px'
                              h='45'
                              mb='20px'
                              mt='20px'
                              onClick={handleSignUpStep1}
                              disabled={authenticating}>
                            {authenticating ? <Spinner size="sm" /> : "SIGN UP"}
                          </Button>}

                    </FormControl>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        maxW='100%'
                        mt='0px'>
                      <Text color={textColor} fontWeight='medium'>
                        Already have an account?
                        <NavLink to='/auth/signin' >
                          <Text color={titleColor} as='span' ms='5px' fontWeight='bold'>Sign In</Text>
                        </NavLink>
                      </Text>
                    </Flex>
                  </Flex>
                </GradientBorder>

          : isPlanConfirmation ?

                    <GradientBorder p='2px' me={{ base: "none", lg: "30px", xl: "none" }} mt={{ base: "60px", md: "140px", lg: "200px" }}>
                      <Flex
                          background='transparent'
                          borderRadius='30px'
                          direction='column'
                          justifyContent={'center'}
                          alignItems={'center'}
                          p='40px'
                          minW={{ base: "unset", md: "430px", xl: "450px" }}
                          w='100%'

                          mx={{ base: "0px" }}
                          bg={{
                            base: "rgb(19,21,56)",
                          }}>
                        <Text
                            fontSize='xl'
                            color={textColor}
                            fontWeight='bold'
                            textAlign='center'
                            mb='22px'>
                          Plan Confirmation
                        </Text>




                        <Stack
                            direction={{ sm: "column", lg: "row" }}
                            spacing="20px"
                            mt="30px"
                            mb="80px"
                            shadow='md'
                            borderWidth='1px'
                            borderColor={'gray.600'}
                            borderRadius='lg'
                        >
                          <Card
                              boxShadow={"0px 2px 5.5px rgba(0, 0, 0, 0.1)"}
                              w={{ sm: "300px", md: "650px", lg: "300px" }}
                              bg={'linear-gradient(159.02deg, #0F123B 14.25%, #090D2E 56.45%, #020515 86.14%)'}

                          >
                            <CardHeader mb="30px">
                              <Flex direction="column" w="100%" align="center">
                                {Array.isArray(plans) && plans.length > 0 && <Tag size="sm">{selectedPlan?.name?.toUpperCase()}</Tag>}
                                <Text color={textColor} fontSize="5xl" fontWeight="bold">
                                  {selectedPlan?.price}
                                </Text>
                              </Flex>
                            </CardHeader>
                            <CardBody>
                              <Flex direction="column" w="100%" minHeight={200} justifyContent={'space-between'} >
                                <Stack direction="column" spacing="16px" w="100%" mb="35px" justifyContent={'space-between'} align={'space-between'}>

                                    {selectedPlan.features.map((feature, index) => (
                                        <Flex align="center" key={index}>
                                            <Icon
                                                w="20px"
                                                h="20px"
                                                as={FaCheckCircle}
                                                mr="8px"
                                                color="green.300"
                                            />

                                            <Text color="gray.500" fontWeight="normal" fontSize="md">
                                              {feature}
                                            </Text>
                                        </Flex>

                                  ))}




                                </Stack>
                                <Button
                                    marginTop={10}
                                    variant="no-hover"
                                    fontSize="xs"
                                    bg={bgButton}
                                    color="#fff"
                                    onClick={handleSignUpStep4} // Add the onClick event handler
                                >
                                  JOIN NOW
                                </Button>
                              </Flex>
                            </CardBody>
                            <Text
                                as="a"
                                display="flex"
                                alignItems="center"
                                alignSelf={'flex-start'}
                                fontSize="md"
                                mt={10}
                                color="gray.600"
                                fontWeight="semibold"
                                transition="all .3s ease"
                                _hover={{ color: "gray.500", textDecoration: "none" }}
                                onClick={handleChangePlans}
                                leftIcon={<Icon as={FaArrowLeft} />}
                            >
                              <Icon as={FaArrowLeft} mr="4px" />
                              Change
                            </Text>
                          </Card>
                        </Stack>

                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                          <Text color={textColor} fontWeight='medium'>
                            Already have an account?
                            <NavLink to='/auth/signin' >
                              <Text color={titleColor} as='span' ms='5px' fontWeight='bold'>Sign In</Text>
                            </NavLink>
                          </Text>
                        </Flex>
                      </Flex>
                    </GradientBorder>

          : isPlanSelection ?

                    <GradientBorder p='2px' me={{ base: "none", lg: "-140px", xl: "none" }} mt={{ base: "60px", md: "140px", lg: "100px" }}>
                      <Flex
                          background='transparent'
                          borderRadius='30px'
                          direction='column'
                          p='40px'
                          minW={{ base: "unset", md: "430px", xl: "450px" }}
                          w='100%'
                          mx={{ base: "0px" }}
                          bg={{
                            base: "rgb(19,21,56)",
                          }}>
                        <Text
                            fontSize='xl'
                            color={textColor}
                            fontWeight='bold'
                            textAlign='center'
                            mb='22px'>
                          Choose your plan!
                        </Text>
                        <VStack
                            spacing={4}
                            align='stretch'
                            mb={10}

                        >
                          {plans.map((plan, index) => (
                              <Box
                                  key={index}
                                  p={5}
                                  shadow='md'
                                  borderWidth='1px'
                                  borderColor={'gray.600'}
                                  borderRadius='lg'
                                  bg={selectedPlan === index ? '#131537' : 'linear-gradient(159.02deg, #0F123B 14.25%, #090D2E 56.45%, #020515 86.14%)'}
                                  _hover={{ bg: '#131537' }}
                                  cursor='pointer'
                                  onClick={() => {
                                    setSelectedPlan(plans[index]); // Set the selected plan using the index
                                    setIsPlanSelection(false);
                                    setIsPlanConfirmation(true);
                                    console.log(plans[index]);
                                  }}
                              >
                                <Text fontSize='xl' fontWeight='bold'>
                                  {plan.name}
                                  <HStack display='inline' spacing='2px' ml={3}>
                                    {[...Array(index + 1)].map((_, rocketIndex) => (
                                        <RocketIcon color={'white'} w='22px' h='22px'  />
                                    ))}
                                  </HStack>
                                </Text>
                                <Text fontSize='lg'>${plan.price} / month</Text>
                                <HStack spacing={2} maxW={'100%'} wrap="wrap" mt={5}>
                                  {plan.features.map((feature, featureIndex) => (
                                      <Badge key={featureIndex} px={2} py={1} borderRadius='full' variant='solid' colorScheme='blue'>
                                        {feature}
                                      </Badge>
                                  ))}
                                </HStack>
                              </Box>
                          ))}
                        </VStack>

                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                          <Text color={textColor} fontWeight='medium'>
                            Already have an account?
                            <NavLink to='/auth/signin' >
                              <Text color={titleColor} as='span' ms='5px' fontWeight='bold'>Sign In</Text>
                            </NavLink>
                          </Text>
                        </Flex>
                      </Flex>
                    </GradientBorder>



          : null}
          </Flex>
          <Box
              w={{ base: "335px", md: "450px" }}
              mx={{ base: "auto", lg: "unset" }}
              ms={{ base: "auto", lg: "auto" }}
              mb='90px'
          alignItems={'center'}
          >
            <AuthFooter />
          </Box>
        </Flex>

        <Box
            display={{ base: "none", lg: "block" }}
            overflowX='hidden'
            h='100%'
            maxW={{ md: "50vw", lg: "50vw" }}
            minH='100vh'
            w='960px'
            position='absolute'
            left='0px'>
          <Box
              bgImage={signInImage}
              w='100%'
              h='100%'
              bgSize='cover'
              bgPosition='50%'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              position='relative'>
            <Image mt={-100} w={"50%"} src={Layer2} alt="Logo" />
            <Text
                textAlign='center'
                color='transparent'
                letterSpacing='8px'
                fontSize='36px'
                fontWeight='bold'
                bgClip='text !important'
                bg='white'>

            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignUp;
