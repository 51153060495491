
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Link,
  Switch,
  Text,
  DarkMode,
  LightMode,
} from "@chakra-ui/react";
import GitHubButton from "react-github-btn";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { FaTwitter, FaTelegram } from "react-icons/fa";
import { useAtom } from 'jotai';
import { jwtTokenAtom, userAtom } from 'context/Atoms'; // replace with the actual path and atom names
import { useSignOut } from 'hooks/useSignOut';


export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);
  const [user] = useAtom(userAtom);
  const signOut = useSignOut();
  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }
  let colorButton = "white";
  const secondaryButtonColor = "white";
  const settingsRef = React.useRef();
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}>
        <DrawerContent
          bg='linear-gradient(111.84deg, rgba(6, 11, 38, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%)'
          backdropFilter='blur(42px)'>
          <DrawerHeader pt='24px' px='24px'>
            <DrawerCloseButton color='white' />
            <Text color='white' fontSize='xl' fontWeight='bold' mt='16px' mb='16px'>
              Lynx Crypto
            </Text>
            <Separator />
          </DrawerHeader>
          <DrawerBody w='340px' ps='24px' pe='40px'>
            <Flex flexDirection='column'>
              <Box
                display={fixedDisplay}
                justifyContent='space-between '
                mb='20px'>
                <DarkMode>


                </DarkMode>
              </Box>
              <Box>
                <Box>
                  {
                    user ? (
                      <Link
                          w='100%'
                          mb='16px'>
                          <Button
                            onClick={signOut}
                            w='100%'
                            mb='16px'
                            bg='brand.200'
                            color={colorButton}
                            fontSize='xs'
                            variant='brand'
                            px='30px'>
                            Sign Out
                          </Button>
                      </Link>
                  ) : (
                      <Link
                          w='100%'
                          mb='16px'
                          href='/#/auth/signin'>
                          <Button
                            w='100%'
                            mb='16px'
                            bg='brand.200'
                            color={colorButton}
                            fontSize='xs'
                            variant='brand'
                            px='30px'>
                            Sign In
                          </Button>
                      </Link>
                  )
                  }
                  <Link
                    href='https://lynx-4.gitbook.io/api'
                    w='100%'>
                    <Button
                      w='100%'
                      color={secondaryButtonColor}
                      fontSize='xs'
                      variant='outlineWhite'
                      px='20px'
                      mb='16px'>
                      <Text textDecorationColor='none'> API Documentation</Text>
                    </Button>
                  </Link>
                </Box>
                <Box w='100%' mt={3}>
                  <Text color='white' mb='6px' textAlign='center'>
                    Thank you for sharing!
                  </Text>
                  <LightMode>
                    <Flex justifyContent='center' alignContent='center' mt={3}>
                      <Link
                        isExternal='true'
                        href='https://twitter.com/LynxCryptoApp'>
                        <Button
                          colorScheme='twitter'
                          leftIcon={<FaTwitter />}
                          me='10px'>
                          <Text>Tweet</Text>
                        </Button>
                      </Link>
                      <Link
                        isExternal='true'
                        href='https://t.me/LynxCryptoApp'>
                        <Button
                          colorScheme='telegram'
                          leftIcon={<FaTelegram />}>
                          <Text>Telegram</Text>
                        </Button>
                      </Link>
                    </Flex>
                  </LightMode>
                </Box>
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
