
import {
	Box,
	Button,
	Center,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Grid,
	Image,
	Icon,
	Progress,
	Input,
	SimpleGrid,
	Spacer,
	Stack,
	FormControl,
	FormLabel,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Spinner,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack, useBreakpointValue
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import ReactApexChart from "react-apexcharts";
import medusa from 'assets/img/cardimgfree.png';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import MainSearch from 'components/Tables/MainSearch';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { TriangleUpIcon, TriangleDownIcon, MinusIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import {useAtom} from "jotai";
import {socketAtom} from "../../context/Atoms";
import {MeiliSearch} from "meilisearch";

export default function SearchModal( { isModalOpen, setIsModalOpen } ) {
	const { token_id } = useParams();
	const [socket] = useAtom(socketAtom);
	const [token, setToken] = useState({});
	const [lineChart, setLineChart] = useState([]);
	const [candleChart, setCandleChart] = useState([]);
	const [error, setError] = useState(undefined);
	const toast = useToast();
	const [walletConnected, setWalletConnected] = useState(false);
	const [formData, setFormData] = useState({ tokenA: '', tokenB: '' });
  	const [showForm, setShowForm] = useState(true);
	const [chainId, setChainID] = useState(null)
	const [searchText, setSearchText] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [isSearching, setSearching] = useState(false);
	const timeoutRef = useRef(null);
	const [modalButton, setModalButton] = useState(undefined)
	const [slippage, setSlippage] = React.useState(3);
	const [defaultSearch, setDefaultSearch] = useState([])
	const [percentageChange, setPercentageChange] = useState(0.00)
    const history = useHistory();
	const inputRef = useRef(null);
	const meiliClientRef = useRef(null);

	if (!meiliClientRef.current) {
		meiliClientRef.current = new MeiliSearch({
			host: 'http://66.94.108.2:7700',
			apiKey: 'knfLDF7zbZERvLf7LayTWyy5mJ3ryyPSBfSaJc7qT80',
		});
	}


	const capitalizeName = name => name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

	useEffect(() => {
		if (isModalOpen && inputRef.current) {
		console.log('heya')
		  inputRef.current.focus();
		}
	  }, [setIsModalOpen]);

	

	useEffect(() => {
		
		socket.emit('getDefaultSearchTokens', {chain_id: chainId});

		socket.on('defaultSearchTokens', (data) => {
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			setSearchResults(data.data);
			setDefaultSearch(data.data);
		});
	
		

		socket.on('searchResults', (data) => {
			if (data.error) {
				// Display an error toast
				toast({
				  title: 'Error',
				  description: data.data,
				  status: 'error',
				  duration: 5000,
				  isClosable: true,
				});
				return
			}
			setSearchResults(data.data);
			setSearching(false);
		});

		
		
	  }, [socket]);


	useEffect(() => {
		if (!searchText.trim()) {
			setSearching(false);
			setSearchResults([]);
			return;
		}

		//setSearching(true);

		meiliClientRef.current.index('tokens').search(searchText, {
			sort: ['volume:desc'],
		}).then((response) => {
			setSearchResults(response.hits);
			console.log(response.hits)
			setSearching(false);
		}).catch((error) => {
			console.error('Search error:', error);
			setSearching(false);
		});
	}, [searchText]);

		const handleRowClick = (data) => {
			console.log(data);
			const path = `/app/chart/${data.token_id}`;
			history.push(path);
			setIsModalOpen(false);
		  };

	  const handleTokenSelection = (token) => {
        if (modalButton){
            console.log(token);
            if (modalButton === 'token0') {
            setToken0(token);
            } else if ( modalButton === 'token1') {
            setToken1(token);
            }
            } else {
                
            } 
		setIsModalOpen(false);
	  };
	
	  const handleModalOpen = (data) => {
		setModalButton(data);
		setIsModalOpen(true);
	  };
	
	  const handleModalClose = () => {
		setSearchResults(defaultSearch);
		setIsModalOpen(false);
	  };
	const isSmallScreen = useBreakpointValue({ base: true, md: false });


	return (

        <Modal isOpen={isModalOpen} onClose={handleModalClose} initialFocusRef={inputRef} size={'2xl'}>
				<ModalOverlay />
				<ModalContent 
					bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)" 
					
					>
					
				<ModalHeader>Search</ModalHeader>
				<ModalCloseButton />
				<ModalBody >
					{/* Search bar */}
					<Input
						ref={inputRef}
						placeholder="Search tokens"
						mb={4}
						onChange={(e) => setSearchText(e.target.value)}
						/>
					{isSearching ? <Spinner /> : null}
					{/* Tokens table */}
					<Box 
						maxH="500px"
						overflowY="scroll"

						sx={{
						'::-webkit-scrollbar': {
							width: '3px',
						},
						'::-webkit-scrollbar-track': {
							background: "linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)",
						},
						'::-webkit-scrollbar-thumb': {
							background: "#FFF",
							borderRadius: '4px',
						},
						'::-webkit-scrollbar-thumb:hover': {
							background: '#555',
						},
						}}
					>
					<Table variant='simple' color='#fff' maxW={'100%'} overflowX={'auto'} overflowY={'hidden'}>
						<Thead>
							<Tr my='.8rem' ps='0px'>
								<Th
									ps='0px'
									color='gray.400'
									fontFamily='Plus Jakarta Display'
									borderBottomColor='#56577A'>
									Symbol
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
									Chain
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
									24 Hr Change
								</Th>
								{!isSmallScreen && (
									<>
										<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
											24Hr Volume
										</Th>
									</>
								)}

							</Tr>
						</Thead>
						<Tbody>
							{searchResults.map((row, index, arr) => {
								return (
									<MainSearch
										selectToken={handleRowClick}
										name={row.name}
										logo={row.logo_url}
										chain={row.chain}
										change={row.price_change}
										volume={row.volume}
										price={row.usd_price}
										token_id={`${row.contract}:${row.chain}`}
										token={row}
										isSmallScreen={isSmallScreen}
										lastItem={index === arr.length - 1 ? true : false}
									/>
								);
							})}
						</Tbody>
					</Table>
					</Box>
				</ModalBody>
				<ModalFooter>
				</ModalFooter>
				</ModalContent>
			</Modal>
      )
                        };