import { useAtom } from 'jotai';
import {
    userAtom,
    jwtTokenAtom,
    authErrorMessageAtom,
    authenticatingAtom,
    awaitingConfirmationAtom,
    isSignUpCompleteAtom
} from 'context/Atoms';
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import {useSignIn} from "./useSignIn";
export function useSignUp() {
    const [, setAuthErrorMessage] = useAtom(authErrorMessageAtom);
    const [, setAuthenticating] = useAtom(authenticatingAtom);
    const [, setAwaitingConfirmation] = useAtom(awaitingConfirmationAtom);
    const [, setIsSignUpComplete] = useAtom(isSignUpCompleteAtom);
    const signIn = useSignIn();

    return async (email, password) => {
        try {
            setAuthenticating(true);
            await Auth.signUp({
                username: email,
                password: password,
            });
            setAuthErrorMessage("");
            setAwaitingConfirmation(true);
            setIsSignUpComplete(true);


        } catch (error) {
            console.log(error)
            setAuthErrorMessage(error.message);
        } finally {
            setAuthenticating(false);
        }
    };
}
