import React, { useContext, useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  Center,
  Link,
  List,
  ListItem, useDisclosure,
} from "@chakra-ui/react";
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "../../context/Atoms";
import Card from "../../components/Card/Card";
import Billing from "../Pages/Billing";
import NodeScreen from "../Pages/Nodes";
import PlansTable from "../../components/PlansTable";
import LoginModal from "../../components/signInModal";


function Nodes() {
  const [socket] = useAtom(socketAtom);
  const [user] = useAtom(userAtom);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();



  useEffect(() => {
    socket.emit("getUserData", { apiKey: "YOUR_API_KEY" });

    socket.on("userData", (data) => {
      console.log(data)
      setLoading(false);
      if (!data.error) {
        setUserData(data.data);
      }
    });
  }, [socket]);

  return (
      <Flex direction='column' pt={{ base: "120px", md: "75px", sm: '0px' }} mx='auto' justify={'center'} align={'center'}>
        {loading ? (
            <div>Loading...</div>
        ) : user ? (  // <-- Check if user exists (is logged in)
            userData ? (
                <NodeScreen />
            ) : (
                <PlansTable user={user} currentPlan={null} action={'create'} />
            )
        ) : (
            <Flex
                  mt={'-75px'}
                  height="80vh"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  w={'100%'}

              >
              <Card maxW={{sm: '90%',md:'40%'}}>
                <Box boxShadow="md" p="6" rounded="md" textAlign="center">
                  <Text fontSize="xl" mb="4">You must be logged in to view this page.</Text>
                    <Button colorScheme="blue" onClick={onOpen}>Login Now</Button>
                </Box>
              </Card>
            </Flex>
        )}
        <LoginModal isOpen={isOpen} onClose={onClose} />

      </Flex>
  );
}

export default Nodes;
