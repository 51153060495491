// ApiContext.js
import React, { useContext, createContext, useMemo } from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import { jwtTokenAtom } from './Atoms'; // Adjust the path as necessary
import Cookies from 'js-cookie';

const ApiContext = createContext(null);

export const ApiProvider = ({ children }) => {
    // Assuming useAtom(jwtTokenAtom) returns the current state value as the first element of the array
    const [jwtToken] = useAtom(jwtTokenAtom);

    // Alternatively, or additionally, get the JWT token from a cookie
    const jwtTokenFromCookie = Cookies.get('jwtToken'); // Assuming the cookie name is 'jwtToken'
    const effectiveJwtToken = jwtToken || jwtTokenFromCookie;

    //console.log("API CALL TOKEN IS: " + effectiveJwtToken);

    // Directly create a new Axios instance without useMemo
    //console.log("Creating new Axios instance with token:", effectiveJwtToken);
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    if (effectiveJwtToken) {
        api.defaults.headers.common['Authorization'] = `Bearer ${effectiveJwtToken}`;
    } else {
        console.log("No JWT token available");
    }

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};


export const useApi = () => useContext(ApiContext);
