
import Dashboard from "views/Dashboard/Dashboard.js";
import Tokens from "views/Dashboard/Tokens.js";
import Tables from "./views/Dashboard/Portfolio.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Chart from "views/Pages/Chart.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import Nodes from "./views/Dashboard/Nodes";
import Account from "views/Dashboard/Billing.js";
import Wallets from "./views/Dashboard/Wallets";
import Portfolio from "./views/Dashboard/Portfolio.js";
import Listings from "./views/Pages/Listing";
import Home from "./views/Pages/Home";



var dashRoutes = [

  {
    path: "/dashboardee",
    name: "Discover",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Tokens,
    layout: "/app",
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Portfolio,
    layout: "/app",
  },
  {
    path: "/wallets",
    name: "Wallets",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Wallets,
    layout: "/app",
  },
  {
    path: "/chart/:token_id",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Chart,
    layout: "/app",
  },
  {
    path: "/list",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Listings,
    layout: "/app",
  },

  {
    path: "/nodes",
    name: "Node Service",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Nodes,
    layout: "/app",
  },

  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/",
        name: "Lynx Home",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: Home,
        layout: "/home",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color='inherit' />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },

];

export var dashRoutesGuest = [

  {
    path: "/dashboard",
    name: "Discoverr",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Tokens,
    layout: "/app",
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Portfolio,
    layout: "/app",
  },
  {
    path: "/wallets",
    name: "Wallets",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Wallets,
    layout: "/app",
  },
  {
    path: "/chart/:token_id",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Chart,
    layout: "/app",
  },
  {
    path: "/list",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Listings,
    layout: "/app",
  },

  {
    path: "/nodes",
    name: "Node Serviceee",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Nodes,
    layout: "/app",
  },

  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/",
        name: "Lynx Home",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: Home,
        layout: "/home",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color='inherit' />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },

];



var dashRoutesOld = [
  {
    path: "/dashboard",
    name: "Portfolio",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/app",
  },
  {
    path: "/tokens",
    name: "Discover",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Tokens,
    layout: "/app",
  },
  {
    path: "/chart/:token_id",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Chart,
    layout: "/app",
  },
  {
    path: "/tables",
    name: "Portfolio",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Tables,
    layout: "/app",
  },
  {
    path: "/nodes",
    name: "Node Service",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Nodes,
    layout: "/app",
  },
  {
    path: "/billing",
    name: "Billing",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Account,
    layout: "/app",
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color='inherit' />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/app",
      },
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color='inherit' />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
