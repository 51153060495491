import React from "react";
import {
    Tr,
    Td,
    Flex,
    Text,
    Icon,
    Button,
    useColorModeValue,
    Image,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Box,
    StatArrow, HStack, Menu, MenuButton, MenuItem, MenuList, useToast
} from "@chakra-ui/react";
import {FaEllipsisV, FaTrash} from "react-icons/fa";
import {formatCrypto} from "../../utils/format_number";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {useAtom} from "jotai/index";
import {userAtom} from "../../context/Atoms";

function WalletRow(props) {
    const { name, address, lastItem, wallet_id, setWalletData } = props;
    const textColor = useColorModeValue("white", "white");
    const [user] = useAtom(userAtom);
    const toast = useToast();

    const history = useHistory();


    const handleDelete = async () => {
        try {
            const headers = {
                "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM"
            };
            await axios.delete(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/remove?email=${user.attributes.email}&wallet=${address}`, {
                headers: headers
            });

            // Update the parent component's state
            setWalletData((currentWallets) => currentWallets.filter(w => w.wallet_address !== address));
            toast({
                title: "Wallet Deleted",
                description: "The wallet has been successfully deleted.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting wallet:', error);
        }
    };



    return (
        <Tr>
            <Td
                minWidth={{ sm: "250px" }}
                ps='0px'
                borderBottomColor='#56577A'
                border={lastItem ? "none" : null}>
                <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
                    <Text fontSize='md' color={textColor} minWidth='100%' fontWeight={'bold'}>
                        {name}
                    </Text>
                </Flex>
            </Td>
            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
                <Text fontSize='md' color={textColor} fontWeight='bold' pb='.5rem'>
                    {address}
                </Text>
            </Td>

            <Td borderBottomColor="#56577A" border={lastItem ? "none" : null}>
                <Menu placement="left-start">
                    <MenuButton as={Button} p="0px" bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }}>
                        <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
                    </MenuButton>
                    <MenuList>
                        <MenuItem icon={<FaTrash color={'#181F47'}/>} command="⌘T" onClick={handleDelete}>
                            <Text color={'#181F47'}>Delete</Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Td>

        </Tr>
    );
}

export default WalletRow;
