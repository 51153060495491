import {
    Grid,
    Flex,
    VStack,
    Text,
    Button,
    Icon,
    useColorModeValue,
    Heading,
    Spinner,
    useToast,
    Image, useBreakpointValue
} from "@chakra-ui/react";
import { FaCopy } from "react-icons/fa";
import { useAtom } from 'jotai';
import { userAtom } from "context/Atoms";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import React from "react";
import CardBody from "../../../../components/Card/CardBody";

function Nodes() {
    const [user] = useAtom(userAtom);
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const toast = useToast();

    // Function to copy Node URL to clipboard
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        toast({
            title: "URL copied",
            description: "The Node URL has been copied to your clipboard.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };
    const truncateUrl = (url) => {
        // Define how many characters to show based on the breakpoint
        const breakpoint = useBreakpointValue({ base: 25, md: 25, lg: Infinity });
        return url.length > breakpoint ? `${url.slice(0, breakpoint)}...` : url;
    };


    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px", sm: '120px' }} mx='auto' justify={'center'} align={'center'}>

            <Grid templateColumns={{ sm: '1fr', md: '.5fr .5fr', '2xl': '1fr 1fr' }} my='26px' gap='18px' justifyContent="center">
                {['bsc', 'eth'].map(network => (
                    <Card key={`card-${network}`} maxW={"100%"} >
                        <VStack spacing={4} width={'100%'} alignItems={'flex-start'}>
                            <CardHeader pb="33px">
                                <Flex alignItems="center" w="100%">
                                    <Image src={`https://token-images.s3.amazonaws.com/${network}.png`} w={"50px"} mr={2} alt={name} />

                                    <Text fontSize="xl" fontWeight="bold">
                                        {network.toUpperCase()} Nodes
                                    </Text>
                                </Flex>
                            </CardHeader>
                            <CardBody flexDirection={'column'}>
                            {['http', 'ws'].map(protocol => (
                                <>
                                    <Text fontSize="xl" fontWeight="bold" m={2}>
                                        {protocol.toUpperCase()} URL
                                    </Text>

                                <Flex
                                    m={1}
                                    key={`${network}-${protocol}`}
                                    p="1rem"
                                    bg="transparent"
                                    borderRadius="15px"
                                    w={'100%'}
                                    h={"5rem"}
                                    border="1px solid"
                                    borderColor={borderColor}
                                    align="center"
                                    justify={'space-between'}

                                >
                                    <Flex overflow={'hidden'}>
                                        <Text color="gray.400" fontSize={{ base: "sm", md: "md" }} fontWeight="semibold">
                                            {truncateUrl(`${protocol}s://${network}.lynxlabs.io/${protocol}/${user._id}`)}
                                        </Text>
                                    </Flex>

                                    <Button
                                        leftIcon={<FaCopy />}
                                        colorScheme="blue"
                                        variant="solid"
                                        onClick={() => copyToClipboard(`${protocol}s://${network}.lynxlabs.io/${protocol}/${user._id}`)}
                                    >
                                        Copy
                                    </Button>
                                </Flex>
                                </>
                            ))}
                            </CardBody>
                        </VStack>
                    </Card>
                ))}
            </Grid>
        </Flex>
    );
}

export default Nodes;
