/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect, useState} from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr, Box, Link, Button,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup, StatArrow, HStack,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";



// Data
import { tablesProjectData, tablesTableData } from "variables/general";

// Icons
import { AiFillCheckCircle } from "react-icons/ai";
import { useAtom } from 'jotai';
import { userAtom } from "context/Atoms";
import axios from "axios";
import PortfolioTableRow from "../../components/Tables/PortfolioProjectRow";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";


function Portfolio() {
  const [projectData, setProjectData] = useState([]);
  const [user] = useAtom(userAtom);
  const [totals, setTotals] = useState({ totalValue: 0, overallChange: 0 });

  useEffect(() => {

      async function fetchData() {
        try {
          const headers = {
            "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM"
          };
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/portfolio?email=${user.attributes.email}`, {
            headers: headers
          });

          setProjectData(response.data);
          console.log(response.data);
        } catch (error) {
          console.log('Error fetching data:', error);
        }
      }
    if (user?.attributes.email) {
      fetchData();
    }

    // Whenever projectData changes, recalculate totals
    if (projectData.length > 0) {
      setTotals(calculateTotals(projectData));
    }
  }, [user]);

  useEffect(() => {
    // Whenever projectData changes, recalculate totals
    if (projectData.length > 0) {
      setTotals(calculateTotals(projectData));
    }
  }, [projectData]);

  const calculateTotals = (data) => {
    let totalValue = 0;
    let weightedPriceChange = 0;
    data.forEach(token => {
      const tokenValue = token.balance * token.price;
      totalValue += tokenValue;
      weightedPriceChange += (token.twentyFourHour / 100) * tokenValue; // Convert percentage to value
    });

    // Calculate the overall percentage change
    const overallChange = (totalValue > 0) ? (weightedPriceChange / totalValue) * 100 : 0;

    return { totalValue, overallChange };
  };


  if (!user) {
    return (
        <Flex
            height="80vh"
            justifyContent="center"
            alignItems="center"
            direction="column"

        >

          <Card maxW={'40%'}>
            <Box boxShadow="md" p="6" rounded="md" textAlign="center">
              <Text fontSize="xl" mb="4">You must be logged in to view this page.</Text>
              <Link w='100%' mb='16px' href='/#/auth/signin'>
                <Button colorScheme="blue">Login Now</Button>
              </Link>
            </Box>
          </Card>
        </Flex>
    );
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px", sm: '0px' }}>

      {/* Projects Table */}
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='column' width={'100%'}>
            <Text fontSize='lg' color='#fff' fontWeight='bold' >
              Portfolio
            </Text>
            <Flex mb={4} mt={4} justify={'center'} align={'center'} w={'50%'}>
                <Stat>
                  <StatLabel>Total Portfolio Value</StatLabel>
                  <StatNumber>${totals.totalValue.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>24Hr Portfolio Change</StatLabel>
                  <StatNumber display="flex" alignItems="center" color={totals.overallChange > 0 ? 'green.500' : 'red.500'}>
                    <StatArrow type={totals.overallChange > 0 ? 'increase' : 'decrease'} />
                    {totals.overallChange.toFixed(2)}%
                  </StatNumber>
                </Stat>
            </Flex>

          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant='simple' color='#fff'>
            <Thead>
              <Tr my='.8rem' ps='0px'>
                <Th
                  ps='0px'
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Token
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Balance
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Price
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  24Hr Change
                </Th>
                <Th borderBottomColor='#56577A'></Th>
              </Tr>
            </Thead>
            <Tbody>
              {projectData.map((row, index, arr) => {
                console.log(row.token_id);
                return (
                    <PortfolioTableRow
                        logo={row.url}
                        name={row.name}
                        status={row.price}
                        budget={row.balance * row.price}
                        progression={row.twentyFourHour}
                        token_id={row._id}
                        lastItem={index === arr.length - 1}
                        setProjectData={setProjectData}

                    />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Portfolio;
