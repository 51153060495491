import React from "react";
import {
    Tr,
    Td,
    Flex,
    Text,
    Icon,
    Button,
    useColorModeValue,
    Image,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Box,
    StatArrow, HStack, MenuButton, MenuList, MenuItem, Menu
} from "@chakra-ui/react";
import {FaEllipsisV, FaEye, FaEyeSlash, FaTrash} from "react-icons/fa";
import {formatCrypto} from "../../utils/format_number";
import {useHistory} from "react-router-dom";
import {useAtom} from "jotai";
import {userAtom} from "../../context/Atoms";
import axios from "axios";

function PortfolioTableRow(props) {
    const { logo, name, status, budget, progression, lastItem, token_id, setProjectData } = props;
    const textColor = useColorModeValue("white", "white");
    const history = useHistory();
    const progressionValue = parseFloat(progression).toFixed(2);
    const progressionColor = progression > 0 ? "green.400" : "red.400";
    const changeValue = progression > 0 ? "increase" : "decrease";
    const formattedPrice = formatCrypto(status);
    const [user] = useAtom(userAtom);

    // Helper function to format number as USD
    const formatToUSD = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }

    const handleRowClick = (event, data) => {
        if (event) {
            event.stopPropagation();
        }
        console.log(data);
        const path = `/app/chart/${data.token_id}`;
        history.push(path);
    };
    const removeToken = async (item) => {
        try {
            console.log(item)
            const myHeaders = {
                "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM"
            };

            // Make the API call to change the token's visibility
            let response = await axios.post(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/token-visibility?visible=true&email=${user.attributes.email}&tokenId=${item.token_id}`, {}, { headers: myHeaders });
            console.log(response.data)
            // Remove the token from the displayed list
            setProjectData(prevData => prevData.filter(token => token._id !== item.token_id));
        } catch (error) {
            console.error('Error removing token:', error);
        }
    };




    return (
        <Tr
            _hover={{ cursor: "pointer" }}
        >
            <Td
                onClick={() => handleRowClick(null, props)}

                minWidth={{ sm: "250px" }}
                ps='0px'
                borderBottomColor='#56577A'
                border={lastItem ? "none" : null}>
                <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
                    <Image src={logo} w={"30px"} me='18px' alt={name} />
                    <Text fontSize='md' color={textColor} minWidth='100%' fontWeight={'bold'}>
                        {name}
                    </Text>
                </Flex>
            </Td>
            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}             onClick={() => handleRowClick(null, props)}
            >
                <Text fontSize='md' color={textColor} fontWeight='bold' pb='.5rem'>
                    {formatToUSD(budget)}
                </Text>
            </Td>
            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}             onClick={() => handleRowClick(null, props)}
            >
                { status >= 0.01
                    ?
                    <Text fontSize='md' color={textColor} fontWeight='bold' pb='.7rem'>
                        {formattedPrice}
                    </Text>
                    :
                    <HStack spacing={1}>
                        <Text fontSize='md' color={textColor} fontWeight='bold' pb='.7rem'>
                            {formattedPrice.start}
                        </Text>
                        <Text fontSize='sm' color={textColor} fontWeight='bold'>
                            {formattedPrice.zeroCount}
                        </Text>
                        <Text fontSize='md' color={textColor} fontWeight='bold' pb='.7rem'>
                            {formattedPrice.value}
                        </Text>
                    </HStack>


                }


            </Td>
            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}             onClick={() => handleRowClick(null, props)}
            >
                <Stat>
                    <StatHelpText color={progressionColor} fontSize={'lg'} fontWeight={'bold'}>
                        <StatArrow type={changeValue} />
                        {progressionValue}%
                    </StatHelpText>
                </Stat>
            </Td>
            <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
                <Menu placement="left-start">
                    <MenuButton as={Button} p="0px" bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }}>
                        <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
                    </MenuButton>
                    <MenuList>
                        <MenuItem
                            icon={<FaEyeSlash color={'#181F47'}/>}
                            command="⌘T"
                            onClick={(event) => {
                                removeToken(props);
                            }}
                        >
                            <Text color={'#181F47'}>Hide</Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
}

export default PortfolioTableRow;
