export const globalStyles = {
  colors: {
    gray: {
      700: "#C9CBCB",
    },
    brand: {
      50: "#cbbff8",
      100: "#876cea",
      200: "#582CFF",
      300: "#542de1",
      400: "#4a25d0",
      500: "#3F4ED0",
      600: "#300eaa",
      700: "#1c0377",
      800: "#505785",
      900: "#212B66 "
    },
  },
  styles: {
    global: (props) => ({
      body: {
        fontFamily: "Plus Jakarta Display",
        color: "white",
      },
      "*::placeholder": {
        color: "gray.400",
      },
      html: {
        fontFamily: "Plus Jakarta Display",
      },
    }),
  },
};
