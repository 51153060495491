/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import basic from "assets/img/basic-auth.png";
import deloitteLogo from "assets/svg/deloitte-logo.svg";
import georgiaLogo from "assets/svg/georgia-logo.svg";
import googleLogo from "assets/svg/google-logo.svg";
import microsoftLogo from "assets/svg/microsoft-logo.svg";
import msnLogo from "assets/svg/msn-logo.svg";
import zohoLogo from "assets/svg/zoho-logo.svg";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import React, {useEffect, useState} from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import PlansTable from "../../../components/PlansTable";
import {fetchPlans} from "../../../apiCalls/fetchPlans";
import {useApi} from "../../../context/ApiContext";
import FeaturesGrid from "../../../components/FeaturesGrid";
import Pricing from "../../../components/Pricing";
import HeroSection from "../../../components/HeroSection";
import CallToAction from "../../../components/CallToAction";
import Testimonial from "../../../components/Testamonial";
import Footer from "../../../components/Footer/Footer";
import HowItWorks from "../../../components/HowItWorks";
import {Separator} from "../../../components/Separator/Separator";
function Home() {


  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      width={'100%'}
    >

      <HeroSection />
      <Separator />
      <FeaturesGrid />
      <Separator />
      <Pricing />

      <Separator />
      <HowItWorks />
      <CallToAction />
      <Footer />






    </Flex>
  );
}

export default Home;
