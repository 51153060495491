import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Image,
    Text,
    VStack,
    HStack,
    Stack,
    useToast,
    CircularProgress,
    List,
    ListItem,
    ListIcon,
    Checkbox, StatGroup, Stat, StatLabel, StatNumber, StatHelpText, Divider, Flex, Progress,
} from '@chakra-ui/react';
import axios from 'axios';
import {useAtom} from "jotai";
import {userAtom} from "../../context/Atoms";

function TokenSelection({ walletInfo, onTokensSaved, walletStats }) {
    const [tokens, setTokens] = useState([]); // Token list from the API
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();
    const [user] = useAtom(userAtom);

    // Format wallet address for display
    const formatWallet = (wallet) => {
        return wallet ? `${wallet.slice(0, 5)}...${wallet.slice(-4)}` : '';
    };

    // Fetch tokens when the component mounts or walletInfo changes
    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const email = user.attributes.email;
                const headers = {
                    "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM",
                };
                const response = await axios.get(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/setup?email=${email}&wallet=${walletInfo.address}`,{headers},);
                setTokens(response.data);
            } catch (error) {
                toast({
                    title: "Error fetching tokens",
                    description: "Unable to fetch tokens. Please try again.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchTokens();
    }, [walletInfo]);

    // Handle token selection
    const handleTokenSelect = (token) => {
        setSelectedTokens((prevSelected) =>
            prevSelected.includes(token)
                ? prevSelected.filter((t) => t !== token)
                : [...prevSelected, token._id]
        );
    };

    // Submit selected tokens
    const submitSelectedTokens = async () => {
        setIsSubmitting(true);
        try {
            // Call your API endpoint to save the selected tokens
            const email = user.attributes.email;
            const headers = {
                "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM",
            };
            let stringTokens = JSON.stringify(selectedTokens)
            const response = await axios.post(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/setup/save?email=${email}&wallet=${walletInfo.address}&tokens=${stringTokens}`, {}, {headers});
            console.log(response.data)

            // Callback or state update to move to the next step or close the wizard
            onTokensSaved();
        } catch (error) {
            toast({
                title: "Error saving tokens",
                description: "Unable to save selected tokens. Please try again.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Box minH={200}>
                <Progress size="xs" isIndeterminate />
            </Box>
        );
    }

    return (
        <VStack spacing={4}>

            <Box w="100%" p={4}>
                <Box w="100%" p={4}  borderRadius="md" align={'center'}>
                    <StatGroup>
                        <Stat>
                            <StatLabel>Tokens</StatLabel>
                            <StatNumber>{walletStats.tokens}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Transactions</StatLabel>
                            <StatNumber>{walletStats.txns}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Transfers</StatLabel>
                            <StatNumber>{walletStats.transfers}</StatNumber>
                        </Stat>
                    </StatGroup>
                </Box>
                <Divider mb={7}></Divider>
                <Text fontSize="lg">Select the tokens you would like to include in your portfolio below.</Text>

                <List spacing={2} mt={5}>
                    {tokens.map((token) => (
                        <ListItem key={token._id}>
                            <HStack>
                                <Checkbox isChecked={selectedTokens.includes(token._id)} onChange={() => handleTokenSelect(token)}>
                                    <HStack>
                                        <Image boxSize="50px" src={token.url} alt={token.name} />
                                        <Text>{token.name}</Text>
                                    </HStack>
                                </Checkbox>
                            </HStack>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Button isLoading={isSubmitting} variant={'primary'} onClick={submitSelectedTokens}>
                Save
            </Button>
        </VStack>
    );
}

export default TokenSelection
