import { Container, Box, chakra, Text, Icon, SimpleGrid } from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import {
    MdOutlinePersonPin,
    MdPermDeviceInformation,
    MdOutlineFlashlightOn,
    MdFlashOn,
    MdOutlineEqualizer, MdDeviceHub
} from 'react-icons/md';
import { SiMinds } from 'react-icons/si';
import { IconType } from 'react-icons';
import {FaCoins} from "react-icons/fa";


const features = [
    {
        heading: 'RPC Nodes',
        content:
            'Get instant access to our global RPC node network and start builing your dApp',
        icon: MdDeviceHub
    },
    {
        heading: 'Real Time Data',
        content: `Get real time pricing data only milliseconds after block execution.`,
        icon: MdFlashOn
    },
    {
        heading: 'Millions of Tokens',
        content:
            'Lynx has data on millions of tokens. From degen tokens that launched moments ago to the largest bluechips. We have it all.',
        icon: FaCoins
    },
    {
        heading: 'Chart Data',
        content: `We provide chart data ready to go to be plugged right into your favorite candlestick or line chart library.`,
        icon: MdOutlineEqualizer
    }
];

const Features = () => {
    return (
        <Container maxW="6xl" p={{ base: 5, md: 10 }}>
            <chakra.h3 fontSize="4xl" fontWeight="bold" mb={3} textAlign="center">
                Features
            </chakra.h3>
            <SimpleGrid columns={{ base: 1, md: 2 }} placeItems="center" spacing={16} mt={12} mb={4}>
                {features.map((feature, index) => (
                    <Box key={index} textAlign="center">
                        <Icon as={feature.icon} w={10} h={10} color="blue.400" />
                        <chakra.h3 fontWeight="semibold" fontSize="2xl">
                            {feature.heading}
                        </chakra.h3>
                        <Text fontSize="md">{feature.content}</Text>
                    </Box>
                ))}
            </SimpleGrid>
        </Container>
    );
};

export default Features;