import {
  Avatar,
  AvatarGroup,
  Flex,
  Text,
  Td,
  Tr,
  useColorModeValue,
  Image,
  Box,
  Stack,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import BNB from "./../../assets/img/bnb.png";
import ETH from "./../../assets/img/eth.png";
import POLY from "./../../assets/img/poly.png";
import { TriangleUpIcon, TriangleDownIcon, MinusIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';


function TokensTable(props) {
  const { logo, name, chain, change, volume, price, lastItem, token_id } = props;
  const history = useHistory();

  const textColor = useColorModeValue("gray.700", "white");

  // Function to capitalize the first letter of each word in the name
  const capitalizeName = name => 
    name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const handleRowClick = (data) => {
      const path = `/app/chart/${token_id}`;
      history.push(path);
    };

  return (
    <Tr onClick={() => handleRowClick(props)}>
      <Td
        minWidth={{ sm: "200px" }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex align='center' py='.1rem' minWidth='100%' flexWrap='nowrap'>
          <Image src={logo} h={"44px"} w={"44px"} me='18px' alt='logo'/>
          <Text fontSize='sm' color='#fff' fontWeight='normal' minWidth='100%'>
            {capitalizeName(name)}
          </Text>
        </Flex>
      </Td>

      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Image src={chain == 1 ? ETH : chain == 137 ? POLY : BNB} h={"44px"} w={"44px"} me='18px' alt='logo'/>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Stack direction="row" alignItems={'center'}>
          {change > 0 ? 
            <TriangleUpIcon color="green.500"/> : 
            change < 0 ?
            <TriangleDownIcon color="red.500"/>
            : <MinusIcon color="gray.400"/>}
          <Text fontSize='sm' color={change > 0 ? "green.500" : change < 0 ? "red.500" : "gray.400"} fontWeight='bold' pb='.1rem'>
            {Math.abs(change).toFixed(2)}%
          </Text>
        </Stack>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>

        ${volume ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(volume) : 0.00}
        </Text>
      </Td>
    </Tr>
  );
}

export default TokensTable;
