import {
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Grid,
	Icon,
	Progress,
	SimpleGrid,
	Spacer,
	Stack,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
// Styles for the circular progressbar
import medusa from 'assets/img/cardimgfree.png';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
import IconBox from 'components/Icons/IconBox';
// Icons
import { CartIcon, DocumentIcon, GlobeIcon, RocketIcon, StatsIcon, WalletIcon } from 'components/Icons/Icons.js';
import TokensTable from 'components/Tables/TokensTable';
import TimelineRow from 'components/Tables/TimelineRow';
import React, { useContext, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";

import { AiFillCheckCircle } from 'react-icons/ai';
import { BiHappy } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import { IoCheckmarkDoneCircleSharp, IoEllipsisHorizontal } from 'react-icons/io5';
// Data
import {
	barChartDataDashboard,
	barChartOptionsDashboard,
	lineChartDataDashboard,
	lineChartOptionsDashboard
} from 'variables/charts';
import { dashboardTableData, timelineData } from 'variables/general';

export default function Tokens() {
	const [socket] = useAtom(socketAtom);
	const history = useHistory();
	const [communityPicks, setCommunityPicks] = useState([]);

	useEffect(() => {
		socket.emit('getCommunityPicks', {});
	
		socket.on('communityPicks', (data) => {
			console.log(data);
			setCommunityPicks(data.data);
		});
		
	  }, [socket]); 

	
	return (
		<Flex flexDirection='column' pt={{ base: '120px', md: '75px', sm: '80px' }}>
			<Grid templateColumns={{ sm: '1fr', md: '1fr', lg: '1fr' }} gap='24px'>
				{/* Projects */}
				<Card p='16px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
					<CardHeader p='12px 0px 28px 0px'>
						<Flex direction='column'>
							<Text fontSize='lg' color='#fff' fontWeight='bold' pb='8px'>
								Communty Picks
							</Text>
							<Flex align='center'>
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									<Text fontWeight='bold' as='span'>
										Our most loved projects by our community.
									</Text>
								</Text>
							</Flex>
						</Flex>
					</CardHeader>
					<Table variant='simple' color='#fff'>
						<Thead>
							<Tr my='.8rem' ps='0px'>
								<Th
									ps='0px'
									color='gray.400'
									fontFamily='Plus Jakarta Display'
									borderBottomColor='#56577A'>
									Name
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
									Chain
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
									24 Hr Change
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
									Volume
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{communityPicks.map((row, index, arr) => {
								return (
									<TokensTable
										name={row.name}
										logo={row.project_info.logo_url}
										chain={row.chain}
										change={row.price_change}
										volume={row.volume}
										price={row.usd_price}
										token_id={row.token_id}
										lastItem={index === arr.length - 1 ? true : false}
									/>
								);
							})}
						</Tbody>
					</Table>
				</Card>

			</Grid>
		</Flex>
	);
}
