
import Dashboard from "views/Dashboard/Dashboard.js";
import Tokens from "views/Dashboard/Tokens.js";
import Tables from "./views/Dashboard/Portfolio.js";
import Billing from "views/Pages/Billing";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Chart from "views/Pages/Chart.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon, WalletIcon, DashboardLogo, ClockIcon, GlobeIcon,
} from "components/Icons/Icons";
import Nodes from "./views/Pages/Nodes/components/Nodes";
import Account from "views/Dashboard/Billing.js";
import Wallets from "./views/Dashboard/Wallets";
import Portfolio from "./views/Dashboard/Portfolio.js";
import Listings from "./views/Pages/Listing";
import Home from "./views/Pages/Home";
import APIKeys from "./views/Pages/Nodes/components/APIKeys";
import UsageScreen from "./views/Pages/Nodes/components/Usage";



var dashRoutes = [

  {
    path: "/dashboard",
    name: "Discoverrrrr",
    hide: true,

    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Tokens,
    layout: "/app",
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    hide: true,

    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Portfolio,
    layout: "/app",
  },
  {
    path: "/wallets",
    name: "Wallets",
    hide: true,

    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Wallets,
    layout: "/app",
  },
  {
    path: "/chart/:token_id",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Chart,
    layout: "/app",
  },
  {
    path: "/list",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Listings,
    layout: "/app",
  },
  {
    path: "/usage",
    name: "Usage",
    rtlName: "لوحة القيادة",
    icon: <GlobeIcon color='inherit' />,
    component: UsageScreen,
    layout: "/app",
  },

  {
    path: "/nodes",
    name: "Nodes",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color='inherit' />,
    component: Nodes,
    layout: "/app",
  },
  {
    path: "/api-keys",
    name: "API Keys",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: APIKeys,
    layout: "/app",
  },
  {
    path: "/billing",
    name: "Billing",
    rtlName: "لوحة القيادة",
    icon: <WalletIcon color='inherit' />,
    component: Billing,
    layout: "/app",
  },
  {
    path: "/",
    name: "Lynx Home",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: Home,
    layout: "/home",
    hide: true
  },



  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
      },

      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color='inherit' />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },

];

var dashRoutesOld = [

  {
    path: "/dashboard",
    name: "Discoverrrrr",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Tokens,
    layout: "/app",
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Portfolio,
    layout: "/app",
  },
  {
    path: "/wallets",
    name: "Wallets",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Wallets,
    layout: "/app",
  },
  {
    path: "/chart/:token_id",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Chart,
    layout: "/app",
  },
  {
    path: "/list",
    name: "Tokens",
    hide: true,
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Listings,
    layout: "/app",
  },

  {
    path: "/nodes",
    name: "Node Service",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Nodes,
    layout: "/app",
  },

  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/",
        name: "Lynx Home",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: Home,
        layout: "/home",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color='inherit' />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },

];


export default dashRoutes;
