import React, { useEffect, useState } from 'react';
import {  Flex, Text, Box } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import {useAtom} from "jotai/index";
import {userAtom} from "../../context/Atoms";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import {useApi} from "../../context/ApiContext";
const RpcUsageChart = () => {
    const [rpcUsageByDay, setRpcUsageByDay] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);
    const [user] = useAtom(userAtom);

    const api = useApi();
    const fetchRpcUsageByDay = async () => {
        try {
            const response = await api.get(`${process.env.REACT_APP_API_URL}/user-usage-by-rpc/${user._id}`);
            if (response.data) {
                console.log('RPC Usage Data:', response.data);
                processData(response.data);
            }
        } catch (error) {
            console.error('Error fetching RPC usage by day:', error);
        }
    };

    const processData = (data) => {
        const seriesMap = {};
        const categories = [];

        data.forEach(item => {
            const day = item._id.day;
            const method = item._id.rpc_method;
            const count = item.totalUsage;

            if (!categories.includes(day)) {
                categories.push(day);
            }
            if (!seriesMap[method]) {
                seriesMap[method] = { name: method, data: [] };
            }
            seriesMap[method].data.push(count);
        });

        const seriesData = Object.values(seriesMap);
        setSeries(seriesData);
        setChartOptions({
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            xaxis: {
                categories: categories,
                labels: {
                    style: {
                        colors: '#c8cfca',
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#c8cfca',
                        fontSize: '12px',
                    },
                    formatter: (value) => {
                        // Check if value is a number before formatting
                        return (value !== undefined && !isNaN(value)) ? value.toLocaleString() : '0';
                    }
                },
            },
            tooltip: {
                theme: 'dark',
                y: {
                    formatter: (value) => {
                        // Check if value is a number before formatting
                        return (value !== undefined && !isNaN(value)) ? value.toLocaleString() : '0';
                    }
                }
            },

            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        });
    };

    useEffect(() => {
        if (user && user._id) {
            fetchRpcUsageByDay();
        }
    }, [user]);

    return (
        <Card >
            <CardHeader mb='20px' ps='22px'>
                <Flex direction='column' alignSelf='flex-start'>
                    <Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
                        Node Requests By RPC Method
                    </Text>
                </Flex>
            </CardHeader>
            <Box w='100%' minH={{ sm: '300px' }}>
                <Chart options={chartOptions} series={series} type="bar" height={320} />
            </Box>
        </Card>
    );
};

export default RpcUsageChart;
