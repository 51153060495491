import Card from "../Card/Card";
import {Box, Button, Flex, useToast, Spinner, VStack, Text, Spacer} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
const test_stripe_public_key = "pk_test_51NqMYsIKGHPI0vziue3PMw2bvUGSuf6qBntAec1cFkPx4q59UKL51KQtoC5GnyX6vvY7FUhkwXD05JQXj3kEkK3E00WKECRQlC";
const stripe_public_key = "pk_live_51NqMYsIKGHPI0vzipi3sOv6PlNStGFG9dusZvphqGasJ2ax66SWawcdv0MlJQxPa2XgXunAbYrvI2Lj9EacsBUsN00EpOjuhdL"
import axios from 'axios';
import {useApi} from "../../context/ApiContext";
import {fetchPlans} from "../../apiCalls/fetchPlans";
import {loadStripe} from "@stripe/stripe-js";



function PlansCard({ plan, user, currentPlan, action, setCurrentPlan }) {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const isCurrentPlan = plan.name === currentPlan;
    const cardBg = isCurrentPlan ? "gray.600" : null;
    const buttonText = isCurrentPlan ? "Current Plan" : "Sign Up";

    const api = useApi();


    const handleClick = async () => {
        setLoading(true);
        const endpoint = action === "change" ? '/change-plan' : '/checkout-session';
        const bodyData = action === "change"
            ? { email: user.attributes.email, newPlanName: plan.name }
            : { email: user.attributes.email, plan: plan.name.toLowerCase(), stripeCustomerId: user.stripeCustomerId };

        try {
            const response = await api.post(endpoint, bodyData);

            if (response.status === 200) {
                const data = response.data;

                if (action === "create") {
                    const stripe = await window.Stripe(stripe_public_key);
                    const result = await stripe.redirectToCheckout({ sessionId: data.id });
                    if (result.error) {
                        toast({
                            title: "Error",
                            description: result.error.message,
                            status: "error",
                            duration: 5000,
                            isClosable: true
                        });
                    }
                } else if (action === "change") {
                    setCurrentPlan(plan.name);
                    toast({
                        title: "Success",
                        description: data.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true
                    });
                }
            } else {
                toast({
                    title: "Error",
                    description: response.data.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card maxW={'400px'} w="100%" mb={4} bg={cardBg} boxShadow="md">
            <Box d="flex" flexDirection="column" p="6" rounded="md" height="100%">
                <Text fontSize="2xl" mb="4" fontWeight="bold">{plan.name}</Text>
                <Text fontSize="xl" mt="4" fontWeight="semibold" mb={4}>${plan.price}/month</Text>

                <Box overflow="auto">
                    {plan.features.map((feature, idx) => (
                        <Text key={idx} mb={3}>{feature}</Text>
                    ))}
                </Box>
                <Flex h={5}/>

                {loading
                    ? <VStack mt="auto" mb={2}><Spinner /></VStack>
                    : <Button mt="auto" colorScheme="blue" mb={2} onClick={handleClick} isDisabled={isCurrentPlan}>{buttonText}</Button>
                }
            </Box>
        </Card>
    );
}

export function PlansTable({ user, currentPlan, action }) {
    const [current, setCurrentPlan] = useState(currentPlan);
    const [plans, setPlans] = useState([]); // State for plans
    const api = useApi();


    useEffect(() => {
        const loadPlans = async () => {
            const fetchedPlans = await fetchPlans(api);
            setPlans(fetchedPlans);
        };

        loadPlans();
    }, []);

    return (
        <Flex direction="row" justifyContent="space-evenly" wrap="wrap" width={'100%'}>
            {plans.map(plan => (
                <PlansCard key={plan.name} plan={plan} user={user} currentPlan={current} action={action} setCurrentPlan={setCurrentPlan} />
            ))}
        </Flex>
    )
}

export default PlansTable;