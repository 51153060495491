import {
    Flex,
    Button,
    Icon,
    Text,
    Spinner,
    VStack,
    Badge,
    IconButton,
    Switch,
    useColorModeValue,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useBreakpointValue
} from "@chakra-ui/react";
import { FaCopy ,FaTrash, FaToggleOn, FaToggleOff } from "react-icons/fa";
import React, {useRef, useEffect, useState} from "react";
import { useAtom } from 'jotai';
import {socketAtom, userAtom} from "context/Atoms";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
function APIKeys() {
    const [socket] = useAtom(socketAtom);
    const [user, setUser] = useAtom(userAtom); // added setUser
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [actionType, setActionType] = useState("");  // "delete" or "deactivate"
    const cancelRef = useRef();
    const displayFormat = useBreakpointValue({ base: 'abbreviated', md: 'full' });





    const onClose = () => {
        setIsOpen(false);
        setSelectedKey(null);
        setActionType("");
    };

    const openDialog = (apiKey, type) => {
        setSelectedKey(apiKey);
        setActionType(type);
        setIsOpen(true);
    };

    // Function to generate API Key
    const generateAPIKey = () => {
        setLoading(true);
        if(user.api_keys.length >= 2) {
            toast({
                title: "API Key Limit Reached",
                description: "You can only have a maximum of 2 API Keys per account.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return
        }
        socket.emit('createApiKey');
    };

    const deleteAPIKey = (socket, awsId) => {
        socket.emit('deleteApiKey', awsId);
    };

    const toggleAPIKeyStatus = (socket, apiKey) => {
        if (apiKey.active) {
            socket.emit('deactivateApiKey', apiKey.awsId);
        } else {
            socket.emit('activateApiKey', apiKey.awsId);
        }
    };


    useEffect(() => {
        socket.emit('getApiKeyUsage', 'wkw48dzahj');

    }, []);

    useEffect(() => {


        socket.on('apiKeyUsageData', (response) => {
            if (!response.error) {
                console.log("API Key usage data:", response.data);
            } else {
                console.error("Error fetching API Key usage:", response.message);
            }
        });
        // Event listener for receiving the new API key
        socket.on('newApiKey', (newKey) => {
            console.log(newKey)
            if (!newKey.error) {
                if (user && Array.isArray(user.api_keys)) {
                    setUser(prevUser => ({
                        ...prevUser,
                        api_keys: [...prevUser.api_keys, newKey.data]
                    }));
                } else if (user) {
                    setUser(prevUser => ({
                        ...prevUser,
                        api_keys: [newKey.data]
                    }));
                }

                setLoading(false);
                toast({
                    title: "API Key Created",
                    description: "Your new API key has been successfully created.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

            } else {
                setLoading(false);
                toast({
                    title: "API Key Creation Failed",
                    description: "Something went wrong creating your API Key.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        });

        socket.on('apiKeyDeleted', (response) => {
            if (!response.error) {
                // Handle successful deletion
                setUser(prevUser => ({
                    ...prevUser,
                    api_keys: prevUser.api_keys.filter(apiKey => apiKey.awsId !== response.data.awsId)
                }));
                toast({
                    title: "API Key Deleted",
                    description: "The API key has been successfully deleted.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

            } else {
                // Handle error in deletion
                toast({
                    title: "API Key Deletion Failed",
                    description: response.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        });

        socket.on('apiKeyActivated', (response) => {
            if (!response.error) {
                // Handle successful activation
                console.log(response)
                setUser(prevUser => {
                    const updatedApiKeys = prevUser.api_keys.map(apiKey => {
                        if (apiKey.awsId === response.data.awsId) {
                            // Return a NEW object with the updated active status
                            return {...apiKey, active: true};
                        }
                        // Otherwise, return the original object
                        return apiKey;
                    });
                    // Return a NEW user object with the updated api_keys array
                    return {
                        ...prevUser,
                        api_keys: [...updatedApiKeys]
                    };
                });


                toast({
                    title: "API Key Activated",
                    description: "The API key has been successfully activated.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Handle error in activation
                toast({
                    title: "API Key Activation Failed",
                    description: response.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        });

        socket.on('apiKeyDeactivated', (response) => {
            if (!response.error) {
                // Handle successful deactivation
                setUser(prevUser => {
                    const updatedApiKeys = prevUser.api_keys.map(apiKey => {
                        if (apiKey.awsId === response.data.awsId) {
                            // Return a NEW object with the updated active status
                            return {...apiKey, active: false};
                        }
                        // Otherwise, return the original object
                        return apiKey;
                    });
                    // Return a NEW user object with the updated api_keys array
                    return {
                        ...prevUser,
                        api_keys: [...updatedApiKeys]
                    };
                });

                toast({
                    title: "API Key Deactivated",
                    description: "The API key has been successfully deactivated.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

            } else {
                // Handle error in deactivation
                toast({
                    title: "API Key Deactivation Failed",
                    description: response.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        });




        return () => {
            // Clean up the listener when the component is unmounted
            socket.off('newApiKey');
            socket.off('apiKeyActivated')
            socket.off('apiKeyDeactivated')
        }
    }, [socket]);


    // Function to copy API Key to clipboard
    // Function to copy API Key to clipboard
    const copyToClipboard = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        toast({
            title: "API Key copied",
            description: "The API key has been copied to your clipboard.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const getApiKeyDisplay = (key, format) => {
        if (format === 'abbreviated') {
            return `${key.substring(0, 5)}...${key.slice(-5)}`;
        }
        return key;
    };

    return (
        <Flex justify="center" minH="60vh" pt={{ base: "120px", md: "75px", sm: '120px' }}>
            <Card>
                <CardHeader pb="33px">
                    <Flex justifyContent="space-between" alignItems="center" w="100%">
                        <Text fontSize="lg" fontWeight="bold">
                            API Keys
                        </Text>
                        <Button
                            onClick={generateAPIKey}
                            colorScheme="blue"
                            isLoading={loading}
                            rightIcon={loading ? <Spinner /> : null}
                        >
                            Create New API Key
                        </Button>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <VStack spacing={4} width={'100%'} alignItems={'flex-start'}> {/* Added VStack here */}
                        {user.api_keys && user.api_keys.length > 0 ? (
                            user.api_keys.map((apiKey, index) => (
                                <Flex
                                    key={index}
                                    p="1rem"
                                    bg="transparent"
                                    borderRadius="15px"
                                    minW={'60%'}
                                    w={{sm: "100%", md:"50%"}}
                                    h={"5rem"}
                                    border="1px solid"
                                    borderColor={borderColor}
                                    align="center"
                                    justify={'space-between'}
                                >
                                    <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                                        {getApiKeyDisplay(apiKey.key, displayFormat)}
                                    </Text>

                                    <Badge colorScheme={apiKey.active ? "green" : "red"} ml={3}>
                                        {apiKey.active ? "Active" : "Disabled"}
                                    </Badge>

                                    <Switch
                                        isChecked={apiKey.active}
                                        onChange={() => openDialog(apiKey, "deactivate")}
                                        colorScheme="green"
                                        me="10px"
                                    />



                                    <IconButton
                                        aria-label="Copy API Key"
                                        icon={<FaCopy />}
                                        onClick={() => copyToClipboard(apiKey.key)}
                                        size="md"
                                        variant="ghost"
                                    />
                                </Flex>
                            ))
                        ) : (
                            <Text color="gray.500">No API keys available.</Text>
                        )}
                    </VStack>


                </CardBody>
            </Card>
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.99) 96.65%)"}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm {actionType.charAt(0).toUpperCase() + actionType.slice(1)}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {actionType === "delete"
                                ? "If deleted, this key will never be recovered and all requests made with this key will be denied. Are you sure?"
                                : "If deactivated, the requests with this key will be denied but you can resume them at any time. Are you sure?"}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} colorScheme={'blue'} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={() => {
                                if (actionType === "delete") {
                                    deleteAPIKey(socket, selectedKey.awsId);
                                } else {
                                    toggleAPIKeyStatus(socket, selectedKey);
                                }
                                onClose();
                            }} ml={3}>
                                Proceed
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Flex>
    );
}

export default APIKeys;
