import { useAtom } from 'jotai';
import { userAtom, jwtTokenAtom, authErrorMessageAtom, authenticatingAtom, awaitingConfirmationAtom } from 'context/Atoms';
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import {useSignIn} from "./useSignIn";
export function useConfirmSignUp() {
    const signIn = useSignIn();
    const [, setAuthErrorMessage] = useAtom(authErrorMessageAtom);
    const [, setAuthenticating] = useAtom(authenticatingAtom);

    return async (email, password, confirmationCode) => {
        try {
            setAuthenticating(true);
            await Auth.confirmSignUp(email, confirmationCode);
            setAuthErrorMessage("");
            await signIn(email, password, true);
        } catch (error) {
            setAuthErrorMessage(error.message);
        } finally {
            setAuthenticating(false);
        }
    };
}
