export const formatNumber = (input) => {
    let num = typeof input === 'string' ? parseFloat(input) : input;
    num = Math.abs(num); // convert to absolute value
    if (isNaN(num)) {
        num = 0.00;
    }

    // Check the range of the number and add an abbreviation
    let output = null;
    if (num >= 1.0e+12) {
        output = (num / 1.0e+12).toFixed(2) + " T"; // Trillion
    }
    else if (num >= 1.0e+9) {
        output = (num / 1.0e+9).toFixed(2) + " B"; // Billion
    }
    else if (num >= 1.0e+6) {
        output = (num / 1.0e+6).toFixed(2) + " M"; // Million
    }
    else if (num >= 1.0e+3) {
        output = (num / 1.0e+3).toFixed(2) + " K"; // Thousand
    }
    else {
        output = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
    }

    return output;
}

export const formatCrypto = (value) => {

    if (value >= 0.01) return value.toFixed(4);
    // Convert value from scientific notation to fixed-point notation
    const toFixedPointNotation = (num) => {
        const [lead, decimal, pow] = num.toString()
            .replace(/^-/, '')
            .replace(/^0+/, '')
            .split(/e|\./);
        return +pow < 0
            ? '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : lead + (decimal || '') + '0'.repeat(Math.max(pow - decimal.length || 0, 0));
    }

    if (value < 1 && value > 0 && !Number.isInteger(value) && value.toString().includes('e')) {
        value = toFixedPointNotation(value);
    }

    if (value >= 1) return value.toString();  // If value is >= 1, return as is

    let strValue = value.toString().slice(2);  // Remove '0.'
    let zeroCount = 0;

    // Count zeros until first non-zero
    for (let char of strValue) {
        if (char == '0') {
            zeroCount++;
        } else {
            break;
        }
    }

    let val = strValue.slice(zeroCount, zeroCount + 5);

    // Return formatted string
    return {
        start: "0.0",
        zeroCount: zeroCount,
        value: val
    };
}

