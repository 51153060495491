import React, { useEffect, useRef } from 'react';
import './index.css';
import { widget } from '../../charting_library';
import Datafeed from './datafeed.js';
import {Flex} from "@chakra-ui/react";
function getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartContainer = ({selectedPair}) => {
    const chartContainerRef = useRef();

    const defaultProps = {
        symbol:  `${selectedPair?.token0_symbol?.toUpperCase()} / ${selectedPair?.token1_symbol?.toUpperCase()}`,
        interval: '15M',
        datafeedUrl: 'https://demo_feed.tradingview.com',
        libraryPath: '/charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
        supported_resolutions: ["1", "15", "60", "D"],
        time_frames: [
            { text: "1y", resolution: "1D", description: "1 Year" },
            { text: "3m", resolution: "1D", description: "3 Months"},
            { text: "1m", resolution: "1D", description: "1 Month" },
            { text: "1w", resolution: "60", description: "1 Week" },
            { text: "1d", resolution: "5", description: "1 Day" }
        ]
    };

    useEffect(() => {
        const widgetOptions = {
            symbol: defaultProps.symbol,
            pair: selectedPair,
            // BEWARE: no trailing slash is expected in feed URL
            datafeed: Datafeed,
            debug: true,
            interval: '15',
            container: chartContainerRef.current,
            library_path: defaultProps.libraryPath,
            locale: getLanguageFromURL() || 'en',
            disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
            enabled_features: ['study_templates'],
            charts_storage_url: defaultProps.chartsStorageUrl,
            charts_storage_api_version: defaultProps.chartsStorageApiVersion,
            client_id: defaultProps.clientId,
            user_id: defaultProps.userId,
            fullscreen: defaultProps.fullscreen,
            autosize: defaultProps.autosize,
            studies_overrides: defaultProps.studiesOverrides,
            supported_resolutions: ["1", "15", "60", "D"],
            overrides: {
                "paneProperties.background": "#131722",
                "paneProperties.vertGridProperties.color": "#363c4e",
                "paneProperties.horzGridProperties.color": "#363c4e",
                "symbolWatermarkProperties.transparency": 90,
                "scalesProperties.textColor" : "#AAA",
                "mainSeriesProperties.candleStyle.wickUpColor": '#336854',
                "mainSeriesProperties.candleStyle.wickDownColor": '#7f323f',
            }
        };

        const tvWidget = new widget(widgetOptions);

        tvWidget.onChartReady(() => {
            tvWidget.headerReady().then(() => {
                const button = tvWidget.createButton();
                button.setAttribute('title', 'Click to show a notification popup');
                button.classList.add('apply-common-tooltip');
                button.addEventListener('click', () => tvWidget.showNoticeDialog({
                    title: 'Notification',
                    body: 'TradingView Charting Library API works correctly',
                    callback: () => {
                        console.log('Noticed!');
                    },
                }));

                button.innerHTML = 'Check API';
            });
        });

        return () => {
            tvWidget.remove();
        };
    }, [selectedPair]);

    return (
        <Flex
            w={'100%'}
            h={500}
            ref={chartContainerRef}
            className={'TVChartContainer'}
        />
    );
}