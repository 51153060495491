import React, { useEffect, useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { useAtom } from "jotai";
import { userAtom } from "../../context/Atoms";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import { useApi } from "../../context/ApiContext";

const ApiUsageChart = () => {
    const [apiUsageByEndpoint, setApiUsageByEndpoint] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);
    const [user] = useAtom(userAtom);

    const api = useApi();
    const fetchApiUsageByEndpoint = async () => {
        try {
            const response = await api.get(`${process.env.REACT_APP_API_URL}/user-api-usage-by-endpoint/${user._id}`);
            if (response.data) {
                console.log('API Usage Data:', response.data);
                processData(response.data);
            }
        } catch (error) {
            console.error('Error fetching API usage by endpoint:', error);
        }
    };

    const processData = (data) => {
        const seriesMap = {};
        const categories = [];

        data.forEach(item => {
            const day = item._id.day;
            const endpoint = item._id.endpoint;
            const count = item.totalUsage;

            if (!categories.includes(day)) {
                categories.push(day);
            }
            if (!seriesMap[endpoint]) {
                seriesMap[endpoint] = { name: endpoint, data: [] };
            }
            seriesMap[endpoint].data.push(count);
        });

        const seriesData = Object.values(seriesMap);
        setSeries(seriesData);
        setChartOptions({
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            xaxis: {
                categories: categories,
                labels: {
                    style: {
                        colors: '#c8cfca',
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#c8cfca',
                        fontSize: '12px',
                    },
                    formatter: (value) => value !== undefined && !isNaN(value) ? value.toLocaleString() : '0'
                },
            },
            tooltip: {
                theme: 'dark',
                y: {
                    formatter: (value) => value !== undefined && !isNaN(value) ? value.toLocaleString() : '0'
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        });
    };

    useEffect(() => {
        if (user && user._id) {
            fetchApiUsageByEndpoint();
        }
    }, [user]);

    return (
        <Card >
            <CardHeader mb='20px' ps='22px'>
                <Flex direction='column' alignSelf='flex-start'>
                    <Text fontSize='lg' color='#fff' fontWeight='bold' mb='6px'>
                        API Requests By Endpoint
                    </Text>
                </Flex>
            </CardHeader>
            <Box w='100%' minH={{ sm: '300px' }}>
                <Chart options={chartOptions} series={series} type="bar" height={320} />
            </Box>
        </Card>
    );
};

export default ApiUsageChart;