/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useEffect, useState} from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr, Box, Link, Button, Progress,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";


// Data
import { tablesProjectData, tablesTableData } from "variables/general";

// Icons
import { AiFillCheckCircle } from "react-icons/ai";
import { useAtom } from 'jotai';
import { userAtom } from "context/Atoms";
import axios from "axios";
import PortfolioTableRow from "../../components/Tables/PortfolioProjectRow";
import WalletRow from "../../components/Tables/WalletRow";
import WalletWizard from "../../components/WalletWizard";


function Wallets() {
  const [projectData, setProjectData] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [user] = useAtom(userAtom);
  const [isWizardOpen, setWizardOpen] = useState(false);

  useEffect(() => {

      async function fetchData() {
        try {
          const headers = {
            "x-api-key": "YONf7dPgJI1PRP8bVTcJL95D34Kzt3uN6xWVb9wM"
          };
          const response = await axios.get(`https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets?email=${user.attributes.email}`, {
            headers: headers
          });

          setWalletData(response.data);
          console.log(response.data);
        } catch (error) {
          console.log('Error fetching data:', error);
        }
      }
    if (user?.attributes.email) {
      fetchData();
    }


  }, [user]);

  if (!user) {
    return (
        <Flex
            height="80vh"
            justifyContent="center"
            alignItems="center"
            direction="column"

        >
          <Card maxW={'40%'}>
            <Box boxShadow="md" p="6" rounded="md" textAlign="center">
              <Text fontSize="xl" mb="4">You must be logged in to view this page.</Text>
              <Link w='100%' mb='16px' href='/#/auth/signin'>
                <Button colorScheme="blue">Login Now</Button>
              </Link>
            </Box>
          </Card>
        </Flex>
    );
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px", sm: '0px' }}>
      {/* Projects Table */}
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex direction='row' justify={'space-between'} w={'100%'}>
            <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
              Wallets
            </Text>
            <Button variant={'primary'} onClick={() => setWizardOpen(true)}>
              Add Wallet
            </Button>
            <WalletWizard isOpen={isWizardOpen} setWalletData={setWalletData} onClose={() => setWizardOpen(false)} />


          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant='simple' color='#fff'>
            <Thead>
              <Tr my='.8rem' ps='0px'>
                <Th
                  ps='0px'
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Name
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Address
                </Th>
                <Th borderBottomColor='#56577A'></Th>
              </Tr>
            </Thead>
            <Tbody>
              {walletData.map((row, index, arr) => {
                return (
                    <WalletRow
                        name={row.wallet_name}
                        address={row.wallet_address}
                        wallet_id={row._id}
                        setWalletData={setWalletData}
                        lastItem={index === arr.length - 1}
                    />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Wallets;
