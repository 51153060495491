import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Text, Spinner, Link, Flex, VStack,
} from '@chakra-ui/react';
import {useAtom} from "jotai";
import {authenticatingAtom, authErrorMessageAtom, userAtom} from "../context/Atoms";
import {useSignIn} from "../hooks/useSignIn";
import GradientBorder from "./GradientBorder/GradientBorder";
import {NavLink} from "react-router-dom";

const LoginModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useAtom(userAtom);
    const [authErrorMessage, setAuthErrorMessage] = useAtom(authErrorMessageAtom);
    const [authenticating, setAuthenticating] = useAtom(authenticatingAtom);
    const titleColor = "white";
    const textColor = "gray.400";

    const signIn = useSignIn();

    useEffect(() => {
        console.log(user);
        if (user && !authErrorMessage && !authenticating) {
            onClose()
        }
    }, [user, authErrorMessage, authenticating]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 89.41%, rgba(10, 14, 35, 0.49) 86.65%)"
            >
                <ModalHeader>Sign In</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email address</FormLabel>
                        <GradientBorder
                            mb="24px"
                            w={{ base: "100%", lg: "fit-content" }}
                            borderRadius="20px"
                        >
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            color="white"
                            bg="rgb(19,21,54)"
                            border="transparent"
                            borderRadius="20px"
                            fontSize="sm"
                            size="lg"
                            w={{ base: "100%", md: "346px" }}
                            maxW="100%"
                            h="46px"
                            placeholder="Your email address"
                        />
                        </GradientBorder>
                    </FormControl>

                    <FormControl id="password" isRequired mt={4}>
                        <FormLabel>Password</FormLabel>
                        <GradientBorder
                            mb="24px"
                            w={{ base: "100%", lg: "fit-content" }}
                            borderRadius="20px"
                        >
                        <Input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            color="white"
                            bg="rgb(19,21,54)"
                            border="transparent"
                            borderRadius="20px"
                            fontSize="sm"
                            size="lg"
                            w={{ base: "100%", md: "346px" }}
                            maxW="100%"
                            h="46px"
                            placeholder="Your email address"
                        />
                        </GradientBorder>
                    </FormControl>

                    {authErrorMessage && (
                        <Text color="red.500" mb="24px" ms="4px" fontSize="sm" alignSelf={'center'}>
                            {authErrorMessage}
                        </Text>
                    )}
                    <VStack w={'100%'} mb={5}>
                        <Button
                            variant='brand'
                            fontSize='10px'
                            type='submit'
                            w='100%'
                            maxW='350px'
                            h='45'
                            mb='20px'
                            mt='20px'
                            disabled={authenticating}
                            onClick={() => signIn(email,password)}>
                            {authenticating ? <Spinner size="sm" /> : "SIGN IN"}
                        </Button>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                            <Text color={textColor} fontWeight='medium'>
                                Don't have an account?
                                <NavLink to='/auth/signup' >
                                    <Text color={titleColor} as='span' ms='5px' fontWeight='bold'>Sign Up</Text>
                                </NavLink>
                            </Text>
                        </Flex>

                    </VStack>
                </ModalBody>





            </ModalContent>
        </Modal>
    );
};

export default LoginModal;
